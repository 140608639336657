import React from 'react';
import { connect } from 'react-redux';

const Progress = (props) => {
  const course = props.course.id;
  const user = props.user;
  let courseProgress = 0;

  if (user.courses && user.courses.length !== 0 && course) {
    courseProgress = user.courses.find((e) => e.course === course).progress;
  }

  return (
    <div className='ui olive progress' data-percent='65'>
      <div
        className='bar'
        style={{
          width: `${courseProgress}%`,
        }}
      >
        <div className='progress'>{courseProgress}%</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(Progress);
