import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import FacebookLogin from 'react-facebook-login';

import Modal from '../../widgets/Modal';
import UserForm from '../../widgets/UserForm';
import { createUser, createFacebookUser, resetErrors } from '../../../actions';
import Error from '../../widgets/Error';

import icon from '../../../../img/primary_icon.png';

const Signup = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [ellipses, setEllipses] = useState('');
  const error = props.error;
  const errorType = props.errorDetail.code;

  useEffect(() => {
    return () => props.resetErrors();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const changeEllipses = () => {
      if (ellipses === '...') {
        setTimeout(() => {
          setEllipses('');
        }, 500);
      } else {
        setTimeout(() => {
          setEllipses(`${ellipses}.`);
        }, 500);
      }
    };

    changeEllipses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ellipses]);

  const onSubmit = (formValues) => {
    props.createUser(formValues);
    ReactGA.event({
      category: `User`,
      action: 'Sign up',
      label: 'App sign up',
      transport: 'beacon',
    });
    setSubmitted(true);
  };

  const responseFacebook = (response) => {
    props.createFacebookUser(response.name, response.email);
  };

  const renderDisplay = () => {
    if (submitted && !error) {
      return (
        <div>
          <Modal loginLoad={true}>
            <div className='centered row'>
              <img
                className='rotating'
                src={icon}
                alt='logo_rotating'
                style={{ height: '120px' }}
              />
            </div>
            <div className='centered row'>
              <h3>Setting up your account{ellipses}</h3>
            </div>
          </Modal>
        </div>
      );
    }
    return (
      <div>
        <Modal title='Sign-up to start your Pyxium journey!' size='mini'>
          <p>
            It's completely free, just like learning should be{' '}
            <span role='img' aria-label='rocket'>
              🚀
            </span>
          </p>
          {/* <FacebookLogin
            appId='261925085947485'
            autoLoad={false}
            fields='name,email,picture'
            textButton='Continue with facebook'
            callback={responseFacebook}
          /> */}
          <UserForm button='Signup' onSubmit={onSubmit} />
        </Modal>
      </div>
    );
  };

  const renderError = () => {
    if (error) {
      switch (errorType) {
        case 11000:
          return (
            <div>
              <Modal title='Email already taken!'>
                <div className='centered'>
                  <h3>That email is already registered</h3>
                  <div className='ui list'>
                    <div className='item'>
                      <Link to='/login' className='ui primary button'>
                        Login
                      </Link>
                    </div>
                    <div className='item'>
                      <Link to='/forgot' className='ui button'>
                        I've forgotten my password
                      </Link>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          );
        default:
          return (
            <div>
              <Error />
            </div>
          );
      }
    }
    return <>{renderDisplay()}</>;
  };

  return <>{renderError()}</>;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    error: state.error.error,
    errorDetail: state.error.errorDetail,
  };
};

export default connect(mapStateToProps, {
  createUser,
  resetErrors,
  createFacebookUser,
})(Signup);
