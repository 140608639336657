import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactGA from 'react-ga';
import validator from 'validator';

import BackArrow from '../../widgets/BackArrow';

import history from '../../../history';

import {
  fetchCourses,
  fetchSection,
  setTagTerm,
  registerUserInterest,
} from '../../../actions/index';

const CourseInfo = (props) => {
  // const [moreInfo, setMoreInfo] = useState(false);
  // const [topMargin, setTopMargin] = useState(0);
  const [prerequisites, setPrerequisites] = useState(false);
  const [interestHover, setInterestHover] = useState(false);
  const [registerInfo, setRegisterInfo] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [registered, setRegistered] = useState(false);

  const courses = props.courses;
  const user = props.user;

  useEffect(() => {
    // if (topRef.current && topMargin !== topRef.current.offsetHeight) {
    //   setTopMargin(topRef.current.offsetHeight);
    // }

    if (courses.length === 0) {
      props.fetchCourses(user);
    }
  });

  // useEffect(() => {
  //   if (moreInfo) {
  //     if (ref.current) {
  //       ref.current.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   } else {
  //     if (topRef.current) {
  //       topRef.current.scrollTop = 0;
  //     }
  //   }
  // }, [moreInfo]);

  const course = courses.find((el) => el.id === props.match.params.id);

  const topRef = useRef();
  // const centerRef = useRef();
  // const centerRefMob = useRef();

  if (course && courses) {
    const prerequisitesComplete =
      course.prerequisites.length === 0 ||
      (course.prerequisites.length !== 0 &&
        user &&
        course.prerequisites.every((el) =>
          user.completedCourses.includes(el.id)
        ));

    const onCloseInfo = () => {
      history.goBack();
    };

    const onEnrollmentClick = (text, course) => {
      if (text === 'Get started') {
        ReactGA.event({
          category: `Course`,
          action: 'Enrollment',
          label: course.id,
          transport: 'beacon',
        });
      }
    };

    const length = Math.round(course.totalSections * (2 / 3));

    const onTagClick = (tag) => {
      props.setTagTerm(tag.tag);
      history.push('/courses');
    };

    const renderTags = (type) => {
      return course.tags
        .filter((tag) => tag.display)
        .map((tag) => {
          return (
            <div
              id='course-tag-button-primary'
              style={{ marginLeft: `${type === 'desktop' ? '0px' : '4px'}` }}
              className='ui mini button'
              key={course.tags.indexOf(tag)}
              onClick={() => onTagClick(tag)}
            >
              {tag.tag}
            </div>
          );
        });
    };

    const renderPrereqCourses = () => {
      return course.prerequisites.map((prereq) => {
        const prereqCourse = courses.find((course) => course.id === prereq);

        return (
          <div
            key={course.prerequisites.indexOf(prereq)}
            style={{ marginTop: '4px' }}
          >
            {prereqCourse.title}
            <span
              style={{
                fontSize: '12px',
                marginLeft: '12px',
                display: 'inline-block',
              }}
            >
              <div
                onClick={() => history.push(`/course/${prereqCourse.id}`)}
                style={{ color: '#4286F4', cursor: 'pointer' }}
              >
                Go to course
              </div>
            </span>
          </div>
        );
      });
    };

    // ********** COURSE TOPICS **********

    const renderComingSoon = (section) => {
      if (section.comingSoon) {
        return (
          <div
            className='ui top right attached label'
            style={{
              borderRadius: '16px',
              margin: '4px',
              color: '#ffffff',
              backgroundColor: '#6556FF',
            }}
          >
            Coming Soon
          </div>
        );
      }
    };

    const renderCourseSections = (type) => {
      let width;
      let height;

      if (type === 'desktop') {
        width = 'four';
        height = '150px';
      } else {
        width = 'ten';
        height = '100px';
      }

      return course.structure.map((section) => {
        let isLocked = false;

        if (user) {
          if (course.structure.indexOf(section) !== 0) {
            let userProgress;
            if (user.courses.some((el) => el.course === course.id)) {
              userProgress = user.courses.find(
                (el) => el.course === course.id
              ).highestSection;
            }

            if (!userProgress || userProgress < parseInt(section.section)) {
              isLocked = true;
            }
          } else if (course.comingSoon) {
            isLocked = true;
          }
        } else {
          isLocked = true;
        }

        if (isLocked || section.comingSoon) {
          return (
            <div
              className={`${width} wide column`}
              key={course.structure.indexOf(section)}
            >
              <div className='ui card course-card'>
                <div id='section-card-image' className='image locked'>
                  <img
                    src={section.image}
                    alt='section_img'
                    style={{
                      maxHeight: `${height}`,
                      opacity: '0.6',
                      width: 'auto',
                      margin: '0 auto',
                      borderRadius: '16px',
                    }}
                  />
                  {section.comingSoon ? (
                    renderComingSoon(section)
                  ) : (
                    <div
                      className='ui top attached label'
                      style={{
                        borderRadius: '16px',
                        margin: '4px',
                        width: 'auto',
                        color: '#ffffff',
                        backgroundColor: '#6556FF',
                      }}
                    >
                      <i className='lock icon' style={{ margin: '0' }}></i>
                    </div>
                  )}
                </div>
                <div
                  className='content'
                  style={{ padding: '8px', borderTop: '0px' }}
                >
                  <div className='header' style={{ fontSize: '14px' }}>
                    {section.title}
                  </div>
                  <div className='description' style={{ fontSize: '12px' }}>
                    {section.content}
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return (
          <div
            className={`${width} wide column`}
            key={course.structure.indexOf(section)}
          >
            <Link
              to={`/course/live/${course.id}/${section.grouping}/${
                parseInt(section.section) + 1
              }`}
              className='ui link card course-card'
            >
              <div id='section-card-image' className='image'>
                <img
                  src={section.image}
                  alt='section_image'
                  style={{
                    maxHeight: `${height}`,
                    borderRadius: '16px',
                    width: 'auto',
                    margin: '0 auto',
                  }}
                />
                {renderComingSoon(section)}
              </div>
              <div
                className='content'
                style={{ padding: '8px', borderTop: '0px' }}
              >
                <div className='header' style={{ fontSize: '14px' }}>
                  {section.title}
                </div>
                <div className='description' style={{ fontSize: '12px' }}>
                  {section.content}
                </div>
              </div>
            </Link>
          </div>
        );
      });
    };

    const renderPrerequisites = () => {
      if (!prerequisitesComplete) {
        return (
          <div style={{ marginBottom: '8px' }}>{renderPrereqCourses()}</div>
        );
      }
    };

    const renderAdditionalInfo = (type) => {
      return (
        <div className='more-info'>
          <div>
            <div style={{ marginBottom: '16px' }}>
              <b>Instructor:</b> {course.ownerName}{' '}
              <span style={{ fontSize: '12px', marginLeft: '12px' }}>
                <Link
                  to={`/instructor/${course.instructorUrl}` || ''}
                  style={{ color: '#4286F4' }}
                >
                  {/* eslint-disable-next-line */}
                  See profile
                </Link>
              </span>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <b>Description:</b> {course.description}
            </div>

            <div style={{ color: 'rgb(135 135 135)', marginBottom: '4px' }}>
              <i className='clock outline icon'></i> {length} minutes
            </div>
            <div style={{ color: 'rgb(135 135 135)', marginBottom: '4px' }}>
              <i className='question circle icon'></i> {course.totalQuestions}{' '}
              questions
            </div>
            <div style={{ color: 'rgb(135 135 135)', marginBottom: '16px' }}>
              <i className='info circle icon'></i> {course.structure.length}{' '}
              topics
            </div>

            {type !== 'desktop' ? (
              <>
                {/* COURSE TOPICS */}
                <div style={{ marginBottom: '16px' }}>
                  <div style={{ marginBottom: '8px' }}>
                    <b>Topics</b>
                  </div>
                  <div className='ui stretched grid x-overflow no-scrollbar'>
                    {renderCourseSections()}
                  </div>
                </div>
                <div style={{ marginBottom: '8px' }}>
                  <b>Tags</b>
                </div>
                <div style={{ paddingBottom: '16px' }}>{renderTags()}</div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    };

    const renderIcon = () => {
      if (prerequisites) {
        return <i className='angle up icon'></i>;
      } else {
        return <i className='angle down icon'></i>;
      }
    };

    const onRegisterInterestClick = (val) => {
      if (user) {
        props.registerUserInterest(course.id, user.name, user.email, val);
      } else if (val) {
        setRegisterInfo(true);
      }
    };

    const onAddressInputChange = (e, field) => {
      const value = e.target.value;
      switch (field) {
        case 'name':
          setName(value);
          setErrors(errors.filter((error) => error.type !== 'name'));
          break;
        case 'email':
          setEmail(value);
          setErrors(errors.filter((error) => error.type !== 'email'));
          break;
      }
    };

    const onConfirmRegisterClick = () => {
      let collectingErrors = [];
      if (!validator.isEmail(email)) {
        collectingErrors = [
          ...collectingErrors,
          { type: 'email', error: 'Please enter a valid email' },
        ];
      }
      if (course.registeredInterest.some((record) => record.email === email)) {
        collectingErrors = [
          ...collectingErrors,
          {
            type: 'email',
            error: 'That email has already registered interest!',
          },
        ];
      }
      if (name === '') {
        collectingErrors = [
          ...collectingErrors,
          { type: 'name', error: 'Please enter your name' },
        ];
      }
      if (collectingErrors.length !== 0) {
        setErrors(collectingErrors);
      } else {
        props.registerUserInterest(course.id, name, email, true);
        setRegisterInfo(false);
        setRegistered(true);
      }
    };

    const onKeyDown = (e) => {
      if (e.key === 'Enter') {
        onConfirmRegisterClick();
      }
    };

    const renderErrorMessage = (type) => {
      if (errors.some((error) => error.type === type)) {
        return (
          <>
            <div>
              <div
                className='ui pointing label'
                style={{
                  backgroundColor: '#E56B6F',
                  color: '#fff',
                  borderRadius: '50px',
                  marginTop: `${type === 'name' ? 'calc(1em - 8px)' : ''}`,
                }}
              >
                <div className='header'>
                  {errors.find((error) => error.type === type).error}
                </div>
              </div>
            </div>
          </>
        );
      }
      return <></>;
    };

    const renderRegisterInterest = () => {
      let desktopInterestHelperMargin;

      if (
        parseInt(window.innerWidth) >= 968 &&
        parseInt(window.innerWidth) < 1200
      ) {
        desktopInterestHelperMargin = '0.5em';
      } else if (parseInt(window.innerWidth) >= 1200) {
        desktopInterestHelperMargin = '1em';
      } else {
        desktopInterestHelperMargin = '0';
      }

      if (
        course.registeredInterest.some(
          (record) => record.email === user.email
        ) ||
        registered
      ) {
        return (
          <>
            <div style={{ marginTop: '24px' }}>
              <div className='ui grid'>
                <div className='two wide column'>
                  <i className='check icon' style={{ color: '#5dc080' }} />
                </div>
                <div className='fourteen wide column'>
                  Interest registered. We'll send an email to{' '}
                  <b>{user.email || email}</b> when this course goes live!
                  <br />
                  <span
                    className='text-link'
                    onClick={() => onRegisterInterestClick(false)}
                  >
                    {user.email ? 'Unregister interest' : ''}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      } else if (registerInfo) {
        return (
          <>
            <div style={{ marginTop: '24px' }}>
              <span style={{ fontWeight: '700' }}>
                Add your details below to be notified when this course goes
                live!
              </span>
              <div className='field' style={{ marginTop: '8px' }}>
                <div className='ui input' style={{ marginBottom: '8px' }}>
                  <input
                    className='primary-input'
                    type='text'
                    placeholder='Name'
                    onChange={(e) => onAddressInputChange(e, 'name')}
                    onKeyDown={onKeyDown}
                    value={name}
                  />
                </div>
                {renderErrorMessage('name')}
                <br />
                <div className='ui input'>
                  <input
                    className='primary-input'
                    type='text'
                    placeholder='Email'
                    onChange={(e) => onAddressInputChange(e, 'email')}
                    onKeyDown={onKeyDown}
                    value={email}
                  />
                </div>
                {renderErrorMessage('email')}
              </div>
              <div
                id='secondary-button'
                className='ui button'
                style={{ marginTop: '8px' }}
                onClick={onConfirmRegisterClick}
              >
                Register
              </div>
            </div>
          </>
        );
      }
      {
        return (
          <>
            <div
              id='basic-secondary-button'
              className='ui basic button'
              style={{ marginTop: '8px', borderRadius: '50px' }}
              onClick={() => onRegisterInterestClick(true)}
            >
              Register interest
            </div>
            <i
              style={{ color: '#6556ff' }}
              className='question circle outline link icon'
              onMouseEnter={() => setInterestHover(true)}
              onMouseLeave={() => setInterestHover(false)}
            />
            {interestHover ? (
              <>
                <div
                  className='ui left pointing label helper-label desktop-only fade-in'
                  style={{
                    position: 'absolute',
                    marginTop: desktopInterestHelperMargin,
                  }}
                >
                  Get notified by email when this course goes live
                </div>
                <br />
                <div
                  className='ui label helper-label mobile-only fade-in'
                  style={{ marginTop: '8px' }}
                >
                  Get notified by email when this course goes live
                </div>
              </>
            ) : (
              ''
            )}
          </>
        );
      }
    };

    const renderButton = () => {
      if (course.comingSoon) {
        return (
          <>
            <div
              id='secondary-button'
              className='ui big disabled button'
              style={{ marginTop: '8px' }}
            >
              <i className='lock icon'></i>Coming soon
            </div>
            <br />
            {renderRegisterInterest()}
          </>
        );
      } else if (user) {
        if (!prerequisitesComplete) {
          return (
            <div
              id='secondary-button'
              className='ui big disabled button'
              style={{ marginTop: '8px' }}
            >
              <i className='lock icon'></i>Complete prerequisites
            </div>
          );
        } else {
          let text;
          let userSection;
          let grouping;
          if (user.courses.some((el) => el.course === course.id)) {
            text = 'Continue';
            userSection = user.courses.find(
              (el) => el.course === course.id
            ).section;

            grouping = Math.max(
              ...course.structure
                .filter((el) => parseInt(el.section) <= parseInt(userSection))
                .map((el) => parseInt(el.grouping))
            );
          } else {
            text = 'Get started';
            userSection = parseInt(course.structure[0].section) + 1;
            grouping = 1;
          }

          return (
            <Link
              to={`/course/live/${course.id}/${grouping}/${userSection}`}
              id='secondary-button'
              style={{ marginTop: '8px' }}
              className='ui big button'
              onClick={() => onEnrollmentClick(text, course)}
            >
              <div>
                {text}{' '}
                <i
                  style={{ verticalAlign: 'middle' }}
                  className='arrow alternate circle right icon'
                ></i>
              </div>
            </Link>
          );
        }
      } else {
        return (
          <Link
            to='/login'
            id='secondary-button'
            className='ui big button'
            style={{ marginTop: '8px', borderRadius: '50px' }}
          >
            <i className='lock icon'></i>Login to learn
          </Link>
        );
      }
    };

    const renderLabels = (type) => {
      return (
        <div
          style={{
            marginTop: `${type === 'desktop' ? '' : '8px'}`,
          }}
        >
          {course.new ? (
            <div id='course-tag-button-secondary' className='ui mini button'>
              New
            </div>
          ) : (
            ''
          )}
        </div>
      );
    };

    // const calculateTopMargin = (type) => {
    //   if (type === 'desktop') {
    //     if (topMargin && centerRef.current) {
    //       return `${(topMargin - centerRef.current.offsetHeight - 20) / 2}px`;
    //     }
    //     return '0';
    //   } else {
    //     if (topMargin && centerRefMob.current) {
    //       return `${
    //         (topMargin - centerRefMob.current.offsetHeight - 20) / 2
    //       }px`;
    //     }
    //     return '0';
    //   }
    // };

    const renderMobile = () => {
      return (
        <>
          <div
            className='centered'
            // ref={centerRefMob}
            style={{
              marginTop: '28px',
            }}
          >
            <div className='image'>
              <img
                src={course.image}
                alt='cousre_title_img'
                style={{ maxWidth: '60vw', borderRadius: '16px' }}
              ></img>
            </div>
            {renderLabels()}
            <h1 style={{ margin: '0px' }}>{course.title}</h1>
            {/* <p>by {course.ownerName}</p> */}

            {/* <div style={{ fontSize: '16px', color: 'rgb(135 135 135)' }}>
              <i className='clock outline icon'></i> {length} minutes total
              content
            </div> */}
            {renderButton()}
            <div
              style={{
                marginTop: '8px',
                fontSize: '16px',
                color: 'rgb(135 135 135)',
              }}
              // onClick={() => setMoreInfo(!moreInfo)}
            >
              {!prerequisitesComplete && user ? (
                <span
                  className='text-link'
                  onClick={() => setPrerequisites(!prerequisites)}
                >
                  See prerequisites {renderIcon()}
                </span>
              ) : (
                ''
              )}{' '}
              {prerequisites ? <>{renderPrerequisites()}</> : ''}
            </div>
          </div>
          {renderAdditionalInfo()}
        </>
      );
    };

    const renderDesktop = () => {
      return (
        <>
          <div
            // ref={centerRef}
            style={{
              marginTop: '40px',
            }}
          >
            <div className='ui two column grid'>
              <div className='eight wide column'>
                <div className='image'>
                  <img
                    src={course.image}
                    alt='course_title_image'
                    style={{
                      maxWidth: '100%',
                      maxHeight: '529px',
                      borderRadius: '16px',
                    }}
                  ></img>
                </div>
              </div>

              <div className='eight wide column'>
                <div>
                  {renderLabels('desktop')}
                  <h1 style={{ margin: '0px' }}>{course.title}</h1>
                  {/* <p>by {course.ownerName}</p> */}
                  {/* <div style={{ fontSize: '16px', color: 'rgb(135 135 135)' }}>
                <i className='clock outline icon'></i> {length} minutes total
                content
              </div> */}
                  {renderAdditionalInfo('desktop')}
                  <div>{renderButton()}</div>
                  <div
                    style={{
                      marginTop: '8px',
                      fontSize: '16px',
                      color: 'rgb(135 135 135)',
                    }}
                    // onClick={() => setMoreInfo(!moreInfo)}
                  >
                    {!prerequisitesComplete && user ? (
                      <span
                        className='text-link'
                        onClick={() => setPrerequisites(!prerequisites)}
                      >
                        See prerequisites {renderIcon()}
                      </span>
                    ) : (
                      ''
                    )}{' '}
                    {prerequisites ? <>{renderPrerequisites()}</> : ''}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div style={{ margin: '16px 0 8px 0', fontSize: '16px' }}>
                <b>Tags</b>
              </div>
              <div style={{ width: '50%' }}>{renderTags('desktop')}</div>
              {/* COURSE TOPICS */}
              <div style={{ margin: '16px 0 8px 0', fontSize: '16px' }}>
                <b>Topics</b>
              </div>
              <div className='ui stretched grid'>
                {renderCourseSections('desktop')}
              </div>
            </div>
          </div>
        </>
      );
    };

    return (
      <div style={{ height: `${window.innerHeight - 98}px` }}>
        <div className='slide-up no-scrollbar' ref={topRef}>
          <div style={{ padding: '20px' }}>
            <BackArrow onClick={onCloseInfo} left='20px' top='20px' />
            <div className='mobile-only'>{renderMobile()}</div>
            <div className='desktop-only'>{renderDesktop()}</div>
          </div>
        </div>
      </div>
    );
  }
  return '';
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    courses: state.courses.courses,
  };
};

export default connect(mapStateToProps, {
  fetchCourses,
  fetchSection,
  setTagTerm,
  registerUserInterest,
})(CourseInfo);
