import actions from '../actions/types';

export default (
  state = {
    canAnswer: {},
    userQuestions: [],
    userQuestionsToAnswer: [],
    record: [],
  },
  action
) => {
  switch (action.type) {
    case actions.GET_QUESTION:
      return {
        ...state,
        canAnswer: {
          canAnswer: action.payload.canAnswer,
          reason: action.payload.reason,
        },
      };
    case actions.GET_USER_QUESTIONS:
      return {
        ...state,
        userQuestions: action.payload.questions,
      };
    case actions.GET_USER_QUESTIONS_TO_ANSWER:
      return {
        ...state,
        userQuestionsToAnswer: action.payload.questions,
      };
    case actions.UPDATE_USER_RECORD:
      return {
        ...state,
        record: action.payload.record,
      };
    case actions.RESET_USER_QUESTIONS:
      return {
        ...state,
        userQuestions: action.payload.questions,
      };
    default:
      return state;
  }
};
