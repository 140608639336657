import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import history from '../../history';

import validator from 'validator';

const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <div
        className='ui pointing label'
        style={{
          backgroundColor: '#E56B6F',
          color: '#fff',
          borderRadius: '50px',
        }}
      >
        <div className='header'>{error}</div>
      </div>
    );
  }
};

const renderInput = ({
  input,
  placeholder,
  label,
  type,
  icon,
  meta,
  rightIcon,
}) => {
  return (
    <div className='field'>
      <label>{label}</label>
      <div className='ui left icon input'>
        <i className={`${icon} icon`}></i>
        <input
          {...input}
          className='primary-input'
          autoComplete='off'
          placeholder={placeholder}
          type={type}
        />
        {/* {rightIcon ? (
          <i
            className='eye ui link icon'
            style={{ right: '1px', left: 'auto' }}
            onClick={() => onViewClick(rightIcon)}
          />
        ) : (
          ''
        )} */}
      </div>

      {renderError(meta)}
    </div>
  );
};

let UserForm = (props) => {
  const handleCancelClick = () => {
    if (props.lastPath) {
      history.push('/courses');
    } else {
      history.goBack() || history.push('/courses');
    }
  };

  const buttons = ['Signup', 'Login', 'Save', 'Set new password'];

  const formatButton = () => {
    if (buttons.includes(props.button)) {
      return 'secondary-button';
    } else if (props.button === 'Delete' || props.button === 'Delete account') {
      return 'red-button';
    } else {
      return 'primary-button';
    }
  };

  const onSubmit = (formValues) => {
    props.onSubmit(formValues);
  };

  return (
    <form onSubmit={props.handleSubmit(onSubmit)} className='ui form error'>
      {props.button === 'Login' ||
      props.button === 'Set new password' ||
      props.button === 'Delete account' ? (
        ''
      ) : (
        <Field
          name='name'
          component={renderInput}
          placeholder='Name'
          label={`Enter ${props.button === 'Save' ? `new` : `your`} name`}
          icon='user'
        />
      )}
      {props.button === 'Delete account' ? (
        ''
      ) : (
        <Field
          name='email'
          component={renderInput}
          placeholder='Email'
          label={`Enter ${props.button === 'Save' ? `new` : `your`}  email`}
          icon='envelope'
        />
      )}
      {props.button === 'Delete account' ? (
        ''
      ) : (
        <Field
          name='password'
          component={renderInput}
          placeholder='Password'
          label={`Enter ${
            props.button === 'Save' || props.button === 'Set new password'
              ? `new`
              : `a`
          } password`}
          type='password'
          icon='lock'
          rightIcon='password'
        />
      )}
      {props.button === 'Delete account' || props.button === 'Login' ? (
        ''
      ) : (
        <Field
          name='confirm_password'
          component={renderInput}
          placeholder='Password'
          label={`Confirm ${
            props.button === 'Save' || props.button === 'Set new password'
              ? `new`
              : ''
          } password`}
          type='password'
          icon='lock'
          rightIcon='confirm'
        />
      )}
      {props.button === 'Save' || props.button === 'Delete account' ? (
        <Field
          name='current_password'
          component={renderInput}
          placeholder='Current password'
          label='Enter current password'
          type='password'
          icon='lock'
          rightIcon='current'
        />
      ) : (
        ''
      )}
      <button id={`${formatButton()}`} className='ui button'>
        {props.button}
      </button>
      <button
        onClick={handleCancelClick}
        style={{ borderRadius: '50px' }}
        className='ui button'
      >
        Cancel
      </button>
    </form>
  );
};

const validate = (formValues, form) => {
  const errors = {};
  if (
    form.button !== 'Login' &&
    form.button !== 'Set new password' &&
    form.button !== 'Save'
  ) {
    if (!formValues.name) {
      errors.name =
        'A username is required - we need to know what to call you!';
    }
  }

  if (form.button !== 'Set new password' && form.button !== 'Save') {
    if (!formValues.password) {
      errors.password = 'Please enter a password';
    }
  }

  if (
    (form.button === 'Signup' ||
      form.button === 'Save' ||
      form.button === 'Set new password') &&
    formValues.password &&
    formValues.password.length < 7
  ) {
    errors.password = 'Your password must be at least 7 characters long';
  }

  if (!formValues.email && form.button !== 'Save') {
    errors.email = 'Please enter an email';
  }

  if (formValues.email && !validator.isEmail(formValues.email.trim())) {
    errors.email = 'Please provide a valid email';
  }
  if (form.button === 'Save' && !formValues.current_password) {
    errors.current_password =
      'Please provide your current password to update your details';
  }
  if (form.button === 'Delete account' && !formValues.current_password) {
    errors.current_password =
      'Please provide your password to delete your account';
  }

  if (form.button !== 'Login' && form.button !== 'Delete account') {
    if (!formValues.confirm_password && formValues.password) {
      errors.confirm_password = 'Please confirm your password';
    } else if (
      formValues.confirm_password &&
      formValues.confirm_password !== formValues.password
    ) {
      errors.confirm_password = 'Passwords do not match';
    }
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

UserForm = connect(mapStateToProps)(UserForm);

export default reduxForm({
  form: 'userForm',
  validate,
})(UserForm);
