import React from 'react';

const BackArrow = (props) => {
  const left = props.left;
  const top = props.top;
  const notAbsolute = props.notAbsolute;
  const type = props.type;
  const color = props.color;

  return (
    <div onClick={props.onClick}>
      <i
        className={`large ${
          type === 'course' ? 'close' : 'left arrow'
        } icon back-arrow${notAbsolute ? '' : '-absolute'}`}
        style={{
          color: `${color}`,
          cursor: 'pointer',
          left: `${left}`,
          top: `${top}`,
        }}
      ></i>
    </div>
  );
};

export default BackArrow;
