import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../../widgets/Modal';

import { verifyEmail } from '../../../actions';

const VerifyConf = (props) => {
  useEffect(() => {
    const token = props.match.params.id;

    props.verifyEmail(token);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Modal title='Your email is verified!' size='tiny'>
        <div>
          <p>
            Thank you for verifying your email. You can now buy anything in the
            marketplace!
          </p>
          <div className='centered'>
            <Link to='/' id='secondary-button' className='ui button'>
              Close
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, { verifyEmail })(VerifyConf);
