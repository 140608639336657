import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import CardLoader from '../../widgets/CardLoader';

import history from '../../../history';

const CourseList = (props) => {
  const [firstPosition, setFirstPosition] = useState(1);
  const [columnHeight, setColumnHeight] = useState(364);
  const [scrollable, setScrollable] = useState(false);

  const title = props.title;
  const courses = props.courses;
  const user = props.user;

  const ref = useRef();
  const columnRef = useRef();

  const totalCourses = courses.length;

  //  Figure out what this warning is saying
  useEffect(() => {
    if (columnRef.current && columnRef.current.offsetHeight !== columnHeight) {
      setColumnHeight(columnRef.current.offsetHeight);
    }
  });

  const onCourseClick = (course) => {
    history.push(`/course/${course.id}`);
  };

  const onRightScrollClick = async (ref) => {
    if (!scrollable) {
      setScrollable(true);
      ref.current.scrollBy({
        top: 0,
        left: columnRef.current.offsetWidth,
        behavior: 'smooth',
      });
      setFirstPosition(firstPosition + 1);
      await setTimeout(() => {
        setScrollable(false);
      }, 500);
    }
  };

  const onLeftScrollClick = async (ref) => {
    if (!scrollable) {
      setScrollable(true);
      ref.current.scrollBy({
        top: 0,
        left: -columnRef.current.offsetWidth,
        behavior: 'smooth',
      });
      setFirstPosition(firstPosition - 1);
      await setTimeout(() => {
        setScrollable(false);
      }, 500);
    }
  };

  const renderProgress = (course) => {
    if (user && user.courses.some((e) => e.course === course.id)) {
      const userProgress = user.courses.find((e) => e.course === course.id)
        .progress;
      return (
        <div id='no-border' className='extra content'>
          <div
            className='ui tiny progress'
            data-percent={userProgress}
            style={{ margin: '0' }}
          >
            <div
              className='bar'
              style={{
                width: `${userProgress}%`,
                minWidth: '0',
                backgroundColor: '#6556ff',
              }}
            ></div>
          </div>
        </div>
      );
    }
  };

  const renderTopLabel = (course) => {
    if (course.comingSoon) {
      return (
        <div
          className='ui top right attached label'
          style={{
            borderRadius: '16px',
            margin: '4px',
            color: '#ffffff',
            backgroundColor: '#6556FF',
          }}
        >
          Coming Soon
        </div>
      );
    } else if (
      course.prerequisites.length !== 0 &&
      user &&
      !course.prerequisites.every((el) => user.completedCourses.includes(el.id))
    ) {
      return (
        <div
          className='ui top right attached label'
          style={{
            borderRadius: '16px',
            margin: '4px',
            color: '#ffffff',
            backgroundColor: '#6556FF',
          }}
        >
          <i className='lock icon' style={{ margin: '0' }}></i>
        </div>
      );
    }
    return '';
  };

  const renderDesktopCourseList = (selected) => {
    return selected.map((course) => {
      return (
        <div
          className='four wide column'
          key={selected.indexOf(course)}
          ref={columnRef}
        >
          <div
            className='ui link card course-card'
            onClick={() => onCourseClick(course)}
          >
            <div id='course-card-image' className='image'>
              <img src={course.image} alt='course_title_image'></img>
              {renderTopLabel(course)}
            </div>
            <div className='content' style={{ border: 'none' }}>
              <div className='header'>{course.title}</div>
            </div> 
            {renderProgress(course)}
          </div>
        </div>
      );
    });
  };

  const renderCourseList = (selected) => {
    return selected.map((course) => {
      return (
        <div className='ten wide column' key={selected.indexOf(course)}>
          <div
            className='ui link card course-card'
            onClick={() => onCourseClick(course)}
          >
            <div id='course-card-image' className='image'>
              <img src={course.image} alt='course_title_image'></img>
              {renderTopLabel(course)}
            </div>
            <div className='content' style={{ border: 'none' }}>
              <div className='header'>{course.title}</div>
            </div>
            {renderProgress(course)}
          </div>
        </div>
      );
    });
  };

  const renderScrollIcon = (direction) => {
    if (direction === 'left') {
      return (
        <div
          className='desktop-scroll-arrow left'
          style={{ bottom: `${columnHeight / 2 - 28}px` }}
        >
          <i
            className='huge caret left icon'
            onClick={() => onLeftScrollClick(ref)}
          ></i>{' '}
        </div>
      );
    } else {
      return (
        <div
          className='desktop-scroll-arrow right'
          style={{
            bottom: `${columnHeight / 2 - 28}px`,
          }}
        >
          <i
            className='huge caret right icon'
            onClick={() => onRightScrollClick(ref)}
          ></i>{' '}
        </div>
      );
    }
  };

  if (courses.length === 0) {
    return (
      <div>
        <h2 className='empty-header'> </h2>
        <div className='mobile-only'>
          <CardLoader width='ten' height='260px' overflow={true} />
        </div>
        <div className='desktop-only' style={{ position: 'relative' }}>
          <CardLoader width='four' height='260px' overflow={false} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2>{title}</h2>
      <div className='mobile-only'>
        <div
          className='ui stretched grid x-overflow no-scrollbar'
          style={{ marginBottom: '24px' }}
        >
          {renderCourseList(courses)}
        </div>
      </div>
      <div className='desktop-only' style={{ position: 'relative' }}>
        <div
          className='ui stretched grid x-overflow-desktop no-scrollbar'
          style={{ marginBottom: '24px' }}
          ref={ref}
        >
          {renderDesktopCourseList(courses)}
        </div>
        {firstPosition === 1 ? '' : renderScrollIcon('left')}
        {firstPosition + 4 > totalCourses ? '' : renderScrollIcon('right')}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(CourseList);
