import React, { useEffect, useRef} from 'react';
import DOMPurify from 'dompurify';
import { connect } from 'react-redux';

import history from '../../../history';

const SubSection = (props) => {
  const course = props.course;
  let count = props.count;
  const list = course.newContent;

  const ref = useRef();

  // useEffect(() => {
  //   if (highestSection > parseInt(course.section)) {
  //     props.currentContent(course.newContent.length);
  //   }
  //   return () => props.currentContent(1);
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (ref.current) {
      if (count === 1) {
        window.scroll(0, 0);
      } else {
        ref.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [count]);

  const visibleList = list
    .filter((el) => el.part <= count)
    .filter((el) => !(el.category === 'break' && parseInt(el.part) >= count));

  const onContinue = (val) => {
    if (val === 'complete') {
      history.push(
        `/course/live/${course.id}/${course.grouping}/${
          parseInt(course.section) + 1
        }`
      );
    } else {
      count++;
      props.currentContent(count++);
    }
  };

  const renderContinueButton = () => {
    if (list.length !== visibleList.length) {
      return (
        <div
          id='basic-secondary-course-button'
          className='ui basic button centered'
          onClick={() => onContinue('continue')}
        >
          <div>
            Continue{' '}
            <i
              className='angle down icon'
              style={{ color: '#6556ff', verticalAlign: 'middle' }}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        id='secondary-course-button'
        className='ui button'
        onClick={() => onContinue('complete')}
        style={{ width: '100%' }}
      >
        <div>
          Got it{' '}
          <i
            className='arrow right icon'
            style={{ margin: '0 0 0 8px', fontSize: '.9em' }}
          />
        </div>
      </div>
    );
  };

  const renderDetails = (el) => {
    if (el.category === 'text') {
      return (
        <>
          <p
            className='course-text'
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(el.content) }}
          ></p>
        </>
      );
    } else if (el.category === 'image') {
      return (
        <>
          <img
            src={el.content}
            className='course-image'
            alt='course_image'
          ></img>
        </>
      );
    } else if (el.category === 'break') {
      if (parseInt(el.part) === count - 1) {
        return (
          <>
            <div
              className='centered course-text'
              style={{
                width: '60px',
                borderBottom: 'solid 1px #dedede',
                margin: 'auto',
              }}
            ></div>
          </>
        );
      }
      return (
        <>
          <div
            className='centered course-text text-divider'
            style={{
              width: '60px',
            }}
          ></div>
        </>
      );
    }
  };

  const renderContent = () => {
    return visibleList.map((el) => {
      return (
        <div className='item' key={visibleList.indexOf(el)}>
          {renderDetails(el)}
        </div>
      );
    });
  };

  return (
    <div>
      <div className='ui list'>{renderContent()}</div>
      <div className='centered'>{renderContinueButton()}</div>
      <div ref={ref}></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // course: state.course.course
  };
};

export default connect(mapStateToProps)(SubSection);
