import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const MobileFooter = (props) => {
  const [active, setActive] = useState('');

  const ref = useRef();

  const user = props.user;

  useEffect(() => {
    if (window.location.pathname === '/' && props.isSignedIn) {
      setActive('/courses');
    } else {
      setActive(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    const onClick = () => {
      if (window.location.pathname === '/' && props.isSignedIn) {
        setActive('/courses');
      } else if (active !== window.location.pathname) {
        setActive(window.location.pathname);
      }
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
    // eslint-disable-next-line
  }, []);

  const onClick = (pathname) => {
    setActive(pathname);
  };

  return (
    <div
      className={`ui fluid ${
        user ? 'four' : 'three'
      } item labeled icon secondary bottom fixed menu`}
      style={{ height: '58px' }}
      ref={ref}
    >
      <Link
        to='/courses'
        className='item mobile-footer-item'
        onClick={() => onClick('/courses')}
      >
        <i
          id={`mobile-menu-icon${active === '/courses' ? '-active' : ''}`}
          className='book icon'
        ></i>
      </Link>
      {user ? (
        <Link
          to='/practice/questions'
          className='item mobile-footer-item'
          onClick={() => onClick('/practice/questions')}
        >
          <i
            id={`mobile-menu-icon${
              active === '/practice/questions' ? '-active' : ''
            }`}
            className='pencil alternate icon'
          ></i>
        </Link>
      ) : (
        ''
      )}
      <Link
        to='/forums'
        className='item mobile-footer-item'
        onClick={() => onClick('/forums')}
      >
        <i
          id={`mobile-menu-icon${active === '/forums' ? '-active' : ''}`}
          className='comments icon'
        ></i>
      </Link>
      <Link
        to='/marketplace'
        className='item mobile-footer-item'
        onClick={() => onClick('/marketplace')}
      >
        <i
          id={`mobile-menu-icon${active === '/marketplace' ? '-active' : ''}`}
          className='shopping basket icon'
        ></i>
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    isSignedIn: state.user.isSignedIn,
  };
};

export default connect(mapStateToProps)(MobileFooter);
