import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';


import { TransitionGroup, CSSTransition } from 'react-transition-group';


import DOMPurify from 'dompurify';

const Definitions = (props) => {
  const [current, setCurrent] = useState([]);
  const [definitions, setDefinitions] = useState(props.course.definitions);
  const [closedDefinitions, setClosedDefinitions] = useState([]);
  const [allClosed, setAllClosed] = useState(false);
  const [viewed, setViewed] = useState([]);

  useEffect(() => {
    setDefinitions(props.course.definitions);
    setCurrent([]);
    setViewed([]);
    setClosedDefinitions([]);
    setAllClosed(false);
  }, [props.course]);

  useEffect(() => {
    if (definitions.length === 0) {
      setAllClosed(true);
    } else {
      setAllClosed(false);
    }
  }, [closedDefinitions]);

  const handleCardClick = (index) => {
    if (current.includes(index)) {
      if (!viewed.includes(index)) {
        setViewed([...viewed, index]);
      }
      setCurrent(current.filter((el) => el !== index));
    } else {
      setCurrent([...current, index]);
    }
  };

  const onCloseClick = (event, definition) => {
    event.stopPropagation();

    setDefinitions(definitions.filter((def) => def !== definition));

    setClosedDefinitions([...closedDefinitions, definition]);
  };

  const onReopenClick = (definition) => {
    setDefinitions([definition, ...definitions]);

    setClosedDefinitions(closedDefinitions.filter((def) => def !== definition));
  };

  const renderClosedDefinitions = () => {
    return closedDefinitions.map((definition) => {
      return (
        <div
          id='green-course-button'
          style={{ fontWeight: 'unset', marginBottom: '8px' }}
          className='ui mini button'
          onClick={() => onReopenClick(definition)}
          key={closedDefinitions.indexOf(definition)}
        >
          <i className='check icon' />
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(definition.title),
            }}
          />
        </div>
      );
    });
  };

  const renderDefinitions = () => {
    return definitions.map((definition) => {
      const index = definitions.indexOf(definition);

      return (
        <CSSTransition
          key={definition._id}
          timeout={300}
          classNames='card-item'
        >
          <div className='flip-card'>
            <div
              className={`ui card flip-card-inner ${
                current.includes(index) ? 'active' : ''
              }`}
            >
              <div
                className='flip-card-front'
                onClick={() => handleCardClick(index)}
              >
                <div className='centered card-text front'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(definition.title),
                    }}
                    style={{ flexBasis: '100%' }}
                  ></div>
                  {viewed.includes(index) ? (
                    <div
                      id='secondary-course-button'
                      className='ui centered tiny button card-button'
                      onClick={(event) => onCloseClick(event, definition)}
                    >
                      Got it
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div
                className='flip-card-back'
                onClick={() => handleCardClick(index)}
              >
                <div
                  className='centered card-text back'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(definition.content),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </CSSTransition>
      );
    });
  };

  return (
    <div>
      <div className='course-text centered'>
        {allClosed
          ? 'No more definitions!'
          : "Tap each term to see its definition, and tap 'Got it' once you're happy with the term!"}
      </div>
      <div className='centered'>{renderClosedDefinitions()}</div>
      <div className='centered'>
        <TransitionGroup>{renderDefinitions()}</TransitionGroup>
      </div>
      <div className='centered'>
        {allClosed ? (
          <div
            id='secondary-course-button'
            className='ui button'
            style={{ width: '100%' }}
            onClick={props.onContinue}
          >
            Continue
          </div>
        ) : (
          <div
            id='basic-secondary-course-button'
            className='ui button'
            style={{ width: '100%', marginTop: '12px' }}
            onClick={props.onContinue}
          >
            Continue
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(Definitions);
