import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Modal from '../../widgets/Modal';
import { resetErrors } from '../../../actions';

const EditSuccess = (props) => {
  useEffect(() => {
    return () => props.resetErrors();
    //eslint-disable-next-line
  }, []);

  return (
    <Modal title='Succesfully updated details' size='tiny'>
      <div>
        <div className='ui positive message'>
          <div className='header'>
            Your details have been updated successfully!
          </div>
        </div>
        <Link to='/account' className='ui primary button'>
          Head to your account
        </Link>
      </div>
    </Modal>
  );
};

export default connect(null, { resetErrors })(EditSuccess);
