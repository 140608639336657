import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import history from '../../../history';

import {
  getCourseTitles,
  getForums,
  createForum,
} from '../../../actions/index';

const CreateForum = (props) => {
  const [term, setTerm] = useState('');
  const [titleTerm, setTitleTerm] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({});

  const courses = props.courses;
  const filteredTopic = props.topic || 'All';

  const user = props.user;

  useEffect(() => {
    if (!courses) {
      props.getForums(filteredTopic, user);
      props.getCourseTitles();
    }

    //eslint-disable-next-line
  }, []);

  const onForumSubmit = (e) => {
    e.preventDefault();
    const creator = user._id;
    const creatorName = user.name;
    let topic;

    selectedTopic === 'Pyxium'
      ? (topic = 'pyxium')
      : (topic = courses.find((course) => course.id === selectedTopic.id).id);

    props.createForum(titleTerm, creator, creatorName, topic, term);
  };

  const onTitleInputChange = (e) => {
    setTitleTerm(e.target.value);
  };

  const onInputChange = (e) => {
    setTerm(e.target.value);
  };

  const onDropdownClick = () => {
    if (!dropdownActive && initialLoad) {
      setDropdownActive(true);
      setInitialLoad(false);
    } else if (!dropdownActive) {
      setDropdownActive(true);
    } else {
      setDropdownActive(false);
    }
  };

  const renderDropdownAnimation = () => {
    if (initialLoad) {
      return;
    } else {
      if (dropdownActive) {
        return 'animating slide down in visible transition primary-input-large';
      }
      return 'animating slide down out visible transition primary-input-large';
    }
  };

  const renderOptions = () => {
    if (courses && user) {
      const userCourses = courses.filter(
        (course) =>
          user.courses.some((e) => e.course === course.id) ||
          course.title === 'Pyxium'
      );

      return userCourses.map((course) => {
        return (
          <div
            className='item'
            data-value={userCourses.indexOf(course)}
            onClick={() => setSelectedTopic(course)}
            key={userCourses.indexOf(course)}
          >
            {course.title}
          </div>
        );
      });
    }
    return '';
  };
  return (
    <div>
      <form className='ui form' onSubmit={onForumSubmit}>
        <div className='field'>
          <label>Topic area</label>
          <div
            className={`ui selection dropdown ${
              dropdownActive
                ? 'visible active primary-input-large'
                : 'primary-input'
            }`}
            onClick={onDropdownClick}
          >
            <input type='hidden' name='topic' />
            <i className='dropdown icon'></i>
            {selectedTopic.title ? (
              <div className='text'>{selectedTopic.title}</div>
            ) : (
              <div className='default text'>Select topic area...</div>
            )}
            <div className={`menu ${renderDropdownAnimation()}`}>
              {renderOptions()}
            </div>
          </div>
        </div>
        <div className='field'>
          <label>Post title</label>
          <input
            className='primary-input'
            label='title'
            placeholder='Type post title here'
            onChange={onTitleInputChange}
            value={titleTerm}
          />
        </div>
        <div className='field' style={{ marginTop: '8px', marginLeft: '0px' }}>
          <label>Discussion</label>
          <textarea
            className='primary-input-large'
            label='discussion'
            placeholder='Type your post here'
            onChange={onInputChange}
            value={term}
          />
        </div>
      </form>
      <div style={{ marginTop: '16px' }}>
        <button
          onClick={onForumSubmit}
          id='secondary-button'
          style={{ borderRadius: '50px' }}
          className='ui button'
        >
          Create discussion
        </button>
        <button
          className='ui button'
          style={{ borderRadius: '50px' }}
          onClick={() => history.goBack()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    forums: state.forums.forums,
    courses: state.forums.courses,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  getCourseTitles,
  getForums,
  createForum,
})(CreateForum);
