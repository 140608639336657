import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

import Like from '../../widgets/Like';
import AddComment from '../../widgets/AddComment';
import timeAgo from '../../../tools/timeAgo';
import Follow from '../../widgets/Follow';
import history from '../../../history';

import {
  getForum,
  removeComment,
  getCourseTitles,
  closeForum,
  deleteForum,
} from '../../../actions/index';

const Forum = (props) => {
  const [addingComment, setAddingComment] = useState(false);
  const [addingReply, setAddingReply] = useState(false);
  const [liveReply, setLiveReply] = useState('');
  const [showReplies, setShowReplies] = useState([]);
  const [sortingType, setSortingType] = useState('newest');
  const [firstRemove, setFirstRemove] = useState('');
  const [secondRemove, setSecondRemove] = useState('');
  const [editingComment, setEditingComment] = useState('');
  const [deleteReasonValue, setDeleteReasonValue] = useState('');
  const [forumClose, setForumClose] = useState(false);
  const [forumDelete, setForumDelete] = useState(false);
  const [forumReopen, setForumReopen] = useState(false);
  const [notLoggedIn, setNotLoggedIn] = useState({
    notLoggedIn: false,
    part: '',
  });
  const [notEnrolled, setNotEnrolled] = useState({
    notEnrolled: false,
    part: '',
  });

  const ref = useRef();
  const closeForumRef = useRef();
  const deleteForumRef = useRef();
  const reopenForumRef = useRef();

  useEffect(() => {
    props.getForum(props.match.params.id);
    props.getCourseTitles();

    const onBodyClick = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
        setForumClose(false);
        setForumDelete(false);
        setForumReopen(false);
        setNotLoggedIn({ notLoggedIn: false, part: '' });
        setNotEnrolled({ notLoggedEnrolled: false, part: '' });
      } else if (
        closeForumRef.current &&
        closeForumRef.current.contains(e.target)
      ) {
        setFirstRemove('');
        setSecondRemove('');
        setForumDelete(false);
        setNotLoggedIn({ notLoggedIn: false, part: '' });
        setNotEnrolled({ notLoggedEnrolled: false, part: '' });
      } else if (
        deleteForumRef.current &&
        deleteForumRef.current.contains(e.target)
      ) {
        setFirstRemove('');
        setSecondRemove('');
        setForumClose(false);
        setForumReopen(false);
        setNotLoggedIn({ notLoggedIn: false, part: '' });
        setNotEnrolled({ notLoggedEnrolled: false, part: '' });
      } else if (
        reopenForumRef.current &&
        reopenForumRef.current.contains(e.target)
      ) {
        setFirstRemove('');
        setSecondRemove('');
        setForumDelete(false);
        setNotLoggedIn({ notLoggedIn: false, part: '' });
        setNotEnrolled({ notLoggedEnrolled: false, part: '' });
      } else {
        // setFirstRemove('');
        // setSecondRemove('');
        setForumClose(false);
        setForumDelete(false);
        setForumReopen(false);
        // setNotLoggedIn({ notLoggedIn: false, part: '' });
        // setNotEnrolled({ notLoggedEnrolled: false, part: '' });
      }
    };

    document.addEventListener('click', onBodyClick);

    return () => {
      document.removeEventListener('click', onBodyClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forum = props.forum;
  const courses = props.courses;
  const user = props.user;

  if (forum && forum.url !== props.match.params.id) {
    props.getForum(props.match.params.id);
  }

  const onShowRepliesClick = (comment) => {
    if (showReplies.includes(comment)) {
      setShowReplies(showReplies.filter((e) => e !== comment));
    } else {
      setShowReplies(showReplies.concat(comment));
    }
  };

  const onDeleteReasonChange = (e) => {
    setDeleteReasonValue(e.target.value);
  };

  const onDeleteModerator = (comment, reason, moderator, type) => {
    if (type === 'comment') {
      props.removeComment(forum._id, comment._id, false, reason, moderator);
      setSecondRemove('');
    } else if (type === 'forum') {
      props.deleteForum(forum._id, false, reason, moderator);
      setSecondRemove('');
    }
  };

  const onKeyDown = (e, comment, reason, moderator, type) => {
    if (e.key === 'Enter') {
      onDeleteModerator(comment, reason, moderator, type);
    }
  };

  const onDeleteCreator = (part, type) => {
    if (type === 'comment') {
      if (user._id === part.commenter.id) {
        props.removeComment(forum._id, part._id, true);
        setFirstRemove('');
      } else {
        // props.removeComment(forum._id, comment._id);
        setFirstRemove('');
        setSecondRemove(part._id);
      }
    } else if (type === 'forum') {
      if (user._id === part.creator) {
        props.deleteForum(part._id, true);
      } else {
        setSecondRemove(part._id);
      }
    }
  };

  const onEditClick = (comment) => {
    setEditingComment(comment);
  };

  const calculateReplies = (comment) => {
    let totalReplies = 0;

    const addReplies = (comment) => {
      const allReplies = forum.comments.filter((e) => e.reply);

      const replies = allReplies.filter(
        (reply) => JSON.stringify(reply.replyTo) === JSON.stringify(comment)
      );

      totalReplies += replies.length;
      replies.forEach((reply) => addReplies(reply._id));
    };

    addReplies(comment);

    return totalReplies;
  };

  const toggleAddComment = (comment) => {
    if (!addingReply) {
      setAddingReply(true);
      setLiveReply(comment);
    } else {
      setAddingReply(false);
      setLiveReply('');
    }
  };

  const renderSorting = () => {
    return (
      <div className='ui text menu'>
        <div className='header item'>Sort By</div>
        <p
          style={{ cursor: 'pointer' }}
          className={`${sortingType === 'newest' ? 'active' : ''} item`}
          onClick={() => {
            setSortingType('newest');
            setShowReplies([]);
          }}
        >
          Newest
        </p>
        <p
          style={{ cursor: 'pointer' }}
          className={`${sortingType === 'rating' ? 'active' : ''} item`}
          onClick={() => {
            setSortingType('rating');
            setShowReplies([]);
          }}
        >
          Highest Rated
        </p>
        <p
          style={{ cursor: 'pointer' }}
          className={`${sortingType === 'replies' ? 'active' : ''} item`}
          onClick={() => {
            setSortingType('replies');
            setShowReplies([]);
          }}
        >
          Most Replies
        </p>
      </div>
    );
  };

  const renderAuthor = (commenter) => {
    if (commenter.id === forum.instructor) {
      return (
        <>
          <span className='author' style={{ color: '#ffcc00' }}>
            {commenter.name}{' '}
            {commenter.name !== 'Pyxium' ? '(Course Instructor)' : ''}
          </span>
        </>
      );
    } else if (forum.moderators.includes(commenter.id)) {
      return (
        <>
          <span className='author' style={{ color: '#7b9e89' }}>
            {commenter.name} (Moderator)
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className='author'>{commenter.name}</span>
        </>
      );
    }
  };

  const renderText = (comment) => {
    if (editingComment === comment._id) {
      return (
        <AddComment
          forum={forum._id}
          topic={forum.topic}
          comment={comment._id}
          type='edit'
          text={comment.text}
          onCancel={() => {
            setEditingComment('');
          }}
        />
      );
    } else {
      return <>{DOMPurify.sanitize(comment.text)}</>;
    }
  };

  const renderForumEditButton = (forum) => {
    if (user._id === forum.creator) {
      if (editingComment === forum._id) {
        return '';
      }
      return (
        <span
          className='main-topic-forum-edit'
          onClick={() => onEditClick(forum._id)}
        >
          Edit
        </span>
      );
    }
    return '';
  };

  const renderEditButton = (comment) => {
    if (user._id === comment.commenter.id) {
      if (editingComment === comment._id) {
        return '';
      }
      return (
        <p className='comment-action' onClick={() => onEditClick(comment._id)}>
          Edit
        </p>
      );
    }
    return '';
  };

  const renderUnableToLikeMessage = (part) => {
    return (
      <div style={{ marginTop: '8px' }}>
        {notLoggedIn.notLoggedIn && notLoggedIn.part === part._id ? (
          <>
            <Link style={{ color: '#fc0' }} to='/login'>
              <b>Log in</b>
            </Link>{' '}
            to vote
          </>
        ) : (
          ''
        )}
        {notEnrolled.notEnrolled && notEnrolled.part === part._id ? (
          <>
            <Link style={{ color: '#fc0' }} to={`/course/${forum.topic}`}>
              <b>Enrol</b>
            </Link>{' '}
            to vote
          </>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderMainTopic = () => {
    return (
      <div>
        <div style={{ marginBottom: '24px' }}>
          <h1>{DOMPurify.sanitize(forum.title)} </h1>
        </div>

        <p>
          <span style={{ color: '#c6c6c6' }}>by {forum.creatorName}</span>
        </p>
        {editingComment === forum._id ? (
          <AddComment
            forum={forum._id}
            topic={forum.topic}
            type='forum'
            text={forum.mainPost}
            onCancel={() => {
              setEditingComment('');
            }}
          />
        ) : (
          <p style={{ whiteSpace: 'pre-line' }}>{forum.mainPost}</p>
        )}
        <p style={{ marginTop: '8px' }}>
          {forum.score}{' '}
          <Like
            item={forum}
            type='forum'
            notLoggedIn={() => {
              setNotLoggedIn({ notLoggedIn: true, part: forum._id });
            }}
            notEnrolled={() => {
              setNotEnrolled({ notEnrolled: true, part: forum._id });
            }}
          />
          {forum.participants.length}{' '}
          <i className='user icon' style={{ color: '#c6c6c6' }}></i>
          {forum.comments.length}{' '}
          <i className='comment outline icon' style={{ color: '#c6c6c6' }}></i>
          {renderForumEditButton(forum)}
        </p>
        <div>{renderUnableToLikeMessage(forum)}</div>
        <Follow place='forum' forum={forum} />
      </div>
    );
  };

  const renderModeratorDeleteMenu = (part, type) => {
    return (
      <div
        className='ui vertical menu'
        style={{ display: 'inline-block' }}
        ref={ref}
      >
        <div className='item'>
          <div className='header'>Reason for deletion</div>
          <div className='menu'>
            <span
              className='item menu-no-padding'
              onClick={() =>
                onDeleteModerator(
                  part,
                  'Discriminatory language',
                  user._id,
                  type
                )
              }
            >
              Discriminatory language
            </span>
            <span
              className='item menu-no-padding'
              onClick={() =>
                onDeleteModerator(part, 'Bullying', user._id, type)
              }
            >
              Bullying
            </span>
            <span
              className='item menu-no-padding'
              onClick={() =>
                onDeleteModerator(part, 'Hate speech', user._id, type)
              }
            >
              Hate speech
            </span>
            <span
              className='item menu-no-padding'
              onClick={() =>
                onDeleteModerator(part, 'Not relevant', user._id, type)
              }
            >
              Not relevant
            </span>
            <span
              className='item menu-no-padding'
              onClick={() =>
                onDeleteModerator(part, 'Misinformation', user._id, type)
              }
            >
              Misinformation
            </span>
            <div
              className='ui input'
              style={{ maxWidth: '85%', marginLeft: '7%' }}
            >
              <input
                type='text'
                placeholder='Other...'
                onChange={onDeleteReasonChange}
                onKeyDown={(e) =>
                  onKeyDown(e, part, deleteReasonValue, user._id, type)
                }
              />
            </div>
            <span
              className='cancel-text item menu-no-padding'
              onClick={() => setSecondRemove('')}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDelete = (comment) => {
    if (
      (forum.moderators.some((mod) => mod.moderator === user._id) ||
        user._id === comment.commenter.id) &&
      !addingReply
    ) {
      if (firstRemove === comment._id) {
        return (
          <span>
            <span
              className='text delete-comment-text'
              onClick={() => onDeleteCreator(comment, 'comment')}
              ref={ref}
            >
              Confirm delete
            </span>{' '}
            - all replies to this comment will also be lost
            <span className='cancel-text' onClick={() => setFirstRemove('')}>
              {' '}
              Cancel
            </span>
          </span>
        );
      } else if (secondRemove === comment._id) {
        return <>{renderModeratorDeleteMenu(comment, 'comment')}</>;
      } else {
        return (
          <i
            className='trash icon delete-comment'
            onClick={() => setFirstRemove(comment._id)}
          />
        );
      }
    }
    return '';
  };

  const onCloseForumClick = (val) => {
    if (val && !forumClose) {
      setForumClose(true);
    } else if (!val && !forumReopen) {
      setForumReopen(true);
    } else {
      props.closeForum(forum._id, val);
      setForumClose(false);
      setForumReopen(false);
    }
  };

  const onDeleteForumClick = () => {
    if (!forumDelete) {
      setForumDelete(true);
    } else {
      onDeleteCreator(forum, 'forum');
    }
  };

  const renderForumClosed = () => {
    if (
      forum.moderators.some((mod) => mod.moderator === user._id) ||
      forum.creator === user._id
    ) {
      if (forum.closed) {
        return '';
      } else {
        return (
          <div
            id='basic-button'
            className='ui basic button'
            style={{ borderRadius: '50px' }}
            onClick={() => onCloseForumClick(true)}
            ref={closeForumRef}
          >
            {forumClose ? 'Are you sure?' : 'Close forum'}
          </div>
        );
      }
    }
    return '';
  };

  const renderDeleteForum = () => {
    if (
      forum.moderators.some((mod) => mod.moderator === user._id) ||
      forum.creator === user._id
    ) {
      if (secondRemove !== forum._id) {
        return (
          <>
            {forum.closed ? (
              <div
                id='basic-button'
                className='ui basic button'
                style={{ borderRadius: '50px' }}
                onClick={() => onCloseForumClick(false)}
                ref={reopenForumRef}
              >
                {forumReopen ? 'Are you sure?' : 'Reopen forum'}
              </div>
            ) : (
              ''
            )}
            <div
              id='basic-delete-button'
              className='ui basic button'
              style={{ borderRadius: '50px' }}
              onClick={onDeleteForumClick}
              ref={deleteForumRef}
            >
              {forumDelete ? 'Are you sure?' : 'Delete forum'}
            </div>
          </>
        );
      }
      return <>{renderModeratorDeleteMenu(forum, 'forum')}</>;
    }
    return '';
  };

  const renderForumActions = () => {
    return (
      <div style={{ marginTop: '8px' }}>
        {renderForumClosed()}
        {renderDeleteForum()}
      </div>
    );
  };

  const renderReplies = (comment) => {
    const allReplies = forum.comments.filter((e) => e.reply);

    const replies = allReplies.filter(
      (reply) => JSON.stringify(reply.replyTo) === JSON.stringify(comment)
    );

    const sortedReplies = replies.sort((a, b) => b.score - a.score);

    return sortedReplies.map((reply) => {
      return (
        <div className='comment' key={sortedReplies.indexOf(reply)}>
          <div className='avatar'>
            {reply.commenter.image !== 'No image!' ? (
              <img src={reply.commenter.image} alt='user_profile_img' />
            ) : (
              <i className='big user circle icon' />
            )}
          </div>
          <div className='content'>
            {renderAuthor(reply.commenter)}
            <div className='metadata'>
              <div className='date'>
                {' '}
                {timeAgo.format(Date.parse(reply.createdAt))}
              </div>
            </div>
            <div className='text' style={{ whiteSpace: 'pre-line' }}>
              {renderText(reply)}
            </div>
            <div className='actions'>
              {reply.score}{' '}
              <Like
                type='comment'
                item={reply}
                forum={forum}
                notLoggedIn={() => {
                  setNotLoggedIn({ notLoggedIn: true, part: reply._id });
                }}
                notEnrolled={() => {
                  setNotEnrolled({ notEnrolled: true, part: reply._id });
                }}
              />{' '}
              {renderEditButton(reply)}
              {forum.closed ? (
                ''
              ) : (
                <p
                  className='reply comment-action'
                  onClick={() => {
                    toggleAddComment(reply._id);
                  }}
                >
                  Reply
                </p>
              )}
              {renderDelete(reply)}
              {addingReply && liveReply === reply._id ? (
                <AddComment
                  forum={forum._id}
                  topic={forum.topic}
                  replyTo={reply._id}
                  type='reply'
                  onCancel={() => {
                    setAddingComment(false);
                    setAddingReply(false);
                  }}
                />
              ) : (
                ''
              )}
            </div>
            <div>{renderUnableToLikeMessage(reply)}</div>
          </div>
          <div
            className='comments'
            style={{
              boxShadow:
                replies.length > 1 &&
                replies.indexOf(reply) !== replies.length - 1
                  ? ''
                  : 'none',
            }}
          >
            {renderReplies(reply._id)}
          </div>
        </div>
      );
    });
  };

  const renderComments = (forum) => {
    const comments = forum.comments.filter((comment) => !comment.reply);

    if (sortingType === 'newest') {
      comments.sort(
        (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
      );
    } else if (sortingType === 'rating') {
      comments.sort((a, b) => b.score - a.score);
    } else {
      comments.sort(
        (a, b) => calculateReplies(b._id) - calculateReplies(a._id)
      );
    }

    return comments.map((comment) => {
      return (
        <div key={comments.indexOf(comment)}>
          <div className='comment'>
            <div className='avatar'>
              {comment.commenter.image !== 'No image!' ? (
                <img src={comment.commenter.image} alt='user_profile_img' />
              ) : (
                <i className='big user circle icon' />
              )}
            </div>
            <div className='content'>
              {renderAuthor(comment.commenter)}
              <div className='metadata'>
                <div className='date'>
                  {' '}
                  {timeAgo.format(Date.parse(comment.createdAt))}
                </div>
              </div>

              <div className='text' style={{ whiteSpace: 'pre-line' }}>
                {renderText(comment)}
              </div>

              <div className='actions'>
                {comment.score}{' '}
                <Like
                  type='comment'
                  item={comment}
                  forum={forum}
                  notLoggedIn={() => {
                    setNotLoggedIn({ notLoggedIn: true, part: comment._id });
                  }}
                  notEnrolled={() => {
                    setNotEnrolled({ notEnrolled: true, part: comment._id });
                  }}
                />{' '}
                {calculateReplies(comment._id)}{' '}
                <i
                  className='comment outline icon'
                  style={{ color: '#FFCC00' }}
                />
              </div>
              <div>{renderUnableToLikeMessage(comment)}</div>
              <div className='text'>
                <div className='actions'>
                  <span
                    style={{
                      fontSize: '15px',
                      color: '#4183c4',
                      cursor: 'pointer',
                    }}
                    onClick={() => onShowRepliesClick(comment._id)}
                  >
                    Show replies{' '}
                    <i
                      className={`angle ${
                        showReplies.includes(comment._id) ? 'up' : 'down'
                      } icon`}
                    />
                  </span>
                  {renderEditButton(comment)}
                  {forum.closed ? (
                    ''
                  ) : (
                    <p
                      className='reply comment-action'
                      onClick={() => {
                        toggleAddComment(comment._id);
                        onShowRepliesClick(comment._id);
                      }}
                    >
                      Reply
                    </p>
                  )}
                  {addingReply && liveReply === comment._id ? (
                    <AddComment
                      forum={forum._id}
                      topic={forum.topic}
                      replyTo={comment._id}
                      type='reply'
                      onCancel={() => {
                        setAddingComment(false);
                        setAddingReply(false);
                      }}
                    />
                  ) : (
                    ''
                  )}{' '}
                  {renderDelete(comment)}
                </div>
              </div>
            </div>
            {showReplies.includes(comment._id) ? (
              <div className='comments' style={{ boxShadow: 'none' }}>
                {renderReplies(comment._id)}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='ui divider'></div>
        </div>
      );
    });
  };

  if (forum && courses) {
    if (!forum.deleted) {
      let topic;

      if (courses.find((course) => course.id === forum.topic)) {
        topic = courses.find((course) => course.id === forum.topic).title;
      } else {
        topic = 'Pyxium';
      }

      const trimmedTopic = `${topic.substring(0, 15)}${
        topic.length > 15 ? '...' : ''
      }`;

      // Reduce breadcrumb title
      const title = `${forum.title.substring(0, 15)}${
        forum.title.length > 15 ? '...' : ''
      }`;

      return (
        <div>
          <div className='ui breadcrumb'>
            <Link to='/forums' className='section'>
              Forums
            </Link>
            <i className='right angle icon divider'></i>
            <Link to={`/forum/${forum.topic}`} className='section'>
              {trimmedTopic}
            </Link>
            <i className='right angle icon divider'></i>
            <div className='active section'>{title}</div>
          </div>
          {renderMainTopic()}
          {forum.closed ? (
            <div>
              <div
                className='ui label'
                style={{
                  backgroundColor: '#e56b6f',
                  marginBottom: '8px',
                  borderRadius: '50px',
                  marginTop: '8px',
                }}
              >
                Forum closed
              </div>
            </div>
          ) : (
            ''
          )}
          {renderForumActions()}

          <div className='ui threaded divided comments'>
            {' '}
            <h4 className='ui dividing header'>Discussion</h4>
            {renderSorting()}
            {renderComments(forum)}
          </div>
          {!forum.closed ? (
            !addingComment ? (
              <button
                id='primary-button'
                className='ui button'
                onClick={() => setAddingComment(true)}
              >
                Add comment
              </button>
            ) : (
              <AddComment
                type='comment'
                onCancel={() => {
                  setAddingComment(false);
                  setAddingReply(false);
                }}
                forum={forum._id}
                topic={forum.topic}
              />
            )
          ) : (
            ''
          )}
        </div>
      );
    }
    return (
      <div>
        <h1>Oh man!</h1>
        <p>This forum has been deleted.</p>
        <div
          id='basic-button'
          className='ui basic button'
          onClick={() => history.goBack()}
        >
          Go back
        </div>
        <Link
          to='/forums'
          id='basic-secondary-button'
          className='ui basic button'
        >
          Browse forums
        </Link>
      </div>
    );
  }
  return (
    <div className='ui active inverted dimmer'>
      <div className='ui text loader'>Loading...</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    forum: state.forums.forum,
    courses: state.forums.courses,
    forums: state.forums.forums,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  getForum,
  removeComment,
  getCourseTitles,
  closeForum,
  deleteForum,
})(Forum);
