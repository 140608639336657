import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import CourseList from './courses/CourseList';
import PurchaseList from '../widgets/PurchaseList';
import CardLoader from '../widgets/CardLoader';

import history from '../../history';

import { fetchCourses, logoutUser, getUserStats } from '../../actions';

const MyAccount = (props) => {
  useEffect(() => {
    props.fetchCourses(props.user);
    props.getUserStats();

    //eslint-disable-next-line
    window.scrollTo(0, 0);
  }, []);

  const [referralCopied, setReferralCopied] = useState(false);
  const [seeMoreStats, setSeeMoreStats] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [inviteHover, setInviteHover] = useState(false);

  const refLink = useRef(null);
  const inviteExplainRef = useRef(null);

  useEffect(() => {
    let node = inviteExplainRef.current;

    const handleMouseOver = () => {
      setInviteHover(true);
    };

    const handleMouseOut = () => {
      setInviteHover(false);
    };

    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [inviteExplainRef.current]);

  const courses = props.courses;
  const user = props.user;
  const stats = props.stats;

  const onCopyClick = () => {
    refLink.current.type = 'text';
    refLink.current.select();
    document.execCommand('copy');
    refLink.current.type = 'hidden';
    setReferralCopied(true);
  };

  const onSeeMoreClick = (num) => {
    if (initialLoad) {
      setInitialLoad(false);
    }
    if (seeMoreStats.some((el) => el === num)) {
      setSeeMoreStats(seeMoreStats.filter((el) => el !== num));
    } else {
      setSeeMoreStats([...seeMoreStats, num]);
    }
  };

  const logoutUser = () => {
    props.logoutUser();
    history.push('/courses');
  };

  const renderCopyButton = () => {
    if (referralCopied === false) {
      return (
        <>
          <button
            id='secondary-button'
            className='ui right labeled icon button'
            onClick={onCopyClick}
          >
            <i className='copy icon'></i>Invite friends
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            id='secondary-button'
            className='ui right labeled icon button'
            onClick={onCopyClick}
          >
            <i className='check icon'></i>Link copied!
          </button>
        </>
      );
    }
  };

  const renderBasicInfo = () => {
    const date = new Date(user.createdAt);
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    let displayName;

    if (user.name.length > 20) {
      displayName = `${user.name.slice(0, 20)}...`;
    } else {
      displayName = user.name;
    }

    return (
      <div>
        <div className='ui grid'>
          <div className='sixteen wide column'>
            <div className='ui list'>
              <div className='item'>
                <div className='image'>
                  <i
                    className='user circle huge icon'
                    style={{ color: '#6556ff', display: 'contents' }}
                  ></i>
                </div>
                <div
                  className='middle aligned content'
                  style={{ padding: '0px' }}
                >
                  {' '}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '-1rem',
                    }}
                  >
                    <h3
                      className='header'
                      style={{
                        display: 'inline-block',

                        wordBreak: 'break-all',
                      }}
                    >
                      {displayName}
                    </h3>
                    {/* <div style={{ flexGrow: '1' }}></div>
                    <Link
                      to='/account/edit'
                      id='basic-button'
                      className='ui basic small horizontal label'
                      style={{ borderRadius: '50px' }}
                    >
                      <i className='cog icon' style={{ margin: '0' }} />
                    </Link>
                    <div
                      onClick={logoutUser}
                      id='basic-secondary-button'
                      className='ui basic small horizontal label'
                      style={{ borderRadius: '50px', margin: '0px 0 0 0 ' }}
                    >
                      Log out
                    </div> */}
                  </div>
                  <div
                    className='description'
                    style={{ color: '#afafaf', fontSize: '12px' }}
                  >
                    <i className='clock outline icon' />
                    Joined {month} {year}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '8px' }}>
          <Link
            to='/account/edit'
            id='basic-button'
            className='ui basic small horizontal label'
            style={{ borderRadius: '50px' }}
          >
            <i className='cog icon' style={{ margin: '0' }} /> Settings
          </Link>
          <div
            onClick={logoutUser}
            id='basic-secondary-button'
            className='ui basic small horizontal label'
            style={{
              borderRadius: '50px',
              cursor: 'pointer',
            }}
          >
            <i className='sign out icon' style={{ margin: '0' }} /> Log out
          </div>
          {user.isVerified ? (
            ''
          ) : (
            <Link
              to='/verify'
              id='secondary-button'
              className='ui small horizontal label'
              style={{ borderRadius: '50px' }}
            >
              Verify email
            </Link>
          )}
        </div>
      </div>
    );
  };

  let desktopInviteHelperMargin;

  if (parseInt(window.innerWidth) > 1310) {
    desktopInviteHelperMargin = '0';
  } else {
    desktopInviteHelperMargin = '-0.5em';
  }

  const renderInviteButton = () => {
    return (
      <div className='centered' style={{ marginTop: '16px' }}>
        <h3
          style={{
            margin: '0 0 8px 0',
          }}
        >
          Invite a friend, get 20 pyxies!{' '}
          <i
            ref={inviteExplainRef}
            style={{ color: '#6556ff' }}
            className='small question circle outline link icon'
          />
          {inviteHover ? (
            <div
              className='left pointing ui label helper-label desktop-only fade-in'
              style={{
                position: 'absolute',
                marginTop: desktopInviteHelperMargin,
              }}
            >
              Once they sign up, verify their email and complete a course, we'll
              send you 20 pyxies!
            </div>
          ) : (
            ''
          )}
        </h3>
        {inviteHover ? (
          <div
            className='ui label helper-label mobile-only fade-in'
            style={{ marginBottom: '8px' }}
          >
            Once your friend signs up, verifies their email and completes a
            course, we'll send you 20 pyxies from our account!
          </div>
        ) : (
          ''
        )}
        <p>Use the button below to copy your unique referral link</p>
        <div className='ui input'>
          <input
            readOnly
            type='hidden'
            value={`https://app.pyxium.co/signup/${props.user.referralCode}`}
            ref={refLink}
          ></input>
        </div>
        {renderCopyButton()}
      </div>
    );
  };

  const renderAdditionalStats = (stats) => {
    return stats.additional.map((stat, i) => {
      return (
        <div className='item' key={i} style={{ marginBottom: '8px' }}>
          <div
            style={{
              fontWeight: '700',
              color: stats.color,
              fontSize: '20px',
              marginBottom: '4px',
              lineHeight: '1',
            }}
          >
            {stat.value.toLocaleString()}
          </div>
          <div>{stat.stat}</div>
        </div>
      );
    });
  };

  const renderStats = (type) => {
    const totalQuestions = stats.totalQuestions;
    const totalPyxies = stats.totalPyxies;
    const totalPurchasePrice = stats.totalPurchasePrice;
    const totalQuestionsRight = stats.totalQuestionsRight;
    const totalPlatformQuestions = stats.totalPlatformQuestions;
    const totalForums = stats.totalForums;
    const totalComments = stats.totalComments;
    const totalLikes = stats.totalLikes;
    const totalBestComments = stats.totalBestComments;
    const totalLessons = stats.totalLessons;
    const totalTopics = stats.totalTopics;
    const topTopic = stats.topTopic;

    // console.log({
    //   totalQuestions,
    //   totalPyxies,
    //   totalPurchasePrice,
    //   totalQuestionsRight,
    //   totalPlatformQuestions,
    //   totalForums,
    //   totalComments,
    //   totalLikes,
    //   totalBestComments,
    //   totalLessons,
    //   totalTopics,
    //   topTopic,
    // });

    const totalEarned = user.pyxys + totalPurchasePrice;

    const totalPercentageEarned =
      Math.floor((totalEarned * 10000) / totalPyxies) / 100 + '%';

    const totalPercentageRight =
      (Math.floor((totalQuestionsRight * 10000) / totalQuestions) / 100 || 0) +
      '%';
    const totalPlatformQuestionsAnswered =
      Math.floor((totalQuestionsRight * 10000) / totalPlatformQuestions) / 100 +
      '%';

    const lessonCompletionPercentage =
      Math.floor(stats.lessonCompletionPercentage * 10000) / 100 + '%';

    const displayStats = [
      {
        value: user.pyxys.toLocaleString(),
        color: '#fc0',
        singular: 'pyxy',
        plural: 'pyxies',
        seeMore: '',
        additional: [
          { value: totalEarned, stat: 'pyxies earned' },
          { value: totalPurchasePrice, stat: 'pyxies spent' },
          {
            value: totalPercentageEarned,
            stat: 'pyxies earned of total supply',
          },
        ],
      },
      {
        value: totalQuestions,
        color: '#6556ff',
        singular: 'question attempted',
        plural: 'questions attempted',
        seeMore: 'secondary-',
        additional: [
          { value: totalQuestionsRight, stat: 'right answers' },
          {
            value: totalPercentageRight,
            stat: 'right answers (questions attempted)',
          },
          {
            value: totalPlatformQuestionsAnswered,
            stat: 'right answers (all platform questions)',
          },
        ],
      },
      {
        value: totalForums,
        color: '#6556ff',
        singular: 'forum',
        plural: 'forums',
        seeMore: 'secondary-',
        additional: [
          { value: totalComments, stat: 'forum comments' },
          {
            value: totalLikes,
            stat: 'likes',
          },
          {
            value: totalBestComments,
            stat: 'best comments',
          },
        ],
      },
      {
        value: totalLessons,
        color: '#fc0',
        singular: 'lesson',
        plural: 'lessons',
        seeMore: '',
        additional: [
          { value: lessonCompletionPercentage, stat: 'lessons completed' },
          {
            value: totalTopics,
            stat: 'topics',
          },
          {
            value: topTopic,
            stat: 'favourite topic',
          },
        ],
      },
    ];

    if (type === 'desktop') {
      const flip = displayStats[3];
      displayStats[3] = displayStats[2];
      displayStats[2] = flip;
    }

    let columnWidth;

    if (type === 'mobile') {
      columnWidth = 'eight';
    } else {
      columnWidth = 'four';
    }

    if (stats.totalPyxies) {
      return displayStats.map((stat, i) => {
        return (
          <div className={`${columnWidth} wide column`} key={i}>
            <div
              className='ui  card'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '16px',
                padding: '16px 4px',
              }}
            >
              <div className='centered'>
                <div>
                  <h1 style={{ fontSize: '48px', color: stat.color }}>
                    {stat.value}
                  </h1>
                </div>
                <div>{stat.value === 1 ? stat.singular : stat.plural}</div>
                <div
                  id={`basic-${stat.seeMore}button`}
                  className='ui basic mini button'
                  style={{ borderRadius: '50px', margin: '8px', width: 'auto' }}
                  onClick={() => onSeeMoreClick(i)}
                >
                  See {seeMoreStats.some((el) => el === i) ? 'less' : 'more'}
                </div>
                {seeMoreStats.some((el) => el === i) ? (
                  <div
                    className={`ui list ${type}-list-slide-down`}
                    style={{
                      height: `${type === 'mobile' ? '180px' : '152px'}`,
                    }}
                  >
                    {renderAdditionalStats(stat)}
                  </div>
                ) : (
                  ''
                )}
                <div></div>
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <>
        <div>
          <CardLoader width={columnWidth} height='156px' overflow={false} />
        </div>
      </>
    );
  };

  const renderCourses = () => {
    // const grouping = Math.max(
    //   ...courseTitle.structure
    //     .filter((el) => parseInt(el.section) <= parseInt(course.section))
    //     .map((el) => parseInt(el.grouping))
    // );
    if (user.courses && user.courses.length !== 0 && courses.length !== 0) {
      let sorted;

      const filtered = courses.filter((course) =>
        user.courses.some((e) => e.course === course.id)
      );

      filtered.forEach((el) => {
        const userCourse = user.courses.find((e) => e.course === el.id);

        const index = user.courses.indexOf(userCourse);

        el.index = index;
      });

      sorted = filtered.sort((a, b) => a.index - b.index);

      return (
        <>
          <CourseList courses={sorted} />
        </>
      );
    }

    return (
      <div className='centered'>
        <img
          src='https://i.ibb.co/vXjDbTS/No-courses.png'
          style={{ maxWidth: '50%', maxHeight: '300px' }}
          alt='empty_box'
        ></img>
        <p>You're not enrolled in any courses!</p>
        <Link to='/courses' id='secondary-button' className='ui button'>
          Find courses
        </Link>
      </div>
    );
  };

  const renderPurchases = () => {
    if (user.purchases && user.purchases.length !== 0) {
      return <PurchaseList items={user.purchases} />;
    }
    return (
      <div className='centered'>
        <img
          src='https://i.ibb.co/SNmR4Lz/No-products.png">'
          style={{ maxWidth: '50%', maxHeight: '300px' }}
          alt='empty-products'
        />
        <p>You haven't purchased anything!</p>
        <Link to='/marketplace' id='secondary-button' className='ui button'>
          Browse marketplace
        </Link>
      </div>
    );
  };

  if (user) {
    return (
      <div>
        <div>{renderBasicInfo()}</div>
        <div className='ui section divider'></div>
        <div>{renderInviteButton()}</div>
        <div className='ui section divider'></div>
        <div className='mobile-only ui grid'>{renderStats('mobile')}</div>
        <div className='desktop-only ui grid'>{renderStats('desktop')}</div>
        <div className='ui section divider'></div>
        <div className='ui header'>Your courses</div>
        <div>{renderCourses()}</div>
        <div className='ui section divider'></div>
        <div className='ui header'>Your purchases</div>
        <div>{renderPurchases()}</div>
      </div>
    );
  }

  return '';
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    courses: state.courses.courses,
    stats: state.user.stats,
  };
};

export default connect(mapStateToProps, {
  fetchCourses,
  logoutUser,
  getUserStats,
})(MyAccount);
