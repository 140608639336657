import actions from '../actions/types';

export default (
  state = { courses: [], topics: [], term: '', course: {} },
  action
) => {
  switch (action.type) {
    case actions.FETCH_COURSES:
      return {
        ...state,
        courses: action.payload.courses,
      };
    case actions.SET_TOPICS:
      return {
        ...state,
        topics: action.payload.topics,
      };
    case actions.SET_TAG_TERM:
      return {
        ...state,
        term: action.payload.term,
      };
    case actions.FETCH_COURSE:
      return {
        ...state,
        course: action.payload.course,
      };
    case actions.REGISTER_INTEREST:
      return {
        ...state,
        courses: action.payload.courses,
      };
    default:
      return {
        ...state,
      };
  }
};
