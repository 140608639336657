import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import InputQuestion from '../../course/course-parts/InputQuestion';
import CheckboxQuestion from '../../course/course-parts/CheckboxQuestion';
import BackArrow from '../../widgets/BackArrow';
import CanAnswer from '../../widgets/CanAnswer';

import timeAgo from '../../../tools/timeAgo';

import history from '../../../history';

import icon from '../../../../img/primary_icon.png';

import {
  getUserQuestionsToAnswer,
  getUserQuestions,
  setAnswer,
  updateUserRecord,
} from '../../../actions';

const Quiz = (props) => {
  const [questions, setQuestions] = useState([]);
  const [active, setActive] = useState(0);
  const [term, setTerm] = useState([]);
  const [validation, setValidation] = useState(false);
  const [answered, setAnswered] = useState([]);
  const [hideSkip, setHideSkip] = useState(false);
  // const [endQuiz, setEndQuiz] = useState(false);
  const [initialSave, setInitialSave] = useState(true);
  const [newPyxyValue, setNewPyxyValue] = useState(0);
  const [inviteHover, setInviteHover] = useState(false);

  const user = props.user;

  let record = [];

  useEffect(() => {
    props.getUserQuestionsToAnswer();

    // const onBodyClick = (e) => {
    //   if (ref.current && !ref.current.contains(e.target)) {
    //     setEndQuiz(false);
    //   }
    // };

    // document.addEventListener('click', onBodyClick);

    // return () => document.removeEventListener('click', onBodyClick);

    if (props.userQuestions.length === 0) {
      props.getUserQuestions();
    }
  }, []);

  useEffect(() => {
    setTerm([]);
    setValidation(false);
    props.setAnswer(false);
    window.scrollTo(0, 0);
  }, [active]);

  useEffect(() => {
    props.questions.forEach((question, i) => {
      question.number = i + 1;
    });

    setQuestions(props.questions);
  }, [props.questions]);

  useEffect(() => {
    if (answered.length !== 0) {
      const uniqueQuestions = [...new Set(questions)];

      uniqueQuestions.forEach((question) => {
        if (
          answered.some(
            (el) => JSON.stringify(el.id) === JSON.stringify(question._id)
          )
        ) {
          const answer = answered.find(
            (el) => JSON.stringify(el.id) === JSON.stringify(question._id)
          );

          record = [
            ...record,
            {
              id: answer.id,
              correct: answer.correct,
              attempted: true,
              earned: answer.correct,
            },
          ];
        } else {
          record = [
            ...record,
            {
              id: question._id,
              correct: false,
              attempted: false,
              earned: false,
            },
          ];
        }
      });

      if (initialSave) {
        props.updateUserRecord(record, true);
        setInitialSave(false);
      } else {
        props.updateUserRecord(record, false);
      }
    }
  }, [answered]);

  useEffect(() => {
    setNewPyxyValue(newPyxyValue + 1);
    setTimeout(() => {
      setNewPyxyValue(1);
    }, 1000);
  }, [user.pyxys]);

  const displayedQuestion = questions[active];

  const trackAnswered = (id, validate) => {
    let correct = false;

    if (validate) {
      correct = true;
    }
    setAnswered([...answered, { id, correct }]);
  };

  const onQuizSkip = () => {
    setQuestions([...questions, displayedQuestion]);
    setActive(active + 1);
  };

  const onContinue = () => {
    setActive(active + 1);
  };

  const onBackArrowClick = () => {
    history.push('/practice/questions');
  };

  const currentTerm = (value) => {
    if (typeof value === 'string') {
      return setTerm([value]);
    }
    setTerm(value);
  };

  // const onEndQuiz = () => {
  //   if (!endQuiz) {
  //     setEndQuiz(true);
  //   } else {
  //     history.push('/practice/questions');
  //   }
  // };

  const renderQuestion = () => {
    if (displayedQuestion) {
      if (displayedQuestion?.question.type === 'input') {
        return (
          <>
            <InputQuestion
              course={displayedQuestion}
              term={term}
              currentTerm={currentTerm}
              validation={validation}
              onValidation={(val) => setValidation(val)}
              onContinue={onContinue}
              isQuiz={true}
              quizSkip={onQuizSkip}
              hideSkip={hideSkip}
              trackAnswered={(id, validation) => trackAnswered(id, validation)}
            />
          </>
        );
      } else {
        return (
          <>
            <CheckboxQuestion
              course={displayedQuestion}
              term={term}
              currentTerm={currentTerm}
              validation={validation}
              onValidation={(val) => setValidation(val)}
              onContinue={onContinue}
              isQuiz={true}
              quizSkip={onQuizSkip}
              hideSkip={hideSkip}
              trackAnswered={(id, validation) => trackAnswered(id, validation)}
            />
          </>
        );
      }
    } else {
      return (
        <div className='centered'>
          <h1>End of quiz!</h1>
          <Link
            to='/practice/questions'
            id='secondary-button'
            className='ui button'
          >
            End quiz
          </Link>
        </div>
      );
    }
  };

  const renderStreak = (question) => {
    const streakArr = [];

    for (let i = 0; i < parseInt(question.highestStreak); i++) {
      streakArr.push(i);
    }

    return streakArr.map((el, i) => {
      if (parseInt(question.currentStreak) >= i + 1) {
        return (
          <div style={{ display: 'inline-block' }} key={i}>
            <i className={`big fire icon active streak`} />
          </div>
        );
      } else {
        return (
          <div style={{ display: 'inline-block' }} key={i}>
            <i className={`large fire icon streak`} />
          </div>
        );
      }
    });
  };

  const renderQuizInfo = (type) => {
    if (displayedQuestion) {
      const totalQuestions = [...new Set(questions)].length;

      if (totalQuestions - answered.length === 1 && !hideSkip) {
        setHideSkip(true);
      } else if (totalQuestions - answered.length !== 1 && hideSkip) {
        setHideSkip(false);
      }

      const questionRecord = props.userQuestions.find(
        (el) => el.question === displayedQuestion._id
      );

      let streakMargin = 8;

      if (type === 'desktop') {
        streakMargin = 24;
      }

      return (
        <div>
          <div className='centered'>
            <h2>Question {displayedQuestion.number}</h2>
            {displayedQuestion.unitTitle}
          </div>
          <div style={{ marginTop: '24px' }}>
            <div style={{ display: 'inline-block' }}>
              <b>Last seen:</b>{' '}
              {timeAgo.format(Date.parse(questionRecord.lastAnswer))}
            </div>
            <div
              style={{ float: 'right', display: 'flex', alignItems: 'center' }}
            >
              <CanAnswer
                question={questionRecord}
                inviteHover={(val) => setInviteHover(val)}
              />
            </div>
          </div>
          {inviteHover ? (
            <div
              className='ui label helper-label fade-in centered'
              style={{
                marginBottom: '8px',
                position: 'absolute',
                maxWidth: '100%',
                right: '1em',
              }}
            >
              You answered this question too recently to earn on it - though you
              can still answer it to check your understanding!
            </div>
          ) : (
            ''
          )}
          <div style={{ marginTop: '8px' }}>
            <div>
              <b>Streak:</b> {questionRecord.currentStreak}
              <div style={{ float: 'right' }}>
                <b>Highest streak:</b> {questionRecord.highestStreak}
              </div>
            </div>
            <div style={{ marginTop: `${streakMargin}px` }}>
              {renderStreak(questionRecord)}
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  const totalQuestions = [...new Set(questions)].length;

  if (totalQuestions - answered.length === 1 && !hideSkip) {
    setHideSkip(true);
  } else if (totalQuestions - answered.length !== 1 && hideSkip) {
    setHideSkip(false);
  }

  const renderMobileHeader = () => {
    return (
      <div className='ui top fixed fluid secondary menu mobile-header'>
        <div className='item'>
          <BackArrow
            onClick={onBackArrowClick}
            notAbsolute={true}
            type='course'
          />
        </div>
        <div
          style={{
            verticalAlign: 'middle',
            margin: 'auto 0',
          }}
        >
          <span style={{ fontWeight: '700', fontSize: '1.25rem' }}>
            Answered: {answered.length} / {totalQuestions}
          </span>
        </div>
        {/* <div className='item' style={{ padding: '0', fontWeight: '700' }}>
            <div className='header'>{course.unitTitle}</div>
          </div> */}
        <div className='right menu'>
          <div className='item'>
            <img className='logo-small' src={icon} alt='pyxium_logo' />
            <span
              className={`pyxy-header ${newPyxyValue > 1 ? 'active' : ''}`}
              style={{ marginLeft: '4px' }}
            >
              {props.user.pyxys.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  if (questions.length !== 0) {
    return (
      <div style={{ minHeight: 'calc(100vh - 58px)', paddingBottom: '24px' }}>
        <div className='mobile-only live-course' style={{ fontSize: '16px' }}>
          <div className='mobile-only'>{renderMobileHeader()}</div>
          <div style={{ padding: '0', marginTop: '58px' }}>
            <div>{renderQuizInfo('mobile')}</div>
            <div>{renderQuestion()}</div>
          </div>
        </div>
        <div
          className='desktop-only live-course'
          style={{
            fontSize: '20px',
            margin: '-16px auto 0',
            maxWidth: '75%',
            padding: '16px 60px 16px 60px',
            backgroundColor: '#f9f6ff',
            borderRadius: '16px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flexGrow: '1' }}>
              <BackArrow onClick={onBackArrowClick} notAbsolute={true} />
            </div>
            <div
              style={{
                fontWeight: '700',
                fontSize: '1.25rem',
              }}
            >
              Answered: {answered.length} / {totalQuestions}
            </div>
          </div>
          <div>
            <div>{renderQuizInfo('desktop')}</div>
            <div>{renderQuestion()}</div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    questions: state.question.userQuestionsToAnswer,
    userQuestions: state.question.userQuestions,
    record: state.question.record,
    user: state.user.user,
  };
};
export default connect(mapStateToProps, {
  getUserQuestionsToAnswer,
  getUserQuestions,
  setAnswer,
  updateUserRecord,
})(Quiz);
