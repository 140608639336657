import actions from '../actions/types';

export default (
  state = {
    isSignedIn: false,
    user: '',
    isValid: false,
    isEmailResetValid: false,
    isValidEmail: false,
    referrer: '',
    saved: true,
    stats: {},
  },
  action
) => {
  switch (action.type) {
    case actions.CREATE_USER:
      return {
        ...state,
        isSignedIn: true,
        user: action.payload.user,
      };
    case actions.FIND_REFERRER:
      return {
        ...state,
        referrer: action.payload.referrer,
      };
    case actions.VERIFY_EMAIL:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.VERIFY_TEXT:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.LOGIN_USER:
      return {
        ...state,
        isSignedIn: true,
        user: action.payload.user,
      };
    case actions.FETCH_USER:
      return {
        ...state,
        isSignedIn: true,
        user: action.payload,
      };
    case actions.FETCH_INSTRUCTOR:
      return {
        ...state,
        instructor: action.payload.instructor,
        instructorCourses: action.payload.courses,
      };
    case actions.EDIT_USER:
      return {
        ...state,
        isSignedIn: true,
        user: action.payload.user,
      };
    case actions.INSTRUCTOR_REQUEST:
      return {
        ...state,
        isSignedIn: true,
        user: action.payload.user,
      };
    case actions.LOGOUT_USER:
      return {
        ...state,
        isSignedIn: false,
        user: action.payload,
      };
    case actions.DELETE_USER:
      return {
        ...state,
        isSignedIn: false,
        user: action.payload,
      };
    case actions.FETCH_SECTION:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.FETCH_SECTION_NEW:
      return {
        ...state,
        user: action.payload.user,
        saved: action.payload.saved,
      };
    case actions.UNSAVED_PROGRESS:
      return {
        ...state,
        saved: action.payload.saved,
      };
    case actions.COMPLETED_COURSE:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.UPDATE_PROGRESS:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.UPDATE_WRONG_ANSWERS:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.UPDATE_PYXYS:
      return {
        ...state,
        user: action.payload.user,
        answer: action.payload.ans,
      };
    case actions.LIKE_COURSE:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.SPEND_PYXYS:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.VALIDATE_RESET:
      return {
        ...state,
        isValid: action.payload.valid,
      };
    case actions.VALIDATE_EMAIL_RESET:
      return {
        ...state,
        isEmailResetValid: action.payload.validReset,
      };
    case actions.VALIDATE_EMAIL:
      return {
        ...state,
        isEmailValid: action.payload.validEmail,
      };
    case actions.UPDATE_READ_NOTIFICATIONS:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.UPDATE_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.MANAGE_SETTING:
      return {
        ...state,
        user: action.payload.user,
      };
    case actions.GET_USER_STATS:
      return {
        ...state,
        stats: action.payload.stats,
      };
    default:
      return state;
  }
};
