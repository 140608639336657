import React, { useState } from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';

const Examples = (props) => {
  const [showExplanation, setShowExplanation] = useState('');
  const examples = props.course.newExamples.sort((a, b) =>
    a.category.localeCompare(b.category)
  );

  const onExplanation = (example) => {
    if (showExplanation === example.explanation) {
      return setShowExplanation('');
    }
    setShowExplanation(example.explanation);
  };

  const renderExplanation = (example) => {
    if (example.explanation === showExplanation) {
      return (
        <>
          <div
            className='ui message question-explanation slide-down-explanation'
            style={{ margin: '1em' }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(showExplanation),
            }}
          ></div>
        </>
      );
    }
    return <></>;
  };

  const renderIcon = (example) => {
    if (example.correct) {
      return <i className='check brand-green large icon' />;
    } else if (example.category === 'example') {
      return <i className='close brand-red large icon' />;
    } else {
      return <i className='question brand-secondary large icon' />;
    }
  };

  const renderExamples = () => {
    return examples.map((example) => {
      return (
        <div key={examples.indexOf(example)}>
          <div
            className='item'
            style={{ marginBottom: '8px', paddingTop: '0' }}
          >
            {example.image ? (
              <img
                src={example.image}
                className='course-image'
                alt='example_image'
              ></img>
            ) : (
              ''
            )}
            <div className='ui grid' style={{ alignItems: 'center' }}>
              <div className='two wide column'>{renderIcon(example)}</div>
              <div className='fourteen wide column'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(example.example),
                  }}
                  style={{ fontSize: '16px' }}
                ></div>
              </div>
              <div className='ui grid' style={{ width: '100%' }}>
                <div className='two wide column'></div>
                <div className='fourteen wide column'>
                  <div style={{ cursor: 'pointer', display: 'inline-block' }}>
                    <i className='question circle outline icon brand-secondary'></i>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => onExplanation(example)}>Explanation</a>
                  </div>
                </div>
              </div>

              {renderExplanation(example)}
            </div>
          </div>
          <div className='text-divider' style={{ margin: '0 16px 1rem' }}></div>
        </div>
      );
    });
  };

  if (examples && examples.length !== 0) {
    return (
      <div>
        <div className='ui relaxed list'>{renderExamples()}</div>
        <div className='centered'>
          <div
            id='secondary-course-button'
            className='ui button'
            onClick={props.onContinue}
            style={{ width: '100%' }}
          >
            Got it
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Examples);
