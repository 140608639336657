import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import DOMPurify from 'dompurify';

const Explanation = (props) => {
  const [style, setStyle] = useState('');
  const [header, setHeader] = useState('');

  const explanation = props.explanation;
  const correct = props.correct;

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (window.location.pathname.includes('quiz')) {
      setStyle('quiz');
    }
  }, []);

  const correctOptions = [
    'Nice one!',
    'You got it!',
    'Well done!',
    'Good stuff',
    'Correct!',
  ];

  const incorrectOptions = ['Not quite!'];

  if (correct && header === '') {
    setHeader(
      correctOptions[Math.floor(Math.random() * correctOptions.length)]
    );
  } else if (header === '') {
    setHeader(
      incorrectOptions[Math.floor(Math.random() * incorrectOptions.length)]
    );
  }

  const onContinue = () => {
    props.onContinue();
    setHeader('');
  };

  const renderIcon = () => {
    if (correct) {
      return (
        <>
          <i
            className={`big rocket icon`}
            style={{ color: '#fc0', marginBottom: '8px' }}
          />
          <br />
        </>
      );
    } else {
      return '';
    }
  };

  return (
    <div className={`ui message question-explanation ${style}`}>
      <div
        className='centered header'
        style={{ fontSize: '20px', marginBottom: '1em' }}
      >
        {renderIcon()}
        {header}{' '}
      </div>
      <div
        className='course-text'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(explanation),
        }}
      ></div>
      <div className='centered'>
        <div
          id='secondary-course-button'
          className='ui button'
          style={{ margin: '8px 0' }}
          onClick={onContinue}
        >
          Continue
          <i
            className='arrow right icon'
            style={{ margin: '0 0 0 8px', fontSize: '.9em' }}
          />
        </div>
      </div>
      <div ref={ref}></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(Explanation);
