import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../../widgets/Modal';
import UserForm from '../../widgets/UserForm';
import { loginUser, resetLoginErrors } from '../../../actions';
import Error from '../../widgets/Error';

import icon from '../../../../img/primary_icon.png';

import history from '../../../history';

const Login = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [ellipses, setEllipses] = useState('');
  useEffect(() => {
    return () => props.resetLoginErrors();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const changeEllipses = () => {
      if (ellipses === '...') {
        setTimeout(() => {
          setEllipses('');
        }, 500);
      } else {
        setTimeout(() => {
          setEllipses(`${ellipses}.`);
        }, 500);
      }
    };

    changeEllipses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ellipses]);

  const lastPath = props.location.state;

  const onSubmit = (formValues) => {
    props.loginUser(formValues, lastPath);
    setSubmitted(true);
  };

  const error = props.error;
  const errorType = props.errorDetail;

  const renderDisplay = () => {
    if (submitted && !error) {
      return (
        <div>
          <Modal loginLoad={true}>
            <div className='centered row'>
              <img
                className='rotating'
                src={icon}
                alt='logo_rotating'
                style={{ height: '120px' }}
              />
            </div>
            <div className='centered row'>
              <h3>Getting your details{ellipses}</h3>
            </div>
          </Modal>
        </div>
      );
    }

    return (
      <div>
        <Modal title='Login to your Pyxium account!' size='mini'>
          {renderError()}
          <UserForm onSubmit={onSubmit} lastPath={lastPath} button='Login' />

          <div className='ui list'>
            <div className='item'>
              <Link to='/forgot'>Forgotten your password?</Link>
            </div>
            <div className='item'>
              Don't have an account? <Link to='/signup'>Sign up for free</Link>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const renderError = () => {
    if (error) {
      switch (errorType.code) {
        case 400:
          return (
            <div
              className='ui label'
              style={{
                backgroundColor: '#E56B6F',
                color: '#fff',
                borderRadius: '50px',
                marginBottom: '8px',
              }}
            >
              <div className='header'>{errorType.message}</div>
            </div>
          );
        default:
          return (
            <div>
              <Error />
            </div>
          );
      }
    }
    return <></>;
  };

  return <div>{renderDisplay()}</div>;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    error: state.error.error,
    errorDetail: state.error.errorDetail,
  };
};

export default connect(mapStateToProps, { loginUser, resetLoginErrors })(Login);
