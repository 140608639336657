import actions from '../actions/types';

export default (state = { course: {} }, action) => {
  switch (action.type) {
    case actions.FETCH_SECTION:
      return {
        ...state,
        course: action.payload.course,
        answer: [],
        instructor: action.payload.instructor,
      };
    case actions.FETCH_STRUCTURE:
      return {
        ...state,
        courseStructure: action.payload.structure,
      };
    case actions.SET_REVISIT:
      return {
        ...state,
        revisit: action.payload.status,
        revisitSection: action.payload.section,
      };
    default:
      return {
        ...state,
      };
  }
};
