import React from 'react';
import { connect } from 'react-redux';

import Modal from '../../widgets/Modal';
import UserForm from '../../widgets/UserForm';
import { deleteUser } from '../../../actions';

const DeleteAccount = (props) => {
  const onDelete = (formValues) => {
    props.deleteUser(formValues);
  };

  const renderError = () => {
    const error = props.error;
    const errorDetail = props.errorDetail;
    if (error && errorDetail === 'Wrong password') {
      return (
        <div className='ui negative message'>
          <div className='header'>
            Wrong password, unable to delete account. Please try again.
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div>
      <Modal title='Are you sure you want to delete your account?' size='tiny'>
        <p>
          Once you delete your account, all your information will be deleted and
          any pyxies you have will be irreversibly transferred to Pyxium.
        </p>
        {renderError()}
        <UserForm button='Delete account' onSubmit={onDelete} />
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    error: state.error.error,
    errorDetail: state.error.errorDetail,
  };
};

export default connect(mapStateToProps, { deleteUser })(DeleteAccount);
