/*
 ********************** USER ACTIONS ***********************
 */

const CREATE_USER = 'CREATE_USER';
const FIND_REFERRER = 'FIND_REFERRER';
const VERIFY_EMAIL = 'VERIFY_EMAIL';
const EDIT_USER = 'EDIT_USER';
const INSTRUCTOR_REQUEST = 'INSTRUCTOR_REQUEST';
const FETCH_USER = 'FETCH_USER';
const FETCH_INSTRUCTOR = 'FETCH_INSTRUCTOR';
const LOGOUT_USER = 'LOGOUT_USER';
const LOGIN_USER = 'LOGIN_USER';
const DELETE_USER = 'DELETE_USER';
const UPDATE_WRONG_ANSWERS = 'UPDATE_WRONG_ANSWERS';
const UPDATE_PYXYS = 'UPDATE_PYXYS';
const LIKE_COURSE = 'LIKE_COURSE';
const SPEND_PYXYS = 'SPEND_PYXYS';
const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
const VALIDATE_RESET = 'VALIDATE_RESET';
const VERIFY_TEXT = 'VERIFY_TEXT';
const VALIDATE_EMAIL_RESET = 'VALIDATE_EMAIL_RESET';
const UPDATE_UNREAD_NOTIFICATIONS = 'UPDATE_UNREAD_NOTIFICATIONS';
const UPDATE_READ_NOTIFICATIONS = 'UPDATE_READ_NOTIFICATIONS';
const MANAGE_SETTING = 'MANAGE_SETTING';
const GET_USER_STATS = 'GET_USER_STATS';

/*
 ********************** COURSE ACTIONS ***********************
 */

const FETCH_COURSES = 'FETCH_COURSES';
const FETCH_COURSE = 'FETCH_COURSE';
const FETCH_SECTION = 'FETCH_SECTION';
const FETCH_SECTION_NEW = 'FETCH_SECTION_NEW';
const UNSAVED_PROGRESS = 'UNSAVED_PROGRESS';
const FETCH_STRUCTURE = 'FETCH_STRUCTURE';
const COMPLETED_COURSE = 'COMPLETED_COURSE';
const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
const SET_REVISIT = 'SET_REVISIT';
const SET_TOPICS = 'SET_TOPICS';
const SET_TAG_TERM = 'SET_TAG_TERM,';
const REGISTER_INTEREST = 'REGISTER_INTEREST';
// const FETCH_COURSE = 'FETCH_COURSE'; NOW IN COURSE COMPONENT

/*
 ********************** ANSWER ACTIONS ***********************
 */

const SET_ANSWER = 'SET_ANSWER';

/*
 ********************** MARKET ACTIONS ***********************
 */

const GET_CATEGORIES = 'GET_CATEGORIES';
const GET_CATEGORY = 'GET_CATEGORY';
const GET_ITEM = 'GET_ITEM';
const GET_ITEMS = 'GET_ITEMS';

/*
 ********************** FORUM ACTIONS ***********************
 */

const GET_FORUMS = 'GET_FORUMS';
const GET_FORUM = 'GET_FORUM';
const GET_COURSE_TITLES = 'GET_COURSE_TITLES';
const CREATE_FORUM = 'CREATE_FORUM';
const SET_FORUM_SCORE = 'SET_FORUM_SCORE';
const SET_COMMENT_SCORE = 'SET_COMMENT_SCORE';
const ADD_COMMENT = 'ADD_COMMENT';
const ADD_REPLY = 'ADD_REPLY';
const EDIT_FORUM = 'EDIT_FORUM';
const EDIT_COMMENT = 'EDIT_COMMENT';
const REMOVE_COMMENT = 'REMOVE_COMMENT';
const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
const CLOSE_FORUM = 'CLOSE_FORUM';
const DELETE_FORUM = 'DELETE_FORUM';

/*
 ********************** QUESTION ACTIONS ***********************
 */

const GET_QUESTION = 'GET_QUESTION';
const GET_USER_QUESTIONS = 'GET_USER_QUESTIONS';
const GET_USER_QUESTIONS_TO_ANSWER = 'GET_USER_QUESTIONS_TO_ANSWER';
const RESET_USER_QUESTIONS = 'RESET_USER_QUESTIONS';
const UPDATE_USER_RECORD = 'UPDATE_USER_RECORD';

/*
 ********************** ERRORS ***********************
 */

const RESET_ERRORS = 'RESET_ERRORS';

/*
 ********************** USER ERRORS ***********************
 */

const E_SIGNUP_DUP_EMAIL = 'E_SIGNUP_DUP_EMAIL';
const E_LOGIN_DETAILS = 'E_LOGIN_DETAILS';
const E_EDIT_USER = 'E_EDIT_USER';
const E_FORGOT_PASSWORD = 'E_FORGOT_PASSWORD';
const E_DELETE_USER = 'E_DELETE_USER';
const E_NOT_LOGGED_IN = 'E_NOT_LOGGED_IN';
const E_GENERAL = 'E_GENERAL';

export default {
  // User
  CREATE_USER,
  FIND_REFERRER,
  VERIFY_EMAIL,
  EDIT_USER,
  INSTRUCTOR_REQUEST,
  FETCH_USER,
  FETCH_INSTRUCTOR,
  LOGOUT_USER,
  LOGIN_USER,
  DELETE_USER,
  UPDATE_WRONG_ANSWERS,
  UPDATE_PYXYS,
  LIKE_COURSE,
  SPEND_PYXYS,
  VALIDATE_EMAIL,
  VALIDATE_RESET,
  VALIDATE_EMAIL_RESET,
  VERIFY_TEXT,
  UPDATE_READ_NOTIFICATIONS,
  UPDATE_UNREAD_NOTIFICATIONS,
  MANAGE_SETTING,
  GET_USER_STATS,
  // Courses
  FETCH_COURSES,
  FETCH_COURSE,
  FETCH_SECTION,
  FETCH_SECTION_NEW,
  UNSAVED_PROGRESS,
  FETCH_STRUCTURE,
  COMPLETED_COURSE,
  UPDATE_PROGRESS,
  SET_REVISIT,
  SET_ANSWER,
  SET_TOPICS,
  SET_TAG_TERM,
  REGISTER_INTEREST,
  // Marketplace
  GET_CATEGORIES,
  GET_CATEGORY,
  GET_ITEM,
  GET_ITEMS,
  // Forums
  GET_FORUMS,
  GET_FORUM,
  GET_COURSE_TITLES,
  CREATE_FORUM,
  SET_FORUM_SCORE,
  SET_COMMENT_SCORE,
  ADD_COMMENT,
  ADD_REPLY,
  EDIT_FORUM,
  EDIT_COMMENT,
  REMOVE_COMMENT,
  ADD_PARTICIPANT,
  CLOSE_FORUM,
  DELETE_FORUM,
  // Errors
  E_SIGNUP_DUP_EMAIL,
  E_LOGIN_DETAILS,
  E_EDIT_USER,
  E_FORGOT_PASSWORD,
  E_DELETE_USER,
  E_NOT_LOGGED_IN,
  E_GENERAL,
  RESET_ERRORS,
  // Questions
  GET_QUESTION,
  GET_USER_QUESTIONS,
  GET_USER_QUESTIONS_TO_ANSWER,
  RESET_USER_QUESTIONS,
  UPDATE_USER_RECORD,
};
