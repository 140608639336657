import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import ReactGA from 'react-ga';

import { fetchCourses, setTopics } from '../../../actions/index';

import Search from '../../widgets/Search';
import CourseList from './CourseList';
import BackArrow from '../../widgets/BackArrow';

const Courses = (props) => {
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState(false);
  const [searchValues, setSearchValues] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [noInterest, setNoInterest] = useState(false);
  const [searchEmpty, setSearchEmpty] = useState(false);

  const user = props.user;
  const topics = props.topics;

  useEffect(() => {
    // if (!props.initialLoad) {
    //   // ReactGA.pageview(props.location.pathname);
    //   props.onInitialLoad();
    // }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.fetchCourses(user);

    //eslint-disable-next-line
  }, []);

  const courses = props.courses;

  const searchMatch = (values) => {
    if (values[0]) {
      setSearchTerm(true);
      setSearchValues(values);
      setSearchEmpty(false);
    } else {
      setSearchTerm(false);
    }
    window.scrollTo(0, 0);
  };

  const onCategoryClick = (topic) => {
    if (topics.includes(topic)) {
      props.setTopics(topics.filter((category) => category !== topic));
      setHiddenCategories([...hiddenCategories, topic]);
    } else if (hiddenCategories.includes(topic)) {
      props.setTopics([...topics, topic]);
      setHiddenCategories(
        hiddenCategories.filter((category) => category !== topic)
      );
    } else {
      props.setTopics([...topics, topic]);
    }
  };

  const searchMatches = (course) => {
    for (let i = 0; i < course.tags.length; i++) {
      for (let j = 0; j < searchValues.length; j++) {
        if (
          course.tags[i].tag
            .toLowerCase()
            .includes(searchValues[j].toLowerCase())
        ) {
          return course;
        }
      }
    }
  };

  const calculateCourseRelevancy = (course) => {
    for (let i = 0; i < course.tags.length; i++) {
      for (let j = 0; j < searchValues.length; j++) {
        if (
          course.tags[i].tag
            .toLowerCase()
            .includes(searchValues[j].toLowerCase())
        ) {
          course.relevancy++;
        }
      }
    }
  };

  const renderSearchResults = () => {
    // Wait for courses to load

    let searchedCourses = courses.filter(searchMatches);

    searchedCourses.forEach((course) => {
      course.relevancy = 0;
      calculateCourseRelevancy(course);
    });

    let sortedCourses = searchedCourses.sort(
      (a, b) =>
        b.relevancy - a.relevancy ||
        a.comingSoon - b.comingSoon ||
        b.new - a.new
    );

    if (searchedCourses.length === 0 && !noResults) {
      setNoResults(true);
    }

    if (searchedCourses.length !== 0 && noResults) {
      setNoResults(false);
    }

    if (noResults && searchTerm) {
      return (
        <div>
          <BackArrow
            onClick={() => {
              setSearchTerm(false);
              setSearchEmpty(true);
            }}
            top='80px'
            left='auto'
          />
          <p>
            We can't find any courses with that search term! Try amending your
            search or browsing all courses.
          </p>
        </div>
      );
    }
    return (
      <div>
        <BackArrow
          onClick={() => {
            setSearchTerm(false);
            setSearchEmpty(true);
          }}
          top='80px'
          left='auto'
        />
        <CourseList title='Your search results' courses={sortedCourses} />
      </div>
    );
  };

  const renderCategoryCourses = () => {
    let coursesToShow = [];

    if (topics.length !== 0) {
      topics
        .filter((topic) => topic !== 'All')
        .forEach((category) => {
          const catCourses = courses.filter((course) =>
            course.categories.some((cat) => cat === category)
          );
          coursesToShow.push({ topic: category, courses: catCourses });
        });
    } else {
      let topicsToShow = [];

      courses.forEach((course) => {
        course.categories.forEach((cat) => {
          topicsToShow.push(cat);
        });
      });

      const uniqueTopics = [...new Set(topicsToShow)].sort();
      uniqueTopics
        .filter((topic) => topic !== 'All')
        .forEach((category) => {
          const catCourses = courses.filter((course) =>
            course.categories.some((cat) => cat === category)
          );
          coursesToShow.push({ topic: category, courses: catCourses });
        });
    }

    // hiddenCategories.forEach((category) => {
    //   const hiddenCourses = courses.filter((course) =>
    //     course.class.some((cat) => cat.topic === category)
    //   );
    //   coursesToShow.push({ topic: category, courses: hiddenCourses });
    // });

    return coursesToShow.map((category) => {
      return (
        <div
          key={coursesToShow.indexOf(category)}
          className={`slide-down${
            hiddenCategories.includes(category.topic) ? '-hidden' : ''
          }`}
        >
          <CourseList
            title={`${category.topic} courses`}
            courses={category.courses}
          />
          {/* <h3>{category.topic} courses</h3>
          
          <div
            className='ui stretched grid x-overflow no-scrollbar'
            style={{ marginBottom: '24px' }}
          >
            {renderCourseList(category.courses)}
          </div> */}
        </div>
      );
    });
  };

  const renderTopics = (type) => {
    let topicsToShow = [];

    courses.forEach((course) => {
      course.categories.forEach((cat) => {
        topicsToShow.push(cat);
      });
    });

    const uniqueTopics = [...new Set(topicsToShow)].sort();

    let toDisplay;
    if (type === 'desktop') {
      toDisplay = uniqueTopics;
    } else if (type === 'first') {
      toDisplay = uniqueTopics.slice(0, uniqueTopics.length / 2);
    } else {
      toDisplay = uniqueTopics.slice(uniqueTopics.length / 2);
    }

    return toDisplay.map((topic) => {
      return (
        <div
          id={`course-category-button${
            topics.includes(topic) ? '-active' : ''
          }`}
          className='ui button'
          onClick={() => onCategoryClick(topic, uniqueTopics)}
          key={toDisplay.indexOf(topic)}
        >
          {topic}
        </div>
      );
    });
  };

  let sorted;
  if (user) {
    const filtered = courses.filter((course) =>
      user.courses.some((e) => e.course === course.id)
    );

    filtered.forEach((el) => {
      const userCourse = user.courses.find((e) => e.course === el.id);

      const index = user.courses.indexOf(userCourse);

      el.index = index;
    });

    sorted = filtered.sort((a, b) => a.index - b.index);
  }

  let courseSuggestionForumLink = '';

  if (user && user.isDemo) {
    courseSuggestionForumLink = 'QoEbzLQ546'
  } else {
    courseSuggestionForumLink = '6n9iYQLY0J'
  }

  return (
    <div>
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}
      >
        <div style={{ flexGrow: '1' }}>
          <h1>Courses</h1>
        </div>
        {props.isSignedIn ? (
          <div style={{ float: 'right' }}>
            <span role='img' aria-label='waving_hand_emoji'>
              👋
            </span>{' '}
            Welcome back {user.name}!
          </div>
        ) : (
          ''
        )}
      </div>
      <div style={{ marginBottom: '8px' }}>
        <Search
          searchMatch={(values) => searchMatch(values)}
          type='Courses'
          placeholder='Search for a course...'
          empty={searchEmpty}
        />
      </div>

      {searchTerm ? (
        <div style={{ height: '2000px' }}>{renderSearchResults()}</div>
      ) : (
        <div>
          {user && user.courses.length !== 0 ? (
            <div>
              <CourseList title='Your courses' courses={sorted} />
            </div>
          ) : (
            ''
          )}
          <div>
            <CourseList
              title='Popular courses'
              courses={courses
                .sort((a, b) => b.totalStudents - a.totalStudents)
                .slice(0, 10)}
            />
          </div>
          <div>
            <CourseList
              title='New courses'
              courses={courses
                .filter((el) => !el.comingSoon)
                .sort(
                  (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
                )
                .slice(0, 5)}
            />
          </div>

          <div>
            <h2>Browse courses</h2>
            <div className='mobile-only'>
              <div
                className='x-overflow-buttons no-scrollbar'
                style={{ marginBottom: '24px', marginRight: '-1rem' }}
              >
                <div>{renderTopics('first')}</div>
                <div> {renderTopics('second')}</div>
              </div>
            </div>
            <div className='desktop-only'>
              <div style={{ marginBottom: '24px' }}>
                {renderTopics('desktop')}
              </div>
            </div>
          </div>
          {renderCategoryCourses()}
          <div>
            <CourseList
              title='Coming soon'
              courses={courses.filter((course) => course.comingSoon)}
            />
          </div>
        </div>
      )}
      <div className='ui accordion'>
        <div
          className='title text-link'
          onClick={() => setNoInterest(!noInterest)}
        >
          <span role='img' aria-label='thinking_face_emoji'>
            🤔
          </span>{' '}
          Nothing of interest?
        </div>
        <div
          className={`content ${
            noInterest ? 'active animating-accordion' : ''
          }`}
        >
          Let us know what you'd like to learn in our forum, and we'll look into
          it!
          <div style={{ marginTop: '8px' }}>
            <Link
              to={`/discuss/${courseSuggestionForumLink}`}
              id='basic-button'
              className='ui basic  tiny button'
              style={{ borderRadius: '50px' }}
            >
              Take me there
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  // if (props.userNotFound) {
  //   return '';
  // }

  // return (
  //   <div className='ui segment empty'>
  //     <div className='ui active inverted dimmer'>
  //       <div className='ui text loader'>Loading...</div>
  //     </div>
  //   </div>
  // );
};

const mapStateToProps = (state) => {
  return {
    courses: state.courses.courses,
    topics: state.courses.topics,
    user: state.user.user,
    isSignedIn: state.user.isSignedIn,
  };
};

export default connect(mapStateToProps, { fetchCourses, setTopics })(Courses);
