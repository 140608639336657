import actions from '../actions/types';

export default (
  state = { marketCategories: [], category: [], item: {}, items: [] },
  action
) => {
  switch (action.type) {
    case actions.GET_CATEGORIES:
      return {
        ...state,
        marketCategories: action.payload.categories,
        category: action.payload.category,
      };
    case actions.GET_CATEGORY:
      return {
        ...state,
        category: action.payload.category,
      };
    case actions.GET_ITEMS:
      return {
        ...state,
        items: action.payload.items,
      };
    case actions.GET_ITEM:
      return {
        ...state,
        item: action.payload.item,
      };
    default:
      return state;
  }
};
