import api from '../../api/index';
import history from '../history';

/*
 ********************** USER ACTIONS ***********************
 */
import actions from './types';

// This is just key value pair stored in localstorage, not anything private!!
const token_key = 'userSession';

export const createUser = (formValues, referralLink) => async (dispatch) => {
  try {
    const response = await api.post('/user', { ...formValues, referralLink });

    localStorage.setItem(token_key, response.data.token);

    dispatch({ type: actions.CREATE_USER, payload: response.data });

    history.goBack();
  } catch (e) {
    dispatch({ type: actions.E_SIGNUP_DUP_EMAIL, payload: e.response.data });
  }
};

export const createFacebookUser =
  (name, email, referralLink) => async (dispatch) => {
    try {
      const response = await api.post('/user', {
        name,
        email,
        referralLink,
        facebookUser: true,
      });

      localStorage.setItem(token_key, response.data.token);

      dispatch({ type: actions.CREATE_USER, payload: response.data });

      history.push('/courses');
    } catch (e) {
      dispatch({ type: actions.E_SIGNUP_DUP_EMAIL, payload: e.response.data });
    }
  };

export const findReferrer = (referralLink) => async (dispatch) => {
  try {
    const response = await api.post('/user/referrer', { referralLink });
    dispatch({ type: actions.FIND_REFERRER, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const verifyEmail = (token) => async (dispatch) => {
  try {
    const response = await api.post('/user/verified', { token });

    dispatch({ type: actions.VERIFY_EMAIL, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const sendVerificationEmail = (email) => async (dispatch) => {
  try {
    const response = await api.post('/user/verifyemail', { email });

    dispatch({ type: actions.VERIFY_EMAIL, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const sendVerificationText = (number) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await api.post(
      '/user/verifyphone',
      { number },
      { headers }
    );

    dispatch({ type: actions.VERIFY_TEXT, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const validatePhoneNumber = (code) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/user/verified/phone',
      { token: code },
      { headers }
    );

    dispatch({ type: actions.VERIFY_TEXT, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const loginUser = (formValues, lastPath) => async (dispatch) => {
  try {
    const response = await api.post('/user/login', { ...formValues });
    localStorage.setItem(token_key, response.data.token);

    dispatch({ type: actions.LOGIN_USER, payload: response.data });
    if (lastPath) {
      history.push('/courses');
    } else {
      history.goBack();
    }
  } catch (e) {
    dispatch({ type: actions.E_LOGIN_DETAILS, payload: e.response.data });
  }
};

// This will cause an error if it doesn't work but that doesn't matter, it just means user isn't already logged in and will have to log in fresh
export const fetchUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await api.get('/user/me', { headers });

    dispatch({ type: actions.FETCH_USER, payload: response.data });
  } catch (e) {
    localStorage.removeItem(token_key);
  }
};

export const fetchInstructor = (url) => async (dispatch) => {
  try {
    const response = await api.post('/instructor', { url });
    dispatch({ type: actions.FETCH_INSTRUCTOR, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const editUser = (formValues) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.patch(
      '/user/me',
      { ...formValues },
      { headers }
    );

    dispatch({ type: actions.EDIT_USER, payload: response.data });

    history.push('/account/edit/success');
  } catch (e) {
    dispatch({ type: actions.E_EDIT_USER, payload: e.response.data });
  }
};

export const instructorRequest = () => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await api.post('/user/instruct', '', { headers });

    dispatch({ type: actions.INSTRUCTOR_REQUEST, payload: response.data });
    history.push('/');
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    await api.post('/user/logout', '', { headers });

    localStorage.removeItem(token_key);

    dispatch({
      type: actions.LOGOUT_USER,
      payload: '',
    });
  } catch (e) {
    // E_GENERAL just takes them to a generic error page
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const deleteUser = (formValues) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.delete('/user/delete/me', {
      headers,
      data: { ...formValues },
    });

    localStorage.removeItem(token_key);

    dispatch({ type: actions.DELETE_USER, payload: response.data });
    history.push('/');
  } catch (e) {
    dispatch({ type: actions.E_DELETE_USER, payload: e.response.data });
  }
};

export const addIncorrectAnswer = (id, section) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/user/incorrect',
      {
        id,
        section,
      },
      { headers }
    );

    dispatch({ type: actions.UPDATE_WRONG_ANSWERS, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

// export const postPyxys = (id, section, pyxyValue) => async (dispatch) => {
//   try {
//     const token = localStorage.getItem(token_key);
//     const headers = {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token}`,
//     };

//     const response = await api.post(
//       '/user/pyxys',
//       {
//         id,
//         section,
//         pyxyValue,
//       },
//       { headers }
//     );

//     dispatch({ type: actions.UPDATE_PYXYS, payload: response.data });
//   } catch (e) {
//     dispatch({ type: actions.E_GENERAL, payload: e.response.data });
//   }
// };

export const trackAnswer =
  (user, coursePart, correct, answer) => async (dispatch) => {
    try {
      const token = localStorage.getItem(token_key);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const response = await api.post(
        '/questions/interval',
        {
          user,
          coursePart,
          correct,
          answer,
        },
        { headers }
      );

      dispatch({ type: actions.UPDATE_PYXYS, payload: response.data });
    } catch (e) {
      dispatch({ type: actions.E_GENERAL, payload: e.response.data });
    }
  };

export const likeCourse = (lesson, course) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/user/course/like',
      { lesson, course },
      { headers }
    );
    dispatch({ type: actions.LIKE_COURSE, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const spendPyxys = (pyxyValue, id, details) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/user/spend',
      { pyxyValue, id, details },
      { headers }
    );

    dispatch({ type: actions.SPEND_PYXYS, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const forgotPassword = (formValues) => async (dispatch) => {
  try {
    const response = await api.post('/user/forgotpassword', { ...formValues });
    dispatch({
      type: actions.VALIDATE_EMAIL,
      payload: response.data,
    });
  } catch (e) {
    dispatch({ type: actions.E_FORGOT_PASSWORD, payload: e.response.data });
  }
};

export const validatePasswordReset = (token) => async (dispatch) => {
  // This shouldn't get an error as the error is handled client side, probs need fixing
  try {
    const response = await api.post('/user/validatepassword', { token });
    dispatch({ type: actions.VALIDATE_RESET, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const validateResetEmail = (formValues, token) => async (dispatch) => {
  try {
    const response = await api.post('/user/validatereset', {
      ...formValues,
      token,
    });
    dispatch({ type: actions.VALIDATE_EMAIL_RESET, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const updateUnreadNotifications = (notification) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/user/notifications/unread',
      { notification },
      { headers }
    );

    dispatch({
      type: actions.UPDATE_UNREAD_NOTIFICATIONS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};
export const updateReadNotifications = (notification) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/user/notifications/read',
      { notification },
      { headers }
    );

    dispatch({
      type: actions.UPDATE_READ_NOTIFICATIONS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const manageSetting = (user, setting, value) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const response = await api.post(
      '/user/setting',
      { user, setting, value },
      { headers }
    );
    dispatch({ type: actions.MANAGE_SETTING, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const getUserStats = () => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await api.get('/user/stats', { headers });
    dispatch({ type: actions.GET_USER_STATS, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

/*
 ********************** COURSE ACTIONS ***********************
 */

export const fetchCourses = (user) => async (dispatch) => {
  try {
    const response = await api.post('/courses/all', { user });

    dispatch({ type: actions.FETCH_COURSES, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const fetchCourse = (id) => async (dispatch) => {
  try {
    const response = await api.post('/course/get', { id });

    dispatch({ type: actions.FETCH_COURSE, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const fetchSection = (id, section) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post('/section', { id, section }, { headers });
    dispatch({ type: actions.FETCH_SECTION, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};
// DEV
export const fetchDevSection = (id, grouping) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/dev/section',
      { id, grouping },
      { headers }
    );
    dispatch({ type: actions.FETCH_SECTION, payload: response.data });
  } catch (e) {
    if (e.response.status === 401) {
      dispatch({ type: actions.E_NOT_LOGGED_IN, payload: e.response.data });
    } else {
      dispatch({ type: actions.E_GENERAL, payload: e.response.data });
    }
  }
};

export const unsavedProgress = () => async (dispatch) => {
  try {
    dispatch({ type: actions.UNSAVED_PROGRESS, payload: { saved: false } });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const saveUserProgress = (id, grouping, section) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/course/progress',
      { id, grouping, section },
      { headers }
    );
    dispatch({ type: actions.FETCH_SECTION_NEW, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const fetchStructure = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post('/structure', { id }, { headers });

    dispatch({ type: actions.FETCH_STRUCTURE, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const setCourseCompletion = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post('/courses/completed', { id }, { headers });

    dispatch({ type: actions.COMPLETED_COURSE, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const setRevisit = (status, section) => (dispatch) => {
  dispatch({ type: actions.SET_REVISIT, payload: { status, section } });
};

export const setTopics = (topics) => (dispatch) => {
  dispatch({ type: actions.SET_TOPICS, payload: { topics } });
};

export const setTagTerm = (term) => (dispatch) => {
  dispatch({ type: actions.SET_TAG_TERM, payload: { term } });
};

/*
 **********************ANSWER ACTIONS ***********************
 */

export const setAnswer = (answer) => (dispatch) => {
  // Bit rogue but at the time seemed easiset to do it this way #onesourceoftruth
  try {
    dispatch({
      type: actions.SET_ANSWER,
      payload: { answer },
    });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const registerUserInterest =
  (id, name, email, register) => async (dispatch) => {
    try {
      const response = await api.post('/course/interest', {
        id,
        name,
        email,
        register,
      });

      dispatch({ type: actions.REGISTER_INTEREST, payload: response.data });
    } catch (e) {
      dispatch({ type: actions.E_GENERAL, payload: e.response.data });
    }
  };

/*
 ********************** MARKETPLACE ACTIONS ***********************
 */

export const getCategories = (user) => async (dispatch) => {
  try {
    const response = await api.post('/market/categories', { user });

    dispatch({ type: actions.GET_CATEGORIES, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const getCategory = (category, user) => async (dispatch) => {
  try {
    const response = await api.post('/market/category', { category, user });

    dispatch({ type: actions.GET_CATEGORY, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const getItems = () => async (dispatch) => {
  try {
    const response = await api.get('/market/items');

    dispatch({ type: actions.GET_ITEMS, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const getItem = (id, user) => async (dispatch) => {
  try {
    const response = await api.post('/market/item', { id, user });

    dispatch({ type: actions.GET_ITEM, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

/*
 ********************** FORUM ACTIONS ***********************
 */

export const getForums = (topic, user) => async (dispatch) => {
  try {
    const response = await api.post('/forums', { topic, user });

    dispatch({ type: actions.GET_FORUMS, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const getForum = (url) => async (dispatch) => {
  try {
    const response = await api.post('/forum/individual', { url });

    dispatch({ type: actions.GET_FORUM, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const getCourseTitles = () => async (dispatch) => {
  try {
    const response = await api.get('/forum/courses');
    dispatch({ type: actions.GET_COURSE_TITLES, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const createForum =
  (title, creator, creatorName, topic, mainPost) => async (dispatch) => {
    try {
      const response = await api.post('/forum', {
        title,
        creator,
        creatorName,
        topic,
        mainPost,
      });
      dispatch({ type: actions.CREATE_FORUM, payload: response.data });
      history.push(`/discuss/${response.data.forum.url}`);
    } catch (e) {
      dispatch({ type: actions.E_GENERAL, payload: e.response.data });
    }
  };

export const setForumScore = (forum, user, fullUser) => async (dispatch) => {
  try {
    const response = await api.post('/forum/score', {
      forum,
      user,
      fullUser,
    });
    dispatch({ type: actions.SET_FORUM_SCORE, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const setCommentScore = (forum, comment, user) => async (dispatch) => {
  try {
    const response = await api.post('/forum/commentscore', {
      forum,
      comment,
      user,
    });
    dispatch({ type: actions.SET_COMMENT_SCORE, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const addComment = (forum, commenter, text) => async (dispatch) => {
  try {
    const response = await api.post('/forum/comment', {
      forum,
      commenter,
      text,
    });
    dispatch({ type: actions.ADD_COMMENT, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const addReply =
  (forum, commenter, text, replyTo) => async (dispatch) => {
    try {
      const response = await api.post('/forum/reply', {
        forum,
        commenter,
        text,
        replyTo,
      });
      dispatch({ type: actions.ADD_REPLY, payload: response.data });
    } catch (e) {
      dispatch({ type: actions.E_GENERAL, payload: e.response.data });
    }
  };

export const editForumPost = (forum, text) => async (dispatch) => {
  try {
    const response = await api.post('/forum/edit', {
      forum,
      text,
    });
    dispatch({ type: actions.EDIT_FORUM, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const editComment = (forum, comment, text) => async (dispatch) => {
  try {
    const response = await api.post('/forum/comment/edit', {
      forum,
      comment,
      text,
    });
    dispatch({ type: actions.EDIT_COMMENT, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const removeComment =
  (forum, comment, creator, reason, moderator) => async (dispatch) => {
    try {
      const response = await api.post('/forum/delete/comment', {
        forum,
        comment,
        creator,
        reason,
        moderator,
      });
      dispatch({ type: actions.REMOVE_COMMENT, payload: response.data });
    } catch (e) {
      dispatch({ type: actions.E_GENERAL, payload: e.response.data });
    }
  };

export const processParticipant =
  (forum, user, place, type) => async (dispatch) => {
    try {
      const response = await api.post('/forum/participant', {
        forum,
        user,
        place,
        type,
      });
      dispatch({ type: actions.ADD_PARTICIPANT, payload: response.data });
    } catch (e) {
      dispatch({ type: actions.E_GENERAL, payload: e.response.data });
    }
  };

export const closeForum = (forum, val) => async (dispatch) => {
  try {
    const response = await api.post('/forum/close', {
      forum,
      val,
    });
    dispatch({ type: actions.CLOSE_FORUM, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const deleteForum =
  (forum, creator, reason, moderator) => async (dispatch) => {
    try {
      const response = await api.post('/forum/delete', {
        forum,
        creator,
        reason,
        moderator,
      });
      dispatch({ type: actions.DELETE_FORUM, payload: response.data });
      history.push('/forums');
    } catch (e) {
      dispatch({ type: actions.E_GENERAL, payload: e.response.data });
    }
  };

/*
 ********************** QUESTION ACTIONS ***********************
 */

export const getQuestion = (user, question) => async (dispatch) => {
  try {
    const response = await api.post('/question', {
      user,
      question,
    });
    dispatch({ type: actions.GET_QUESTION, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const getUserQuestions = () => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.get('/user/questions', { headers });
    dispatch({ type: actions.GET_USER_QUESTIONS, payload: response.data });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const getUserQuestionsToAnswer = () => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.get('/user/questionstoanswer', { headers });
    dispatch({
      type: actions.GET_USER_QUESTIONS_TO_ANSWER,
      payload: response.data,
    });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

export const resetUserQuestions = () => async (dispatch) => {
  try {
    dispatch({
      type: actions.RESET_USER_QUESTIONS,
      payload: { questions: [] },
    });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e });
  }
};

export const updateUserRecord = (record, initialSave) => async (dispatch) => {
  try {
    const token = localStorage.getItem(token_key);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const response = await api.post(
      '/user/record',
      { record, initialSave },
      { headers }
    );
    dispatch({
      type: actions.UPDATE_USER_RECORD,
      payload: response.data,
    });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

/*
 ********************** ERROR ACTIONS ***********************
 */

// Just to make sure errors don't persist
export const resetErrors = () => async (dispatch) => {
  try {
    dispatch({ type: actions.RESET_ERRORS });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};

// Without history push
export const resetLoginErrors = () => async (dispatch) => {
  try {
    dispatch({ type: actions.RESET_ERRORS });
  } catch (e) {
    dispatch({ type: actions.E_GENERAL, payload: e.response.data });
  }
};
