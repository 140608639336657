import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './userReducer';
import coursesReducer from './coursesReducer';
import courseReducer from './courseReducer';
import answerReducer from './answerReducer';
import marketplaceReducer from './marketplaceReducer';
import forumsReducer from './forumsReducer';
import errorsReducer from './errorsReducer';
import questionReducer from './questionReducer';

export default combineReducers({
  form: formReducer,
  user: userReducer,
  courses: coursesReducer,
  course: courseReducer,
  answer: answerReducer,
  market: marketplaceReducer,
  error: errorsReducer,
  forums: forumsReducer,
  question: questionReducer,
});
