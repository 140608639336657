import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import NavDropdown from './NavDropdown';
import Notifications from '../widgets/Notifications';
import icon from '../../../img/primary_icon.png';

const Header = (props) => {
  const [open, setOpen] = useState(false);
  const [newPyxyValue, setNewPyxyValue] = useState(0);

  useEffect(() => {
    setNewPyxyValue(newPyxyValue + 1);
    setTimeout(() => {
      setNewPyxyValue(1);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.pyxys]);

  const user = props.user;

  const renderCompHeader = () => {
    return (
      <div
        className='desktop-only'
        style={{
          height: '58px',
          position: 'fixed',
          width: '100%',
          zIndex: '110',
        }}
      >
        <div className='desktop-header'>
          <div className='ui container'>
            <div className='ui top fluid secondary menu'>
              <Link className='item' to='/'>
                <img src={icon} width='100' alt='pyxium_logo' />
              </Link>
              {props.isSignedIn ? (
                <>
                  <div className='item'>Your pyxy balance:</div>
                  <div
                    className='item'
                    style={{ paddingLeft: '0px', marginLeft: '0px' }}
                  >
                    <span
                      className={`pyxy-header ${
                        newPyxyValue > 1 ? 'active' : ''
                      }`}
                    >
                      {props.user.pyxys.toLocaleString()}
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}

              <div className='ui right secondary menu'>
                {/* <Link to='/contact' className='item'>
                  Give us feedback
                </Link> */}
                {props.user.isVerified || !props.isSignedIn ? (
                  ''
                ) : (
                  <>
                    <Link to='/verify' className='item' onClick={() => ''}>
                      <b>Verify email</b>
                    </Link>
                  </>
                )}
                <Link to='/courses' className='desktop-header-item item'>
                  Courses
                </Link>
                {user ? (
                  <Link
                    to='/practice/questions'
                    className='desktop-header-item item'
                  >
                    Questions
                  </Link>
                ) : (
                  ''
                )}
                <Link to='/forums' className='desktop-header-item item'>
                  Forums
                </Link>
                <Link to='/marketplace' className='desktop-header-item item'>
                  Marketplace
                </Link>
                {props.isSignedIn ? (
                  <div className='item'>
                    <Notifications isOpen={() => setOpen(false)} />
                  </div>
                ) : (
                  ''
                )}

                <div className='item'>
                  <NavDropdown title='User' onClick={() => ''} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  };

  const onClick = () => {
    setOpen(!open);
  };

  // const renderBars = () => {
  //   if (!open) {
  //     return (
  //       <div onClick={onClick}>
  //         <i className='bars icon' />
  //       </div>
  //     );
  //   }
  //   return (
  //     <div className='ui right visible sidebar vertical menu'>
  //       <div className='item'>
  //         <i className='close icon' onClick={onClick} />
  //       </div>
  //       <Link
  //         to='/contact'
  //         className='item'
  //         onClick={onClick}
  //         style={{ backgroundColor: '#ffae0026' }}
  //       >
  //         Give us feedback
  //       </Link>
  //       {props.user.isVerified || !props.isSignedIn ? (
  //         ''
  //       ) : (
  //         <>
  //           <Link
  //             to='/verify'
  //             className='item'
  //             onClick={() => ''}
  //             style={{ backgroundColor: '#9ec6ae' }}
  //           >
  //             <b>Verify email</b>
  //           </Link>
  //         </>
  //       )}
  //       <Link to='/courses' className='item' onClick={onClick}>
  //         Courses
  //       </Link>
  //       <Link to='/forums' className='item' onClick={onClick}>
  //         Forums
  //       </Link>
  //       <Link to='/marketplace' className='item' onClick={onClick}>
  //         Marketplace
  //       </Link>
  //       <div className='item'>
  //         <Notifications isOpen={() => setOpen(false)} />
  //       </div>
  //       <div className='item'>
  //         <NavDropdown title='User' onClick={onClick} />
  //       </div>
  //     </div>
  //   );
  // };

  const renderMobileHeader = () => {
    return (
      <div className='mobile-only'>
        <div className='ui top fixed fluid secondary menu mobile-header'>
          <Link className='item' to='/'>
            <img className='logo-small' src={icon} alt='pyxium_logo' />
            {props.isSignedIn ? (
              <span
                style={{ color: '#fc0', fontSize: '150%', marginLeft: '16px' }}
              >
                {props.user.pyxys.toLocaleString()}
              </span>
            ) : (
              ''
            )}
          </Link>
          {/* {props.isSignedIn ? (
            <>
              <div className='item'>
                <span style={{ color: '#fc0', fontSize: '150%' }}>
                  {props.user.pyxys.toLocaleString()}
                </span>
              </div>
            </>
          ) : (
            ''
          )} */}

          <div className='right menu'>
            <div className=' item'>
              <Notifications isOpen={() => setOpen(false)} />
            </div>
            <div className=' mobile-footer-item item'>
              <NavDropdown title='User' onClick={onClick} />
            </div>
            {/* <div className='ui item' ref={ref}>
              {renderBars()}
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderCompHeader()}
      {renderMobileHeader()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user.user, isSignedIn: state.user.isSignedIn };
};

export default connect(mapStateToProps)(Header);
