import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import { setTagTerm } from '../../actions/index';

const Search = (props) => {
  const [term, setTerm] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);

  const type = props.type;
  const placeholder = props.placeholder;

  useEffect(() => {
    if (props.term) {
      setTerm(props.term);
      props.searchMatch(
        props.term
          .toLowerCase()
          .split(' ')
          .filter((value) => value.length !== 0)
      );
      props.setTagTerm('');
    }
  }, [term]);

  useEffect(() => {
    if (props.empty && term) {
      setTerm('');
    }
  });

  // useEffect(() => {
  //   const onBodyClick = (e) => {
  //     if (ref.current && ref.current.contains(e.target)) {
  //       return;
  //     }
  //     setSelected(false);
  //   };

  //   document.body.addEventListener('click', onBodyClick);

  //   return () => {
  //     document.body.removeEventListener('click', onBodyClick);
  //   };
  // }, []);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const onInputChange = (e) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const value = e.target.value;

    setTerm(value);

    setTypingTimeout(
      setTimeout(() => {
        if (value) {
          ReactGA.event({
            category: `Searches`,
            action: `${type} search`,
            label: value,
            transport: 'beacon',
          });
        }
      }, 500)
    );

    if (
      value.split(' ')[value.split(' ').length - 1] === '' &&
      value.length > 0
    ) {
      return;
    }
    props.searchMatch(
      value
        .toLowerCase()
        .split(' ')
        .filter((value) => value.length !== 0)
    );
  };

  return (
    <div>
      <form className='ui form' onSubmit={onSubmit}>
        <div className='field search-bar'>
          <div className='ui icon input'>
            <input
              className='primary-input'
              type='text'
              placeholder={placeholder}
              onChange={onInputChange}
              value={term}
            />
            <i className='search icon'></i>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    term: state.courses.term,
  };
};

export default connect(mapStateToProps, { setTagTerm })(Search);
