import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  addComment,
  addReply,
  editComment,
  editForumPost,
} from '../../actions/index';

const Suggestions = (props) => {
  const [term, setTerm] = useState('');
  const [initialEditLoad, setInitialEditLoad] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        props.onCancel();
      }
    };

    document.addEventListener('click', onBodyClick);

    return () => document.removeEventListener('click', onBodyClick);
  }, [props]);

  const isSignedIn = props.isSignedIn;
  const user = props.user;
  const type = props.type;
  const forum = props.forum;
  const topic = props.topic;
  const comment = props.comment;
  const replyTo = props.replyTo;

  if (!initialEditLoad) {
    if (type === 'edit') {
      setTerm(props.text);
      setInitialEditLoad(true);
    } else if (type === 'forum') {
      setTerm(props.text);
      setInitialEditLoad(true);
    }
  }

  const onCommentSubmit = async (e) => {
    e.preventDefault();
    if (type === 'comment') {
      props.addComment(forum, user._id, term);
      props.onCancel();
    } else if (type === 'reply') {
      props.addReply(forum, user._id, term, replyTo);
      props.onCancel();
    } else if (type === 'edit') {
      props.editComment(forum, comment, term);
      props.onCancel();
    } else if (type === 'forum') {
      props.editForumPost(forum, term);
      props.onCancel();
    }
  };

  const onInputChange = (e) => {
    setTerm(e.target.value);
  };

  const renderButtonText = () => {
    if (type === 'comment') {
      return 'Add comment';
    } else if (type === 'reply') {
      return 'Add reply';
    } else if (type === 'edit') {
      return 'Edit comment';
    } else if (type === 'forum') {
      return 'Edit post';
    }
  };

  const renderPlaceholder = () => {
    if (type === 'comment') {
      return 'Add your comment in here';
    } else if (type === 'reply') {
      return 'Add your reply in here';
    } else {
      return 'Edit your comment in here';
    }
  };

  const renderComment = () => {
    return (
      <div>
        <form className='ui form' onSubmit={onCommentSubmit}>
          <div
            className='field'
            style={{ marginTop: '8px', marginLeft: '0px' }}
          >
            <textarea
              label='comment'
              placeholder={renderPlaceholder()}
              onChange={onInputChange}
              value={term}
            />
            <div style={{ marginTop: '4px' }}>
              <button
                onClick={onCommentSubmit}
                id='primary-button'
                className='ui button'
              >
                {renderButtonText()}
              </button>
              <button
                className='ui button'
                style={{ borderRadius: '50px' }}
                onClick={props.onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  if (
    isSignedIn &&
    (user.courses.some((course) => course.course === topic) ||
      topic === 'pyxium')
  ) {
    return <div>{renderComment()}</div>;
  } else if (isSignedIn) {
    return (
      <div ref={ref}>
        <Link
          to={`/course/${topic}`}
          id='basic-button'
          className='ui basic button'
          style={{ marginTop: '8px', borderRadius: '50px' }}
        >
          Enrol to comment
        </Link>
      </div>
    );
  }
  return (
    <div ref={ref}>
      <Link
        to='/login'
        id='basic-button'
        className='ui basic button'
        style={{ marginTop: '8px', borderRadius: '50px' }}
      >
        Login to comment
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.user.isSignedIn,
    user: state.user.user,
    course: state.course.course,
  };
};

export default connect(mapStateToProps, {
  addComment,
  addReply,
  editComment,
  editForumPost,
})(Suggestions);
