import React from 'react';
import { connect } from 'react-redux';

const Sections = (props) => {
  const course = props.course;

  const renderSections = (sections) => {
    return sections.map((section) => {
      return (
        <div className='column' key={section._id}>
          <h3>{section.title}</h3>
          <p>{section.content}</p>
        </div>
      );
    });
  };

  if (course.structure && course.structure.length !== 0) {
    let columns = '';
    if (course.structure.length === 1) {
      columns = 'one';
    } else if (course.structure.length === 2) {
      columns = 'two';
    } else {
      columns = 'three';
    }

    return (
      <div className={`ui internally celled ${columns} column stackable grid`}>
        {renderSections(course.structure)}
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Sections);
