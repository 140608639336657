import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

import history from '../../history';

import { fetchSection, setAnswer, setRevisit } from '../../actions';
import Parts from './index';
// import Content from "./course-parts/Content";

const Course = (props) => {
  const [count, setCount] = useState(1);
  const [term, setTerm] = useState([]);
  const [validation, setValidation] = useState(false);

  const section = props.section;

  useEffect(() => {
    setCount(1);
    setTerm([]);
    setValidation(false);
    props.setAnswer();
  }, [section]);

  const user = props.user;
  const course = props.course.find((part) => part.section === props.section);

  const nextSection = course ? parseInt(course.section) + 1 : 0;

  let highestSection = props.section;
  if (user.courses.find((e) => e.course === course.id)) {
    highestSection = user.courses.find(
      (e) => e.course === course.id
    ).highestSection;
  }

  const currentContent = (value) => {
    setCount(value);
  };

  const currentTerm = (value) => {
    if (typeof value === 'string') {
      return setTerm([value]);
    }
    setTerm(value);
  };

  /********** BUTTONS RENDERS **********/

  const onContinue = () => {
    history.push(`/course/live/${course.id}/${course.grouping}/${nextSection}`);
  };

  const renderShareButtons = () => {
    const shareUrl = `https://app.pyxium.co/course/${course.id}`;
    const title = `Check out this course '${course.unitTitle}' I'm doing on Pyxium!`;
    return (
      <>
        <div style={{ textAlign: 'left', margin: '4px' }}>
          <h4>Share course</h4>
        </div>
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon
            size={32}
            borderRadius={16}
            bgStyle={{ fill: '#ffcc00' }}
            iconFillColor={'rgb(99 99 99)'}
            className='basic-side-margin'
          />
        </FacebookShareButton>{' '}
        <TwitterShareButton url={shareUrl} title={title} via={'pyxium'}>
          <TwitterIcon
            size={32}
            round={false}
            borderRadius={16}
            bgStyle={{ fill: '#ffcc00' }}
            iconFillColor={'rgb(99 99 99)'}
            className='basic-side-margin'
          />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={title} summary={title}>
          <LinkedinIcon
            size={32}
            round={false}
            borderRadius={16}
            bgStyle={{ fill: '#ffcc00' }}
            iconFillColor={'rgb(99 99 99)'}
            className='basic-side-margin'
          />
        </LinkedinShareButton>
        <RedditShareButton url={shareUrl} title={title}>
          <RedditIcon
            size={32}
            round={false}
            borderRadius={16}
            bgStyle={{ fill: '#ffcc00' }}
            iconFillColor={'rgb(99 99 99)'}
            className='basic-side-margin'
          />
        </RedditShareButton>
        <WhatsappShareButton url={shareUrl} title={title}>
          <WhatsappIcon
            size={32}
            round={false}
            borderRadius={16}
            bgStyle={{ fill: '#ffcc00' }}
            iconFillColor={'rgb(99 99 99)'}
            className='basic-side-margin'
          />
        </WhatsappShareButton>
        <EmailShareButton
          url={shareUrl}
          subject='I just completed a course on Pyxium'
          body={title}
        >
          <EmailIcon
            size={32}
            round={false}
            borderRadius={16}
            bgStyle={{ fill: '#ffcc00' }}
            iconFillColor={'rgb(99 99 99)'}
            className='basic-side-margin'
          />
        </EmailShareButton>
      </>
    );
  };

  /********** SECTION RENDERS **********/

  const renderSectionIntro = () => {
    if (course.subtitle) {
      return (
        <>
          <Parts.SectionIntro course={course} onContinue={onContinue} />
        </>
      );
    }
    return <></>;
  };

  const renderWarning = () => {
    if (course.warnings) {
      if (course.warnings.length !== 0) {
        return (
          <>
            <Parts.Warning course={course} onContinue={onContinue} />
          </>
        );
      }
    }
    return <></>;
  };

  const renderDescription = () => {
    if (course.description) {
      return (
        <>
          <Parts.Description course={course} />
        </>
      );
    }
    return <></>;
  };

  const renderExamples = () => {
    if (course.newExamples) {
      return (
        <>
          <Parts.Examples course={course} onContinue={onContinue} />
        </>
      );
    }
    return <></>;
  };

  const renderCourseQuestion = () => {
    if (course.question) {
      if (course.question && course.question.type === 'input') {
        return (
          <>
            <Parts.InputQuestion
              term={term}
              currentTerm={currentTerm}
              course={course}
              validation={validation}
              onValidation={(val) => setValidation(val)}
              onContinue={onContinue}
              section={section}
            />
          </>
        );
      } else if (course.question && course.question.type === 'checkbox') {
        return (
          <>
            <Parts.CheckboxQuestion
              term={term}
              currentTerm={currentTerm}
              course={course}
              validation={validation}
              onValidation={(val) => setValidation(val)}
              onContinue={onContinue}
              section={section}
            />
          </>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };

  const renderSubSection = () => {
    if (course.newContent) {
      if (course.newContent.length !== 0) {
        return (
          <>
            <Parts.SubSection
              currentContent={currentContent}
              count={count}
              list={course.newContent}
              highestSection={highestSection}
              course={course}
            />
          </>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };

  const renderDefinitions = () => {
    if (course.definitions && course.definitions.length !== 0) {
      return (
        <>
          <Parts.Definitions
            currentContent={currentContent}
            count={count}
            list={course.definitions}
            course={course}
            onContinue={onContinue}
          />
        </>
      );
    }
    return <></>;
  };

  const renderSections = () => {
    if (course.structure && course.section !== '0') {
      return (
        <>
          <Parts.Sections course={course} />
        </>
      );
    }
    return <></>;
  };

  const renderEnd = () => {
    if (course.end.content) {
      return (
        <>
          <Parts.End course={course} />
        </>
      );
    }
    return <></>;
  };

  /********** INSTRUCTOR RENDER **********/
  // const renderMobileInstructor = () => {
  //   return (
  //     <div className='ui grid'>
  //       <div className='mobile tablet only sixteen wide column'>
  //         <h2>Created by</h2>
  //         <img
  //           src={props.instructor.image}
  //           alt='instructor_pic'
  //           className='ui tiny circular image'
  //         />
  //         <div className='ui items'>
  //           <div className='item' style={{ marginTop: '0' }}>
  //             <div className='middle aligned content'>
  //               <div className='header'>{props.instructor.instructorName}</div>
  //               <div className='meta'>{props.instructor.instructorTagline}</div>
  //               <div>
  //                 <Link to={`/instructor/${props.instructor.instructorUrl}`}>
  //                   About instructor
  //                 </Link>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderCourse = () => {
    return (
      <>
        <Parts.Title course={course} />
        {renderSectionIntro()}
        {renderWarning()}
        {renderDescription()}
        {renderExamples()}
        {renderCourseQuestion()}
        {renderSubSection()}
        {renderDefinitions()}
        {renderSections()}
        {renderEnd()}
      </>
    );
  };

  // If you can figure out mobile add render mobile ==> issues with trying to update an unmounted component

  if (course) {
    return (
      <div style={{ paddingBottom: '24px', flexGrow: '1' }}>
        {renderCourse()}
        <div style={{ textAlign: 'right' }}>
          <div style={{ marginTop: '24px', display: 'inline-block' }}>
            {renderShareButtons()}
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    course: state.course.course,
    user: state.user.user,
    answer: state.answer.answer,
    instructor: state.course.instructor,
    revisit: state.course.revisit,
    revisitSection: state.course.revisitSection,
    courseStructure: state.course.courseStructure,
  };
};

export default connect(mapStateToProps, {
  fetchSection,
  setAnswer,
  setRevisit,
})(Course);
