import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

import Like from '../../widgets/Like';
import Follow from '../../widgets/Follow';
import Pagination from '../../widgets/Pagination';

import { getForums, getCourseTitles } from '../../../actions/index';
import timeAgo from '../../../tools/timeAgo';

const AllForums = (props) => {
  const [list, setList] = useState('all');
  const [sortingType, setSortingType] = useState('newest');
  const [activePage, setActivePage] = useState(1);
  const [totalForums, setTotalForums] = useState(0);
  const [displayedForums, setDisplayedForums] = useState(0);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [selectedDropdownTopics, setSelectedDropdownTopics] = useState([]);
  const [dropdownLoaded, setDropdownLoaded] = useState(false);
  const [notLoggedIn, setNotLoggedIn] = useState({
    notLoggedIn: false,
    part: '',
  });
  const [notEnrolled, setNotEnrolled] = useState({
    notEnrolled: false,
    part: '',
  });

  const filteredTopic = props.topic || 'All';

  const ref = useRef();
  const loginRef = useRef([]);

  const user = props.user;

  useEffect(() => {
    props.getForums(filteredTopic, user);
    props.getCourseTitles();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const onBodyClick = (e) => {
      loginRef.current = loginRef.current.slice(0, displayedForums);

      if (ref.current && ref.current.contains(e.target)) {
        setNotLoggedIn({
          notLoggedIn: false,
          part: '',
        });
      } else if (
        loginRef.current.length !== 0 &&
        loginRef.current.filter((el) => el).some((el) => el.contains(e.target))
      ) {
        setDropdownActive(false);
      } else {
        setNotLoggedIn({
          notLoggedIn: false,
          part: '',
        });

        setDropdownActive(false);
      }
    };

    document.addEventListener('click', onBodyClick);

    return () => document.removeEventListener('click', onBodyClick);
  }, [displayedForums]);

  const forums = props.forums;
  const courses = props.courses;
  const isSignedIn = props.isSignedIn;

  const onListClick = (list) => {
    setList(list);
  };

  const renderSorting = () => {
    return (
      <div className='ui text menu'>
        <div className='header item'>Sort By</div>
        <p
          style={{ cursor: 'pointer' }}
          className={`${sortingType === 'newest' ? 'active' : ''} item`}
          onClick={() => {
            setSortingType('newest');
          }}
        >
          Newest
        </p>
        <p
          style={{ cursor: 'pointer' }}
          className={`${sortingType === 'rating' ? 'active' : ''} item`}
          onClick={() => {
            setSortingType('rating');
          }}
        >
          Highest Rated
        </p>
        <p
          style={{ cursor: 'pointer' }}
          className={`${sortingType === 'comments' ? 'active' : ''} item`}
          onClick={() => {
            setSortingType('comments');
          }}
        >
          Most Replies
        </p>
      </div>
    );
  };

  const renderForums = () => {
    if (forums && courses) {
      let forumsToDisplayByList;
      if (user) {
        list === 'all'
          ? (forumsToDisplayByList = forums)
          : (forumsToDisplayByList = forums.filter((forum) =>
              forum.participants.includes(user._id)
            ));
      } else {
        forumsToDisplayByList = forums;
      }

      if (totalForums !== forumsToDisplayByList.length) {
        setTotalForums(forumsToDisplayByList.length);
      }

      if (selectedDropdownTopics.length !== 0) {
        forumsToDisplayByList = forumsToDisplayByList.filter((forum) =>
          selectedDropdownTopics.some((e) => e.id === forum.topic)
        );
      }

      if (displayedForums !== forumsToDisplayByList.length) {
        setDisplayedForums(forumsToDisplayByList.length);
      }

      if (sortingType === 'newest') {
        forumsToDisplayByList.sort(
          (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        );
      } else if (sortingType === 'rating') {
        forumsToDisplayByList.sort((a, b) => b.score - a.score);
      } else {
        forumsToDisplayByList.sort(
          (a, b) =>
            b.comments.filter((comment) => !comment.deleted).length -
            a.comments.filter((comment) => !comment.deleted).length
        );
      }

      if (activePage * 10 > forumsToDisplayByList.length) {
        forumsToDisplayByList = forumsToDisplayByList.slice(
          activePage * 10 - 10
        );
      } else {
        forumsToDisplayByList = forumsToDisplayByList.slice(
          activePage * 10 - 10,
          activePage * 10
        );
      }

      if (list === 'following' && forumsToDisplayByList.length === 0) {
        return (
          <div>
            <h2>Oh man!</h2>
            <p>You're not following any forums.</p>
            <div
              className='ui basic button'
              id='basic-button'
              onClick={() => setList('all')}
            >
              Explore all forums
            </div>
          </div>
        );
      }

      return forumsToDisplayByList.map((forum, i) => {
        let topic;
        if (courses.find((course) => course.id === forum.topic)) {
          topic = courses.find((course) => course.id === forum.topic).title;
        } else {
          topic = 'Pyxium';
        }
        return (
          <div className='item' key={i}>
            <div className='content'>
              <div className='desktop-only'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ maxWidth: '80%', wordBreak: 'break-all' }}>
                    <Link
                      to={`/discuss/${forum.url}`}
                      style={{ color: 'rgba(0,0,0,.87)' }}
                      className='header'
                    >
                      <h2>{DOMPurify.sanitize(forum.title)}</h2>
                    </Link>
                  </div>

                  <div style={{ marginLeft: '24px' }}>
                    <Follow place='forums' forum={forum} size='' />
                  </div>
                </div>
              </div>
              <div className='mobile-only'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      maxWidth: '80%',
                      wordBreak: 'break-all',
                      flexGrow: '1',
                    }}
                  >
                    <Link
                      to={`/discuss/${forum.url}`}
                      style={{ color: 'rgba(0,0,0,.87)' }}
                      className='header'
                    >
                      <h3>{DOMPurify.sanitize(forum.title)}</h3>
                    </Link>
                  </div>

                  <div style={{ marginLeft: '24px' }}>
                    <Follow place='forums' forum={forum} />
                  </div>
                </div>
              </div>
              <div className='meta'>
                by {forum.creatorName} in{' '}
                <span className='description'>
                  <Link to={`/forum/${forum.topic}`}>{topic}</Link>
                </span>
              </div>
              <div className='meta' ref={(el) => (loginRef.current[i] = el)}>
                {forum.score}{' '}
                <Like
                  item={forum}
                  type='forum'
                  notLoggedIn={() => {
                    setNotLoggedIn({ notLoggedIn: true, part: forum._id });
                  }}
                  notEnrolled={() => {
                    setNotEnrolled({ notEnrolled: true, part: forum._id });
                  }}
                />{' '}
                {forum.participants.length}{' '}
                <i className='user icon' style={{ color: '#c6c6c6' }}></i>
                {
                  forum.comments.filter((comment) => !comment.deleted).length
                }{' '}
                <i
                  className='comment outline icon'
                  style={{ color: '#c6c6c6' }}
                ></i>
              </div>
              {notLoggedIn.notLoggedIn && notLoggedIn.part === forum._id ? (
                <>
                  <Link style={{ color: '#fc0' }} to='/login'>
                    <b>Log in</b>
                  </Link>{' '}
                  to vote
                </>
              ) : (
                ''
              )}
              {notEnrolled.notEnrolled && notEnrolled.part === forum._id ? (
                <>
                  <Link style={{ color: '#fc0' }} to={`/course/${forum.topic}`}>
                    <b>Enrol</b>
                  </Link>{' '}
                  to vote
                </>
              ) : (
                ''
              )}
              <div className='meta'>
                {timeAgo.format(Date.parse(forum.createdAt))}
              </div>

              {/* <div className='extra'>
                <div
                  className='ui label'
                  style={{
                    backgroundColor: topic === 'Pyxium' ? '#FFCC00' : '#6556ff',
                    color: '#FFFFFF',
                  }}
                >
                  {topic}
                </div>
              </div> */}
            </div>
          </div>
        );
      });
    }
  };

  const onDropdownClick = () => {
    if (!dropdownActive && !dropdownLoaded) {
      setDropdownActive(true);
      setDropdownLoaded(true);
    } else if (!dropdownActive) {
      setDropdownActive(true);
    }
  };

  const onRemoveTopicClick = (topic) => {
    setSelectedDropdownTopics(
      selectedDropdownTopics.filter((e) => e.id !== topic)
    );
  };

  const renderSelectedTopics = () => {
    if (selectedDropdownTopics.length !== 0) {
      return selectedDropdownTopics.map((topic) => {
        return (
          <span
            className='ui label transition visible animating scale in'
            style={{ backgroundColor: '#ffe17c', color: '#ffffff' }}
            key={selectedDropdownTopics.indexOf(topic)}
          >
            {topic.title}
            <i
              className='delete icon'
              style={{ float: 'right' }}
              onClick={() => onRemoveTopicClick(topic.id)}
            ></i>
          </span>
        );
      });
    }
    return '';
  };

  const renderTopicMenu = () => {
    if (courses) {
      const sortedByTopic = courses.filter(
        (topic) => !selectedDropdownTopics.some((e) => e.id === topic.id)
      );

      return sortedByTopic.map((course) => {
        return (
          <div
            className='item'
            key={sortedByTopic.indexOf(course)}
            onClick={() =>
              setSelectedDropdownTopics(selectedDropdownTopics.concat(course))
            }
          >
            {course.title}
          </div>
        );
      });
    }
  };

  const dropdownAnimation = () => {
    if (dropdownActive) {
      return 'animating slide down in visible transition primary-input-large';
    } else if (dropdownLoaded) {
      return 'animating slide down out visible transition primary-input-large';
    } else {
      return '';
    }
  };

  const renderTopic = () => {
    if (filteredTopic === 'All') {
      return (
        <div
          className={`ui dropdown selection multiple ${
            dropdownActive || selectedDropdownTopics.length !== 0
              ? 'visible active primary-input-large'
              : 'primary-input' 
          }`}
          onClick={onDropdownClick}
          style={{ marginTop: '8px' }}
          ref={ref}
        >
          <input type='hidden' name='topic' />
          <i className='dropdown icon'></i>
          {selectedDropdownTopics.length !== 0 ? (
            renderSelectedTopics()
          ) : (
            <div className='default text'>Select topic areas...</div>
          )}

          <div className={`menu ${dropdownAnimation()}`}>
            {renderTopicMenu()}
          </div>
        </div>
      );
    }
    return '';
  };

  const renderList = () => {
    return (
      <div>
        <div className='ui secondary menu'>
          <p
            style={{
              cursor: 'pointer',
              backgroundColor: `${list === 'all' ? '#ffe17c' : ''}`,
              borderRadius: '50px',
            }}
            className='item'
            onClick={() => onListClick('all')}
          >
            All
          </p>
          <p
            style={{
              cursor: 'pointer',
              backgroundColor: `${list === 'following' ? '#ffe17c' : ''}`,
              borderRadius: '50px',
            }}
            className='item'
            onClick={() => onListClick('following')}
          >
            Following
          </p>
          <div className='right aligned item'>
            <Link to='/create' id='secondary-button' className='ui button'>
              Create discussion
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    let header;
    if (filteredTopic && courses) {
      if (filteredTopic === 'All') {
        header = 'Forums';
      } else {
        if (courses.find((course) => course.id === filteredTopic)) {
          header = `${
            courses.find((course) => course.id === filteredTopic).title
          }`;
        } else {
          header = 'Pyxium';
        }
      }
    }

    if (header === 'Forums') {
      return (
        <>
          <h1>{header}</h1>
        </>
      );
    }

    let title;
    if (header) {
      title = `${header.substring(0, 15)}${header.length > 15 ? '...' : ''}`;
    }

    return (
      <>
        <div className='ui breadcrumb'>
          <Link to='/forums' className='section'>
            Forums
          </Link>
          <i className='right angle icon divider'></i>
          <div className='active section'>{title}</div>
        </div>
        <div style={{ marginBottom: '24px' }}>
          <h1>{header} forums</h1>
        </div>
      </>
    );
  };

  if (props.userNotFound) {
    return '';
  }

  if (forums.length !== 0) {
    return (
      <div className='course-text'>
        <div style={{ marginBottom: '24px ' }}>{renderTitle()}</div>
        {isSignedIn ? renderList() : ''}
        {renderTopic()}
        {renderSorting()}
        <div className='ui divided items'>{renderForums()}</div>
        <div className='ui grid'>
          <div className='computer only sixteen wide column'>
            <div className='column'>
              {displayedForums === 0 ? (
                ''
              ) : (
                <Pagination
                  items={displayedForums}
                  perPage={10}
                  setActive={(page) => setActivePage(page)}
                  type='computer'
                />
              )}
            </div>
          </div>
          <div className='mobile tablet only sixteen wide column'>
            <div className='column'>
              {displayedForums === 0 ? (
                ''
              ) : (
                <Pagination
                  items={displayedForums}
                  perPage={10}
                  setActive={(page) => setActivePage(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='ui active inverted dimmer'>
      <div className='ui text loader'>Loading...</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    forums: state.forums.forums,
    courses: state.forums.courses,
    user: state.user.user,
    isSignedIn: state.user.isSignedIn,
  };
};

export default connect(mapStateToProps, {
  getForums,
  getCourseTitles,
})(AllForums);
