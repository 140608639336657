import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';

const CanAnswer = (props) => {
  const question = props.question;

  const ref = useRef();

  useEffect(() => {
    let node = ref.current;

    const handleMouseOver = () => {
      props.inviteHover(true);
    };

    const handleMouseOut = () => {
      props.inviteHover(false);
    };

    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);

  let canAnswer;
  let reason;

  const date = new Date();

  const questionDate = new Date(question.nextViewing);

  if (!question) {
    canAnswer = true;
    reason = 'Not yet answered';
  } else if (date.getTime() - questionDate.getTime() > 0) {
    canAnswer = true;
    reason = 'Time has passed';
  } else {
    canAnswer = false;
    reason = 'Too soon';
  }

  if (canAnswer) {
    return (
      <>
        <div
          className='ui empty circular label'
          style={{
            backgroundColor: '#5CD664',
            marginRight: '8px',
            boxShadow: '0 0 8px #5CD664',
          }}
        ></div>{' '}
        Earning
      </>
    );
  } else {
    return (
      <>
        <div
          className='ui empty circular label'
          style={{
            backgroundColor: '#ff5252',
            marginRight: '8px',
            boxShadow: '0 0 8px #ff5252',
            position: 'relative',
          }}
        ></div>{' '}
        Not earning{' '}
        <div style={{ marginLeft: '4px', color: '#6556ff' }}>
          <i className='question circle link icon outline' ref={ref} />
        </div>
      </>
    );
  }
};

export default connect()(CanAnswer);
