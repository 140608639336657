import React, { useRef } from 'react';
import { connect } from 'react-redux';

import icon from '../../../../img/primary_icon.png';

const SectionIntro = (props) => {
  const course = props.course;

  const ref = useRef();

  const calculateHeight = (device) => {
    if (device === 'mobile') {
      return ref.current.offsetWidth;
    } else {
      return ref.current.offsetWidth / 2;
    }
  };

  const renderAnimation = (device) => {
    if (ref.current) {
      return (
        <div
          style={{
            height: `${calculateHeight(device)}px`,
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {device === 'mobile' ? (
              <div className={`flow-right`}>
                <img src={icon} alt='pyxium_icon' />
              </div>
            ) : (
              <div>
                <img
                  className={`cascade-fade one`}
                  src={icon}
                  alt='pyxium_icon'
                />
              </div>
            )}
          </div>
        </div>
      );
    }
    return <></>;
  };

  if (course.subtitle) {
    return (
      <div ref={ref}>
        <div className='mobile-only'>{renderAnimation('mobile')}</div>
        <div className='desktop-only'>{renderAnimation('desktop')}</div>

        <p className='centered large'>{course.subtitle}</p>
        <div
          id='secondary-button'
          className='ui huge button'
          style={{ width: '100%' }}
          onClick={props.onContinue}
        >
          Start
        </div>
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(SectionIntro);
