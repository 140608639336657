import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getUserQuestions, resetUserQuestions } from '../../../actions';

const Questions = (props) => {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [ellipses, setEllipses] = useState('');
  const [message, setMessage] = useState('Fetching your answers');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        if (ellipses !== '...') {
          setEllipses(`${ellipses}.`);
        } else {
          setEllipses('');
        }
      }, 300);
    }
  }, [ellipses]);

  useEffect(() => {
    if (!loaded) {
      const messages = [
        'Fetching your answers',
        'Validating previous responses',
        'Determining memory intervals',
        'Taking break to learn about social justice',
        'Compiling quiz',
      ];
      setTimeout(() => {
        if (message !== 'Compiling quiz') {
          const current = messages.indexOf(message);
          setMessage(messages[current + 1]);
        }
      }, 2000);
    }
  }, [message]);

  const awaitQuestions = async () => {
    if (!loaded) {
      setTimeout(async () => {
        await props.getUserQuestions();
        setLoaded(true);
      }, 2000);
    }
  };

  awaitQuestions();

  const user = props.user;
  const questions = props.questions;

  const record = questions.find((question) => question.userRecord);

  const now = new Date();

  const questionsToAnswer = questions.filter(
    (question) => Date.parse(question.nextViewing) - now < 0
  );

  const renderEntry = () => {
    if (record) {
      const entries = record.record;

      return entries.map((entry, i) => {
        const date = new Date(Date.parse(entry.date)).toLocaleDateString();
        const totalQuestions = entry.questions.length;
        // Calculate answers attempted
        let answers = 0;
        entry.questions.forEach((question) => {
          if (question.attempted) {
            answers++;
          }
        });
        const totalAnswers = `${answers}/${totalQuestions}`;

        // Calculate right answers
        let correct = 0;
        entry.questions.forEach((question) => {
          if (question.correct) {
            correct++;
          }
        });
        const percentageCalc =
          parseInt(totalAnswers) > 0
            ? Math.floor((correct * 10000) / parseInt(totalAnswers)) / 100
            : 0;
        const percentageRight = percentageCalc + '%';

        // Calculate pyxies earned
        const pyxiesEarned = correct;
        return (
          <div
            className={`row ${
              i % 2 === 0 ? 'primary-background' : 'secondary-background'
            }`}
            style={{
              alignItems: 'center',
              borderRadius: '8px',
              fontSize: '14px',
            }}
            key={i}
          >
            <div className='column' style={{ padding: '0' }}>
              <p className='centered'>{date}</p>
            </div>
            <div className='column'>
              <h3 className='centered'>{totalAnswers}</h3>
            </div>
            <div className='column'>
              <h3 className='centered'>{percentageRight}</h3>
            </div>
            <div className='column'>
              <h3 className='centered'>{pyxiesEarned}</h3>
            </div>
          </div>
        );
      });
    }
    return '';
  };

  const renderRecord = () => {
    return (
      <>
        <h3>Your record</h3>
        <div className='ui four column grid'>
          <div
            className='row'
            style={{ alignItems: 'center', paddingBottom: '0' }}
          >
            <div className='column'>
              <h4 className='centered'>Date</h4>
            </div>
            <div className='column'>
              <h4 className='centered'>Answers</h4>
            </div>
            <div className='column'>
              <h4 className='centered'>% Right</h4>
            </div>
            <div className='column'>
              <h4 className='centered'>Pyxies</h4>
            </div>
          </div>

          {renderEntry()}
        </div>
      </>
    );
  };

  const renderAllTimeStats = () => {
    let totalAnswers = 0;
    let correctAnswers = 0;
    let perfectAnswers = 0;

    let actualQuestions = questions.filter((question) => !question.userRecord);

    actualQuestions.forEach((question) => {
      totalAnswers = totalAnswers + question.answers.length;
      question.answers.forEach((answer) => {
        if (answer.correct) {
          correctAnswers++;
        }
      });
      if (question.answers.every((answer) => answer.correct)) {
        perfectAnswers++;
      }
    });

    const rightPercentage =
      Math.floor((correctAnswers * 10000) / totalAnswers) / 100 || 0;

    return (
      <>
        <h3>All time stats</h3>

        <div className='ui one column grid' style={{ alignItems: 'center' }}>
          <div className='column'>
            <div className='centered'>
              <div className='large-stat primary-text'>
                <p>{totalAnswers}</p>
              </div>
              Answers
            </div>
          </div>
        </div>
        <div className='ui two column grid'>
          <div className='column'>
            <div className='centered'>
              <div className='medium-stat secondary-text'>
                <p>{rightPercentage}%</p>
              </div>
              Right answers
            </div>
          </div>
          <div className=' column'>
            <div className='centered'>
              <div className='medium-stat secondary-text'>
                <p>{perfectAnswers}</p>
              </div>
              Perfect streaks
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderButton = () => {
    if (questionNumber > 0) {
      return (
        <Link
          to='/practice/quiz'
          id='secondary-button'
          className='ui big button'
        >
          Start review
        </Link>
      );
    } else {
      return (
        <Link to='/courses' id='secondary-button' className='ui big button'>
          Learn more to earn more
        </Link>
      );
    }
  };

  const renderQuestions = () => {
    const countQuestions = () => {
      let duration;

      if (questionsToAnswer.length - questionNumber > 10) {
        duration = 100;
      } else {
        duration =
          100 + 50 * (questionNumber - (questionsToAnswer.length - 10));
      }

      if (questionNumber + 1 <= questionsToAnswer.length) {
        setTimeout(() => {
          setQuestionNumber(questionNumber + 1);
        }, duration);
      }
    };

    if (loaded) {
      countQuestions();

      return (
        <div className='centered'>
          <h2 style={{ margin: '0' }}>Time to learn {user.name}!</h2>
          <div
            className={`${
              questionNumber === questionsToAnswer.length ? 'end-of-count' : ''
            } large-stat secondary-text`}
          >
            <p>{questionNumber}</p>
          </div>
          <p> Questions ready for you to review</p>
          {renderButton()}
        </div>
      );
    }
    return (
      <div className='centered'>
        <h2 style={{ margin: '0' }}>Analysing your learning{ellipses}</h2>
        <div>
          <i
            className='large spinner loading icon secondary-text'
            style={{ marginTop: '24px' }}
          ></i>
        </div>
        <p style={{ marginTop: '24px' }}>{message}</p>
        {/* <div
          className={`${
            questionNumber === questionsToAnswer.length ? 'end-of-count' : ''
          } large-stat secondary-text`}
        >
          <p>{questionNumber}</p>
        </div>
        <p> Questions ready for you to review</p>
        {renderButton()} */}
      </div>
    );
  };
  return (
    <div className='course-text'>
      <div style={{ margin: '0 0 24px 0' }}>
        <h1>Questions</h1>
      </div>
      <div>{renderQuestions()}</div>
      <div className='ui section divider'></div>
      <div style={{ marginTop: '24px' }}>{renderAllTimeStats()}</div>
      <div style={{ margin: '48px 0' }}>{renderRecord()}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user.user, questions: state.question.userQuestions };
};
export default connect(mapStateToProps, {
  getUserQuestions,
  resetUserQuestions,
})(Questions);
