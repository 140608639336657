import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Modal from '../../widgets/Modal';
import { instructorRequest } from '../../../actions';

const InstructorInterest = (props) => {
  const onRegisterClick = () => {
    props.instructorRequest();
  };
  return (
    <div>
      <Modal title='Register your interest to instruct' size='tiny'>
        <p>We'd love to talk to you about instucting on Pyxium!</p>
        <p>
          Press the button below to confirm your interest and we'll be in touch
          :)
        </p>
        <button className='ui teal button' onClick={onRegisterClick}>
          Register interest
        </button>
        <Link to='/' className='ui button'>
          Cancel
        </Link>
      </Modal>
    </div>
  );
};

export default connect(null, { instructorRequest })(InstructorInterest);
