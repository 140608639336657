import actions from '../actions/types';

export default (
  state = { generalError: false, error: false, errorDetail: '' },
  action
) => {
  switch (action.type) {
    case actions.E_SIGNUP_DUP_EMAIL:
      return {
        ...state,
        error: true,
        errorDetail: action.payload,
      };
    case actions.E_LOGIN_DETAILS:
      return {
        ...state,
        error: true,
        errorDetail: action.payload,
      };
    case actions.E_EDIT_USER:
      return {
        ...state,
        error: true,
        errorDetail: action.payload,
      };
    case actions.E_FORGOT_PASSWORD:
      return {
        ...state,
        error: true,
        errorDetail: action.payload,
      };
    case actions.E_DELETE_USER:
      return {
        ...state,
        error: true,
        errorDetail: action.payload,
      };
    case actions.E_NOT_LOGGED_IN:
      return {
        ...state,
        error: true,
        errorDetail: action.payload,
      };
    case actions.VALIDATE_EMAIL:
      return {
        ...state,
        error: false,
        errorDetail: '',
      };
    case actions.E_GENERAL:
      return {
        ...state,
        generalError: true,
      };
    case actions.RESET_ERRORS:
      return {
        ...state,
        error: false,
        generalError: false,
        errorDetail: '',
      };
    default:
      return {
        ...state,
      };
  }
};
