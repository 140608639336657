import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import history from '../../history';

const Modal = (props) => {
  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        history.goBack() || history.push('/courses');
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  if (props.loginLoad) {
    return ReactDOM.createPortal(
      <div className='ui dimmer modals visible active sticky fixed top'>
        <div
          className={`ui standard ${props.size} modal visible active`}
          style={{ height: '200px' }}
          ref={ref}
        >
          <div className='scrolling content'>{props.children}</div>
        </div>
      </div>,
      document.querySelector('#modal')
    );
  }
  return ReactDOM.createPortal(
    <div className='ui dimmer modals visible active sticky fixed top'>
      <div
        className={`ui standard ${props.size} modal visible active`}
        ref={ref}
      >
        <div className='header centered '>{props.title}</div>
        <div className='scrolling content'>{props.children}</div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export default Modal;
