import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import icon from '../../../../img/primary_icon.png';

const QuizHome = (props) => {
  const user = props.user;

  const renderOptions = () => {
    return (
      <div className='ui two column grid'>
        <div className='column'>
          <Link
            to='/practice/questions'
            className='ui link card centered course-card'
          >
            <div id='course-card-image' className='image'>
              <img
                src='https://i.ibb.co/6mqj7Xq/qmark.png'
                alt='qmark'
                border='0'
              />
            </div>
            <div className='centered content'>
              <div className='header'>Questions</div>
              <div className='description'>Answer questions to earn pyxies</div>
            </div>
          </Link>
        </div>
        <div className='column'>
          <div className='ui link card centered course-card'>
            <div id='course-card-image' className='image'>
              <img
                src='https://i.ibb.co/0Ct2NMK/brain.png'
                alt='brain'
                border='0'
              />
            </div>
            <div className='centered content'>
              <div className='header'>Practice</div>
              <div className='description'>
                Practice definitions to earn pyxies
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (user) {
    return (
      <div>
        <h1>Earn some pyxies</h1>
        <div className='centered' style={{ marginBottom: '32px' }}>
          <img src={icon} alt='pyxium_icon' style={{ maxWidth: '64px' }} />
          <br />
          <div className='ui label pyxy-label'>
            <div style={{ fontSize: '40px' }}>
              {user.pyxys.toLocaleString()}
            </div>
          </div>
        </div>
        {renderOptions()}
      </div>
    );
  }
  return '';
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(QuizHome);
