import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Modal from '../../widgets/Modal';
import UserForm from '../../widgets/UserForm';
import { createUser, resetErrors, findReferrer } from '../../../actions';
import Error from '../../widgets/Error';

const SignupReferral = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const error = props.error;
  const errorType = props.errorDetail.code;
  const referralLink = props.match.params.id;

  useEffect(() => {
    props.findReferrer(referralLink);
    return () => props.resetErrors();
    //eslint-disable-next-line
  }, []);

  const onSubmit = (formValues) => {
    props.createUser(formValues, referralLink);
    ReactGA.event({
      category: `User`,
      action: 'Sign up',
      label: 'App sign up (referral)',
      transport: 'beacon',
    });
    setSubmitted(true);
  };

  const renderDisplay = () => {
    if (submitted) {
      return (
        <div>
          <Modal>
            <div className='ui active inverted dimmer'>
              <div className='ui text loader'>Loading</div>
            </div>
          </Modal>
        </div>
      );
    }
    return (
      <div>
        <Modal
          title={`${props.referrer} is inviting you to join Pyxium. Sign up below!`}
          size='mini'
        >
          <p>
            It's completely free, just like learning should be{' '}
            <span role='img' aria-label='rocket'>
              🚀
            </span>
          </p>
          <UserForm button='Signup' onSubmit={onSubmit} />
        </Modal>
      </div>
    );
  };

  const renderError = () => {
    if (error) {
      switch (errorType) {
        case 11000:
          return (
            <div>
              <Modal title='Email already taken!'>
                <div className='centered'>
                  <h3>That email is already registered</h3>
                  <div className='ui list'>
                    <div className='item'>
                      <Link to='/login' className='ui primary button'>
                        Login
                      </Link>
                    </div>
                    <div className='item'>
                      <Link to='/forgot' className='ui button'>
                        I've forgotten my password
                      </Link>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          );
        default:
          return (
            <div>
              <Error />
            </div>
          );
      }
    }
    return <>{renderDisplay()}</>;
  };

  return <>{renderError()}</>;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    referrer: state.user.referrer,
    error: state.error.error,
    errorDetail: state.error.errorDetail,
  };
};

export default connect(mapStateToProps, {
  createUser,
  resetErrors,
  findReferrer,
})(SignupReferral);
