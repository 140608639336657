import React from 'react';
import { connect } from 'react-redux';

const Title = (props) => {
  const course = props.course;

  return <div className='ui header centered'>{course.title}</div>;
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Title);
 