import React, { useState, useEffect, useRef } from 'react';
import { Router, Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { CSSTransition } from 'react-transition-group';

// import { CSSTransition } from 'react-transition-group';

import history from '../history';
import Header from './widgets/Header';
import MobileFooter from './widgets/MobileFooter';
import Error from './widgets/Error';
import Signup from './modals/user/Signup';
import SignupReferral from './modals/user/SignupReferral';
import VerifyEmail from './modals/user/VerifyEmail';
import VerifyConf from './modals/user/VerifyConf';
import Login from './modals/user/Login';
import EditSuccess from './modals/user/EditSuccess';
import ForgotPassword from './modals/forgot-password/ForgotPassword';
import ForgotInstructions from './modals/forgot-password/ForgotInstructions';
import DeleteAccount from './modals/user/DeleteAccount';
import OrderConfirmation from './modals/ordering/OrderConfirmation';
import Home from './pages/Home';
import InstructorInterest from './modals/instructor/InstructorInterest';
import Contact from './pages/Contact';
import AllForums from './pages/forums/AllForums';
import ForumTopic from './pages/forums/ForumTopic';
import Forum from './pages/forums/Forum';
import CreateForum from './pages/forums/CreateForum';
import Courses from './pages/courses/Courses';
import CourseInfo from './pages/courses/CourseInfo';
import LiveCourse from './pages/LiveCourse';
import QuizHome from './pages/quizzes/QuizHome';
import Questions from './pages/quizzes/Questions';
import Quiz from './pages/quizzes/Quiz';
import Marketplace from './pages/marketplace/Marketplace';
import Product from './pages/marketplace/Product';
import MyAccount from './pages/MyAccount';
import EditAccount from './pages/EditAccount';
import AboutInstructor from './pages/AboutInstructor';
import DemoForms from './pages/DemoForms';
import PhoneVerification from './pages/PhoneVerification';

// ********** DEV ********* //

import { fetchUser, resetErrors } from '../actions';

// eslint-disable-next-line
import { reactRouterV3Instrumentation } from '@sentry/react';
import api from '../../api';

import icon from '../../img/primary_icon.png';

const App = (props) => {
  const [initLoad, setInitLoad] = useState(false);
  const [devSignIn, setDevSignIn] = useState(false);
  const [background, setBackground] = useState('');
  const [ellipses, setEllipses] = useState('');
  const [userNotFound, setUserNotFound] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const token = localStorage.getItem('userSession');

  useEffect(() => {
    if (token) {
      props.fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && !props.user.user) {
      if (!userNotFound) {
        setUserNotFound(true);
      }

      const changeEllipses = () => {
        if (ellipses === '...') {
          setTimeout(() => {
            setEllipses('');
          }, 500);
        } else {
          setTimeout(() => {
            setEllipses(`${ellipses}.`);
          }, 500);
        }
      };

      changeEllipses();
    } else {
      if (userNotFound) {
        setUserNotFound(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ellipses]);

  const pathHistory = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (
        (location.pathname.includes('/course') &&
          !window.location.pathname.includes('/live')) ||
        window.location.pathname.includes('instructor') ||
        window.location.pathname === '/'
      ) {
        setBackground('courses-background');
      } else if (location.pathname.includes('/practice/quiz')) {
        setBackground('quiz-background');
      } else if (location.pathname.includes('/practice')) {
        setBackground('practice-background');
      } else {
        setBackground('no-background');
      }

      if (
        location.pathname.includes('/course/live') ||
        location.pathname.includes('/practice/quiz')
      ) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    });
  }, [pathHistory]);

  const landingPageHit = () => {
    setInitLoad(true);
  };

  if (
    (window.location.pathname.includes('/course') &&
      !window.location.pathname.includes('/live')) ||
    window.location.pathname.includes('instructor') ||
    window.location.pathname === '/'
  ) {
    if (background !== 'courses-background') {
      setBackground('courses-background');
    }
  } else if (window.location.pathname.includes('/practice/quiz')) {
    if (background !== 'quiz-background') {
      setBackground('quiz-background');
    }
  } else if (window.location.pathname.includes('/practice')) {
    if (background !== 'practice-background') {
      setBackground('practice-background');
    }
  } else {
    if (background !== 'no-background') {
      setBackground('no-background');
    }
  }

  if (
    (window.location.pathname.includes('/course/live') ||
      window.location.pathname.includes('/practice/quiz')) &&
    showHeader
  ) {
    setShowHeader(false);
  }

  let loggedIn;
  if (props.user.isSignedIn) {
    loggedIn = 'Yes';
  } else {
    loggedIn = 'No';
  }

  ReactGA.set({ dimension1: loggedIn });

  const ref = useRef();
  const url = window.location.href;
  const devUrls = ['https://pyxium-dev.herokuapp.com/'];

  const onDevClick = async () => {
    const response = await api.post('/admin/dev', {
      password: ref.current.value,
    });
    if (response.data) {
      setDevSignIn(true);
    }
  };

  // const routes = [
  //   { path: '/courses', Component: Courses, class: 'test', index: 1 },
  //   { path: '/forums', Component: AllForums, class: 'test', index: 2 },
  //   { path: '/marketplace', Component: Marketplace, class: 'test', index: 3 },
  // ];

  // const renderClassNames = (route) => {
  //   // if (window.location.pathname === '/courses') {
  //   //   setCurrentIndex(1);
  //   //   return 'slide-right';
  //   // } else if (window.location.pathname === '/marketplace') {
  //   //   setCurrentIndex(3);
  //   //   return 'slide-left';
  //   // } else if (window.location.pathname === '/forums') {
  //   //   if (route.path === '/courses') {
  //   //     return 'slide-left';
  //   //   } else if (route.path === '/marketplace') {
  //   //     return 'slide-right';
  //   //   } else if (route.path === '/forums') {
  //   //     if (currentIndex < route.index) {
  //   //       return 'slide-left';
  //   //     }
  //   //     return 'slide-right';
  //   //   }
  //   // } else {
  //   //   return 'quick-fade';
  //   // }

  // };

  if (props.generalError) {
    return (
      <div>
        <Router history={history}>
          <Route path='/:id' component={Error} />
          <Route path='/courses' exact component={Courses} />
        </Router>
      </div>
    );
  } else if (devUrls.some((val) => url.includes(val)) && !devSignIn) {
    return (
      <div className='ui container'>
        <div className='center'>
          <img
            src='https://i.ibb.co/NpYZsgw/full-no-background-cropped.png'
            alt='full-no-background-cropped'
          ></img>
          <div className='centered'>
            <h1>Dev centre</h1>
            <div
              className='ui action input'
              onKeyDown={(e) => (e.key === 'Enter' ? onDevClick() : '')}
            >
              <input type='password' placeholder='Enter...' ref={ref} />
              <button
                className='ui button'
                style={{ backgroundColor: '#ffcc00' }}
                onClick={onDevClick}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <CSSTransition
          in={userNotFound}
          timeout={1000}
          classNames='original-loader'
          unmountOnExit
        >
          <div
            style={{
              position: 'absolute',
              backgroundColor: '#fff',
              width: '100%',
              zIndex: '999',
            }}
          >
            <div
              className='centered'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <div className='ui grid'>
                <div className='centered row'>
                  <img className='rotating' src={icon} alt='logo_rotating' />
                </div>
                <div className='centered row'>
                  <h3>Getting started{ellipses}</h3>
                </div>
              </div>
            </div>{' '}
          </div>
        </CSSTransition>
        {token && !props.user.user ? (
          ''
        ) : (
          <div>
            <Router history={history}>
              <div className='mobile-only'>
                {showHeader ? <Header pathHistory={pathHistory} /> : ''}
              </div>
              <div className='desktop-only'>
                <Header pathHistory={pathHistory} />
              </div>
            </Router>
            <div>
              <div className={`${background} top-positioning`}>
                <div className={`ui container`}>
                  <Router history={history}>
                    {showHeader ? (
                      <div className='mobile-footer'>
                        <MobileFooter pathHistory={pathHistory} />
                      </div>
                    ) : (
                      ''
                    )}
                    <div>
                      <div className={showHeader ? 'component-margin' : ''}>
                        <Route key='/home' exact path='/'>
                          {props.user.isSignedIn ? (
                            <Courses
                              userNotFound={userNotFound}
                              initialLoad={initLoad}
                              onInitialLoad={landingPageHit}
                              {...props}
                            />
                          ) : (
                            <Home
                              initialLoad={initLoad}
                              onInitialLoad={landingPageHit}
                              {...props}
                            />
                          )}
                        </Route>

                        {/* <Route
                          path='/instructing'
                          exact
                          component={InstructorInterest}
                        /> */}
                        {/* <Route path='/contact' exact component={Contact} /> */}
                        <Route
                          path='/forums'
                          render={(props) => (
                            <AllForums {...props} userNotFound={userNotFound} />
                          )}
                        />
                        <Route path='/forum/:id' exact component={ForumTopic} />
                        <Route path='/discuss/:id' exact component={Forum} />
                        <Route path='/create' exact component={CreateForum} />
                        <Route key='/courses' exact path='/courses'>
                          <Courses
                            userNotFound={userNotFound}
                            initialLoad={initLoad}
                            onInitialLoad={landingPageHit}
                            {...props}
                          />
                        </Route>

                        {/* {routes.map((route) => (
                <Route key={route.path} exact path={route.path}>
                  {({ match }) => (
                    <CSSTransition
                      in={match != null}
                      timeout={500}
                      classNames={renderClassNames(route)}
                      unmountOnExit
                    >
                      <div className='test'>
                        <route.Component {...props} />
                      </div>
                    </CSSTransition>
                  )}
                </Route>
              ))} */}
                        <Route
                          key='/course/live/:id/:grouping/:section'
                          path='/course/live/:id/:grouping/:section'
                          exact
                          component={LiveCourse}
                        />
                        <Route
                          path='/course/:id'
                          exact
                          component={CourseInfo}
                        />
                        <Route
                          path='/instructor/:id'
                          exact
                          component={AboutInstructor}
                        />
                        <Route path='/demo' exact component={DemoForms} />
                        <Route key='/practice' exact path='/practice'>
                          <QuizHome userNotFound={userNotFound} />
                        </Route>
                        <Route
                          key='/practice/questions'
                          exact
                          path='/practice/questions'
                        >
                          <Questions />
                        </Route>
                        <Route key='/practice/quiz' exact path='/practice/quiz'>
                          <Quiz />
                        </Route>
                        <Route key='/marketplace' exact path='/marketplace'>
                          <Marketplace userNotFound={userNotFound} />
                        </Route>

                        <Route
                          key='/product/:id'
                          exact
                          path='/product/:id'
                          component={Product}
                        />
                        <Route
                          path='/confirmation'
                          exact
                          component={OrderConfirmation}
                        />
                        <Route
                          path='/phoneverification'
                          exact
                          component={PhoneVerification}
                        />
                        <Route path='/account' exact component={MyAccount} />
                        <Route
                          path='/account/edit'
                          exact
                          component={EditAccount}
                        />
                        <Route
                          path='/account/edit/success'
                          exact
                          component={EditSuccess}
                        />
                        <Route
                          path='/account/delete'
                          exact
                          component={DeleteAccount}
                        />
                        <Route path='/signup' exact component={Signup} />
                        <Route
                          path='/signup/:id'
                          exact
                          component={SignupReferral}
                        />
                        <Route path='/verify' exact component={VerifyEmail} />
                        <Route
                          path='/verification/:id'
                          exact
                          component={VerifyConf}
                        />
                        <Route path='/login' exact component={Login} />
                        <Route
                          path='/forgotpassword/:id'
                          exact
                          component={ForgotPassword}
                        />
                        <Route
                          path='/forgot'
                          exact
                          component={ForgotInstructions}
                        />
                        {/* ******** DEV PAGES ******** */}

                        {/* ******** ARCHIVEPAGES ******** */}

                        {/* <Route key='/courses' exact path='/courses'>
                      <Courses />
                    </Route> */}
                      </div>
                    </div>
                  </Router>
                  {window.location.pathname.includes('/course/live') ||
                  window.location.pathname.includes('/practice/quiz') ? (
                    ''
                  ) : (
                    <>
                      <div className='ui divider' />
                      <div
                        className='ui container'
                        style={{ paddingBottom: '80px' }}
                      >
                        <div className='ui grid'>
                          <div className='centered column'>
                            <h4>Follow us on social media</h4>
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://www.facebook.com/pyxium'
                              className='ui circular facebook icon button'
                              style={{
                                margin: '4px',
                              }}
                            >
                              <i className='facebook icon brand-icon'></i>
                            </a>
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://twitter.com/pyxium'
                              className='ui circular twitter icon button'
                              style={{ margin: '4px' }}
                            >
                              <i className='twitter icon brand-icon'></i>
                            </a>
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://www.instagram.com/pyxium/'
                              className='ui circular instagram icon button'
                              style={{ margin: '4px' }}
                            >
                              <i className='instagram icon brand-icon'></i>
                            </a>
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              href='https://www.linkedin.com/company/pyxium/'
                              className='ui circular linkedin icon button'
                              style={{ margin: '4px 4px 8px 4px' }}
                            >
                              <i className='linkedin icon brand-icon'></i>
                            </a>
                            <div>
                              <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://pyxium.co/'
                                style={{
                                  textDecoration: 'underline',
                                  alignItems: 'flex-end',
                                }}
                              >
                                Back to pyxium.co
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    generalError: state.error.generalError,
    error: state.error.error,
    errorDetail: state.error.errorDetail,
  };
};

export default connect(mapStateToProps, { fetchUser, resetErrors })(App);
