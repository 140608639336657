import actions from '../actions/types';

export default (state, action) => {
  switch (action.type) {
    case actions.SET_ANSWER:
      return {
        ...state,
        answer: action.payload.answer,
      };
    default:
      return {
        ...state,
      };
  }
};
