import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import history from '../../../history';

import icon from '../../../../img/primary_icon.png';

import { manageSetting } from '../../../actions';

const SkipMessage = (props) => {
  const [checked, setChecked] = useState(false);

  const user = props.user;
  const course = props.course;

  const ref = useRef();

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const onSkip = () => {
    if (checked) {
      props.manageSetting(user._id, 'acceptSkip', true);
    }
    props.onClose('skipped');

    history.push(
      `/course/live/${course.id}/${course.grouping}/${
        parseInt(course.section) + 1
      }`
    );
  };

  const onClose = () => {
    props.onClose('closeed');
  };

  return (
    <div
      className='ui centered message'
      style={{
        borderRadius: '16px',
        border: 'solid 2px #fc0',
        background: '#fff',
      }}
    >
      <div className='header'>
        {' '}
        <img
          className='logo-small'
          style={{ height: '40px' }}
          src={icon}
          alt='pyxium_logo'
        />
        <br />
        Are you sure you want to skip?
      </div>
      <p>Answering questions is how you earn pyxies!</p>
      <div className='field'>
        <div className='ui checkbox'>
          <input
            type='checkbox'
            name={`option 1`}
            onChange={() => setChecked(!checked)}
          />
          <label>Don't show this message again</label>
        </div>
      </div>
      <div
        id='secondary-course-button'
        className='ui button'
        style={{ marginTop: '8px' }}
        onClick={onSkip}
      >
        I'm sure
      </div>
      <div
        id='basic-secondary-button'
        className='ui basic button'
        style={{ marginTop: '8px', borderRadius: '50px' }}
        onClick={onClose}
      >
        I'll answer
      </div>
      <div ref={ref}></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};
export default connect(mapStateToProps, { manageSetting })(SkipMessage);
