import React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import Modal from '../../widgets/Modal';

const OrderConfirmation = (props) => {
  return (
    <div>
      <Modal title='Purchase confirmed!'>
        <p className='centered'>
          Thanks for your purchase! Keep an eye on your email for more
          information.
        </p>
        {props.user.pyxys ? (
          <div className='centered'>
            You now have
            <br />
            <span
              style={{ color: '#6556ff', fontSize: '200%', fontWeight: '700' }}
            >
              {props.user.pyxys.toLocaleString()}
            </span>
            <br />
            {props.user.pyxys === 1 ? 'pyxy' : 'pyxies'}
          </div>
        ) : (
          ''
        )}

        <div className='centered' style={{ marginTop: '16px' }}>
          <Link id='secondary-button' className='ui button' to='/marketplace'>
            Got it
          </Link>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user.user };
};

export default connect(mapStateToProps)(OrderConfirmation);
