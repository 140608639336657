import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import timeAgo from '../../tools/timeAgo';

import {
  updateReadNotifications,
  updateUnreadNotifications,
} from '../../actions/index';

const Notifications = (props) => {
  const [open, setOpen] = useState(false);
  const [dropdownLoaded, setDropdownLoaded] = useState(false);

  const user = props.user;
  const isSignedIn = props.isSignedIn;

  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        return;
      } else {
        setOpen(false);
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  const dropdownAnimation = () => {
    if (open) {
      return 'animating slide down in visible transition';
    } else if (dropdownLoaded) {
      return 'animating slide down out visible transition';
    } else {
      return '';
    }
  };

  const onDropdownClick = (term) => {
    if (!dropdownLoaded) {
      setDropdownLoaded(true);
    }

    if (term !== 'read') {
      setOpen(!open);
    }
  };

  const renderIcon = (notification) => {
    if (notification === 'forum') {
      return (
        <i
          className='user circle icon'
          style={{ fontSize: '2em important' }}
        ></i>
      );
    } else if (notification === 'comment') {
      return (
        <i className='comment icon' style={{ fontSize: '2em important' }}></i>
      );
    } else if (notification === 'reply') {
      return (
        <i className='comments icon' style={{ fontSize: '2em important' }}></i>
      );
    }
  };

  const renderNotifications = () => {
    let sortedNotifications = user.notifications.sort(
      (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
    );

    if (sortedNotifications.length !== 0) {
      return sortedNotifications.map((notification) => {
        return (
          <div key={sortedNotifications.indexOf(notification)}>
            <Link
              to={notification.link}
              className={`${!notification.read ? 'active' : ''} item`}
            >
              <div
                className='ui grid'
                onClick={() => {
                  onDropdownClick('menu');
                  props.updateReadNotifications(notification._id);
                  props.isOpen();
                }}
                style={{ whiteSpace: 'normal' }}
              >
                <div
                  className='two wide column'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '16px 0',
                  }}
                >
                  {renderIcon(notification.category)}
                </div>
                <div
                  className='fourteen wide column'
                  style={{ padding: '16px 0' }}
                >
                  {notification.text}
                  <div className='notification-meta'>
                    {timeAgo.format(Date.parse(notification.createdAt))}
                    {notification.read ? (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          onDropdownClick('read');
                          props.updateUnreadNotifications(notification._id);
                        }}
                        style={{
                          cursor: 'pointer',
                          color: '#fc0',
                          marginLeft: '4px',
                        }}
                      >
                        Mark as unread
                      </span>
                    ) : (
                      ''
                      // <span
                      //   onClick={(e) => {
                      //     e.stopPropagation();
                      //     e.preventDefault();
                      //     onDropdownClick('read');
                      //     props.updateReadNotifications(notification._id);
                      //   }}
                      //   style={{
                      //     cursor: 'pointer',
                      //     color: '#fc0',
                      //     marginLeft: '4px',
                      //   }}
                      // >
                      //   Mark as read
                      // </span>
                    )}
                  </div>
                </div>
              </div>
            </Link>
            {/* {sortedNotifications.indexOf(notification) ===
            sortedNotifications.length - 1 ? (
              <div style={{ marginBottom: '4px' }}> </div>
            ) : (
              <div className='divider'></div>
            )} */}
          </div>
        );
      });
    }
    return <div className='item'>No new notifications</div>;
  };

  const renderMobile = () => {
    const unreadNotifications = user.notifications.filter((not) => !not.read);
    return (
      <div className='ui grid'>
        <div
          className={`mobile-footer-item item ui dropdown ${
            open ? 'visible active' : ''
          }`}
        >
          <div onClick={() => onDropdownClick('menu')}>
            <i
              id={`mobile-header-icon${open ? '-active-notification' : ''}`}
              className='large bell icon'
              style={{ margin: '0', float: 'left' }}
            ></i>
            {unreadNotifications.length === 0 ? (
              ''
            ) : (
              <sup>
                <div
                  className='ui mini circular label'
                  style={{ backgroundColor: '#ffcc00' }}
                >
                  {unreadNotifications.length}
                </div>
              </sup>
            )}
          </div>
          <div
            className={`menu ${dropdownAnimation()} mobile notifications `}
            style={{ maxHeight: '50vh', overflowY: 'scroll' }}
          >
            {renderNotifications()}
          </div>
        </div>
      </div>
    );
  };

  const renderDesktop = () => {
    const unreadNotifications = user.notifications.filter((not) => !not.read);

    return (
      <div className='ui grid'>
        <div
          className={`mobile-footer-item item ui dropdown ${
            open ? 'visible active' : ''
          }`}
        >
          <div onClick={() => onDropdownClick('menu')}>
            <i
              id={`mobile-header-icon${open ? '-active-notification' : ''}`}
              className='large bell icon'
              style={{ margin: '0', float: 'left' }}
            ></i>
            {unreadNotifications.length === 0 ? (
              ''
            ) : (
              <sup>
                <div
                  className='ui mini circular label'
                  style={{ backgroundColor: '#ffcc00' }}
                >
                  {unreadNotifications.length}
                </div>
              </sup>
            )}
          </div>
          <div
            className={`menu ${dropdownAnimation()}  notifications`}
            style={{ maxHeight: '50vh', overflowY: 'scroll' }}
          >
            {renderNotifications()}
          </div>
        </div>
      </div>
    );
  };

  if (user && isSignedIn) {
    return (
      <>
        <div className='ui grid' ref={ref}>
          <div className='computer only sixteen wide column'>
            <div className='column'>{renderDesktop()}</div>
          </div>
          <div className='mobile tablet only sixteen wide column'>
            <div className='column'>{renderMobile()}</div>
          </div>
        </div>
      </>
    );
  }
  return '';
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.user.isSignedIn, user: state.user.user };
};

export default connect(mapStateToProps, {
  updateReadNotifications,
  updateUnreadNotifications,
})(Notifications);
