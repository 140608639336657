import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { logoutUser } from '../../actions';


const NavDropdown = (props) => {
  // const [open, setOpen] = useState(false);
  // const ref = useRef();

  const [active, setActive] = useState('');

  useEffect(() => {
    setActive(window.location.pathname);
  }, []);

  useEffect(() => {
    const onClick = () => {
      if (active !== window.location.pathname) {
        setActive(window.location.pathname);
      }
    };

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
    // es-lint-disable-next-line
  }, []);

  // useEffect(() => {
  //   // const onBodyClick = (event) => {
  //   //   if (ref.current.contains(event.target)) {
  //   //     return;
  //   //   }
  //   //   setOpen(false);
  //   // };
  //   // document.body.addEventListener('click', onBodyClick);
  //   // return () => {
  //   //   document.body.removeEventListener('click', onBodyClick);
  //   // };
  // }, []);

  // const options = [
  //   {
  //     id: 0,
  //     title: 'My account',
  //     path: '/account',
  //   },
  //   {
  //     id: 1,
  //     title: 'Edit account',
  //     path: '/account/edit',
  //   },
  // ];

  // const onClick = () => {
  //   setOpen(!open);
  //   props.onClick();
  // };

  const onButtonClick = () => {
    props.onClick();
  };

  // const onLogoutClick = () => {
  //   setOpen(!open);
  //   props.logoutUser();
  //   history.push('/courses');
  // };

  // const renderedOptions = options.map((option) => {
  //   return (
  //     <Link to={option.path} key={option.id} className='item' onClick={onClick}>
  //       {option.title}
  //     </Link>
  //   );
  // });

  const renderCompAuth = () => {
    if (props.isSignedIn) {
      return (
        <div className='ui grid top'>
          {/* <div
            ref={ref}
            className={`item ui dropdown ${open ? 'visible active' : ''}`}
            onClick={() => setOpen(!open)}
          >
            <div>
              <i className='big user circle icon'></i>
            </div>
            <div>
              <i className='dropdown icon'></i>
            </div>
            <div className={`menu ${open ? 'visible transition' : ''}`}>
              {renderedOptions}
              <div className='item' onClick={onLogoutClick}>
                Logout
              </div>
            </div>
          </div> */}
          <Link to='/account' className='item mobile-footer-item'>
            <i
              id={`mobile-header-icon${active === '/account' ? '-active' : ''}`}
              className='big user circle icon'
            />
          </Link>
        </div>
      );
    }

    return (
      <>
        <div className='item' style={{ padding: '0px' }}>
          <Link
            id='basic-button'
            style={{ borderRadius: '50px' }}
            to='/login'
            className='ui basic button'
            onClick={onButtonClick}
          >
            Login
          </Link>
        </div>
        <div className='item' style={{ padding: '0px' }}>
          <Link
            id='basic-secondary-button'
            style={{ borderRadius: '50px' }}
            to='/signup'
            className='ui basic button'
            onClick={onButtonClick}
          >
            Sign up
          </Link>
        </div>
      </>
    );
  };

  return <>{renderCompAuth()}</>;
};

const mapStateToProps = (state) => {
  return { isSignedIn: state.user.isSignedIn, user: state.user.user };
};

export default connect(mapStateToProps, { logoutUser })(NavDropdown);
