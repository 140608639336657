import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const DemoForms = (props) => {
  return (
    <div>
      <div className='centered'>
        <img
          style={{ maxWidth: '300px' }}
          src='https://i.ibb.co/RhCqC4C/Question-Marks.png'
          alt='Question-Marks'
          border='0'
        />
        <h1>Pyxium demo surveys</h1>
      </div>
      <h2>Initial survey</h2>
      <a
        href='https://forms.gle/NZEb3v9eJw4nUsL6A'
        target='_blank'
        rel='noopener noreferrer'
        className='ui button'
        id='secondary-button'
      >
        Take initial survey
      </a>
      <h2>Final survey</h2>
      <a
        href='https://forms.gle/cmbaDvxCrX9g64RN9'
        target='_blank'
        rel='noopener noreferrer'
        className='ui button'
        id='secondary-button'
      >
        Take final survey
      </a>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(DemoForms);
