import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import ReactGA from 'react-ga';

import { fetchCourses } from '../../actions';

import history from '../../history';

const Home = (props) => {
  useEffect(() => {
    if (!props.initialLoad) {
      // ReactGA.pageview(props.location.pathname);
      props.onInitialLoad();
    }
    props.fetchCourses('Home');
    //eslint-disable-next-line
  }, []);

  // Not logged in display
  const renderBasic = (displayType) => {
    if (displayType === 'desktop') {
      return (
        <div>
          <div className='centered'>
            <h1 className='centered'>Welcome to Pyxium!</h1>
            <h3>The free platform turning your learning into currency.</h3>
            {/* 
            <img
              src='https://i.ibb.co/TqvZWzB/Screenshot-2021-02-01-160754.png'
              alt='pyxium_diagram'
              className='course-image'
            ></img> */}
            <a
              href='https://pyxium.co'
              target='_blank'
              rel='noopener noreferrer'
            >
              What's all this about?
            </a>
          </div>
          <br />
          {/* <h2>How it works</h2>
          <p>
            We believe in the inherent value of learning. That's why we've created
            Pyxium, a platform that converts the value you create when you learn
            into a currency you can use to buy things.
          </p>
          <p>
            When you take our completely free courses on Pyxium, you can validate
            your learning by answering questions. When you answer questions right,
            you earn 'pyxies' (pronounced pik-sees, like the mythical creature).
            You can then use these pyxies to buy things in the Pyxium marketplace.
          </p>
          <p>Sound good? Get started below!</p>
          <h3 className='centered' style={{ color: '#ffae00', fontSize: '200%' }}>
            Learn content. Earn pyxies.
          </h3> */}
          <div className='ui grid'>
            <div className='five wide column'></div>
            <div className='six wide column'>
              <div className='ui segment centered'>
                <h3 className='centered'>Start your Pyxium journey</h3>
                <p>
                  Sign up and start earning pyxies - it's completely free and
                  always will be
                </p>
                <Link to='/signup' id='secondary-button' className='ui button'>
                  Sign up
                </Link>
              </div>
            </div>
            <div className='five wide column'></div>
            <div className='five wide column'></div>
            <div className='six wide column'>
              <div className='ui segment centered'>
                <h3 className='centered'>Login to your Pyxium account</h3>
                <p>Keep learnin', keep earnin'</p>
                <Link to='/login' id='secondary-button' className='ui button'>
                  Login
                </Link>
              </div>
            </div>
            <div className='five wide column'></div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className='centered'>
          <h1 className='centered'>Welcome to Pyxium!</h1>
          <h3>The free platform turning your learning into currency.</h3>
          <img
            src='https://i.ibb.co/TqvZWzB/Screenshot-2021-02-01-160754.png'
            alt='pyxium_diagram'
            className='course-image'
          ></img>
          <a href='https://pyxium.co' target='_blank' rel='noopener noreferrer'>
            What's all this about?
          </a>
        </div>
        <br />
        <div className='ui grid'>
          <div className='sixteen wide column'>
            <div className='ui segment centered'>
              <h3 className='centered'>Start your Pyxium journey</h3>
              <p>
                Sign up and start earning pyxies - it's completely free and
                always will be
              </p>
              <Link to='/signup' id='secondary-button' className='ui button'>
                Sign up
              </Link>
            </div>
          </div>
          <div className='sixteen wide column'>
            <div className='ui segment centered'>
              <h3 className='centered'>Login to your Pyxium account</h3>
              <p>Keep learnin', keep earnin'</p>
              <Link to='/login' id='secondary-button' className='ui button'>
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (props.isSignedIn) {
    history.push('/courses');
  }
  return (
    <div className='ui grid'>
      <div className='ui computer only sixteen wide column'>
        {renderBasic('desktop')}
      </div>
      <div className='ui mobile tablet only sixteen wide column'>
        {renderBasic('mobile')}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.user.isSignedIn,
    courses: state.courses.courses,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, { fetchCourses })(Home);
