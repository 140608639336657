import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import CourseFeedback from '../../widgets/CourseFeedback';

import { likeCourse, getForums, fetchCourse } from '../../../actions';

const End = (props) => {
  const [nextSection, setNextSection] = useState(false);
  const user = props.user;
  useEffect(() => {
    props.getForums(props.course.id, user);
    props.fetchCourse(props.course.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const end = props.course.end;
  const forums = props.forums;

  const renderSources = (sources) => {
    return sources.map((source) => {
      return (
        <div className='item' key={sources.indexOf(source)}>
          <a target='_blank' rel='noopener noreferrer' href={source.link}>
            {source.text}
          </a>
        </div>
      );
    });
  };

  const renderMobileForums = () => {
    let sortedForums;
    forums.sort((a, b) => b.score - a.score);

    if (forums.length > 3) {
      sortedForums = forums.slice(0, 3);
    } else {
      sortedForums = forums;
    }

    return sortedForums.map((forum) => {
      return (
        <div className='twelve wide column' key={sortedForums.indexOf(forum)}>
          <Link to={`/discuss/${forum.url}`} className='ui card '>
            <div
              className='content primary-card-content'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className='header' id='primary-card-header'>
                {forum.title}
              </div>{' '}
            </div>
            <div className='extra content'>
              <i id='primary-icon' className='thumbs up outline icon'></i>
              {forum.score} <i id='primary-icon' className='user icon'></i>
              {forum.participants.length}{' '}
              <i id='primary-icon' className='comment icon'></i>
              {forum.comments.filter((com) => !com.deleted).length}
            </div>
          </Link>
        </div>
      );
    });
  };

  const renderDesktopForums = () => {
    let sortedForums;
    forums.sort((a, b) => b.score - a.score);

    if (forums.length > 3) {
      sortedForums = forums.slice(0, 2);
    } else {
      sortedForums = forums;
    }

    return sortedForums.map((forum) => {
      return (
        <div className='five wide column' key={sortedForums.indexOf(forum)}>
          <Link to={`/discuss/${forum.url}`} className='ui card '>
            <div
              className='content primary-card-content'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className='header' id='primary-card-header'>
                {forum.title}
              </div>{' '}
            </div>
            <div className='extra content'>
              <i id='primary-icon' className='thumbs up outline icon'></i>
              {forum.score} <i id='primary-icon' className='user icon'></i>
              {forum.participants.length}{' '}
              <i id='primary-icon' className='comment icon'></i>
              {forum.comments.filter((com) => !com.deleted).length}
            </div>
          </Link>
        </div>
      );
    });
  };

  const renderForums = () => {
    return (
      <div className='ui grid'>
        <div className='sixteen wide column desktop-only'>
          <div className='column'>
            <div className='ui stretched grid'>
              {renderDesktopForums()}
              <div className='five wide column'>
                <Link to={`/forum/${props.course.id}`} className='ui card '>
                  <div
                    className='content primary-card-content'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='header' id='primary-card-header'>
                      See more <i className='right arrow circle icon'></i>
                    </div>{' '}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='sixteen wide column mobile-only'>
          <div className='column'>
            <div className='ui stretched grid x-overflow no-scrollbar'>
              {renderMobileForums()}
              <div className='twelve wide column'>
                <Link to={`/forum/${props.course.id}`} className='ui card '>
                  <div
                    className='content primary-card-content'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='header' id='primary-card-header'>
                      See more <i className='right arrow circle icon'></i>
                    </div>{' '}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderNextSection = (type) => {
    if (props.courseTitlePage.structure) {
      const section = props.courseTitlePage.structure.find(
        (el) => parseInt(el.grouping) === parseInt(props.course.grouping) + 1
      );
      if (section) {
        let width;
        let height;

        if (type === 'desktop') {
          width = 'five';
          height = '150px';
        } else {
          width = 'ten';
          height = '100px';
        }

        if (section.comingSoon) {
          return (
            <div>
              <h3>Stay tuned for more learning!</h3>
              <div className='ui grid'>
                <div className={`${width} wide column`}>
                  <div
                    to={`/course/live/${props.course.id}/${section.grouping}/${
                      parseInt(section.section) + 1
                    }`}
                    className='ui card course-card'
                  >
                    <div id='course-card-image' className='image locked'>
                      <img
                        src={section.image}
                        alt='section_img'
                        style={{ maxHeight: `${height}`, opacity: '0.6' }}
                      />
                      <div
                        className='ui top right attached label'
                        style={{
                          borderRadius: '16px',
                          margin: '4px',
                          color: '#ffffff',
                          backgroundColor: '#6556FF',
                        }}
                      >
                        Coming Soon
                      </div>
                    </div>
                    <div className='content' style={{ padding: '8px' }}>
                      <div className='header' style={{ fontSize: '14px' }}>
                        {section.title}
                      </div>
                      <div className='description' style={{ fontSize: '12px' }}>
                        {section.content}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }

        if (!nextSection) {
          setNextSection(true);
        }

        return (
          <div>
            <h3>Continue to the next lesson</h3>
            <div className='ui grid'>
              <div className={`${width} wide column`}>
                <Link
                  to={`/course/live/${props.course.id}/${section.grouping}/${
                    parseInt(section.section) + 1
                  }`}
                  className='ui link card course-card'
                >
                  <div id='course-card-image' className='image'>
                    <img
                      src={section.image}
                      alt='section_img'
                      style={{ maxHeight: `${height}` }}
                    />
                  </div>
                  <div className='content' style={{ padding: '8px' }}>
                    <div className='header' style={{ fontSize: '14px' }}>
                      {section.title}
                    </div>
                    <div className='description' style={{ fontSize: '12px' }}>
                      {section.content}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div>
          <h3>Browse more courses</h3>
          <Link to='/courses' id='secondary-button' className='ui button'>
            Go to courses
          </Link>
        </div>
      );
    }
  };

  const onLikeClick = (lesson, course) => {
    props.likeCourse(lesson, course);
  };

  if (end.content) {
    const sources = end.sources;
    return (
      <div>
        {nextSection ? (
          <p>
            You've reached the end of the lesson - continue to the next one
            using the card below{' '}
            <span role='img' aria-label='rocket'>
              🚀
            </span>
          </p>
        ) : (
          <p>
            Amazing! You’ve reached the end of the lesson! We hope you enjoyed
            it, and learned something valuable
            <span role='img' aria-label='smiley'>
              😊
            </span>
          </p>
        )}

        <p>
          {nextSection ? 'And say' : 'Say'} thanks to the instructor by leaving
          them a like!
        </p>
        {props.user.newCourseLikes.some(
          (like) => like.lesson === props.course._id
        ) ? (
          <div
            id='green-course-button'
            className='ui medium left labeled icon disabled button'
            style={{ margin: '0 0 8px 0' }}
          >
            <i className='check icon'></i>Liked lesson
          </div>
        ) : (
          <div
            id='green-course-button'
            className='ui medium right labeled icon  button'
            style={{ margin: '0 0 8px 0' }}
            onClick={() => onLikeClick(props.course._id, props.course.id)}
          >
            <i className='thumbs up icon'></i>Like the lesson
          </div>
        )}
        <div style={{ marginTop: '16px' }}>
          <div className='mobile-only'>{renderNextSection('mobile')}</div>
          <div className='desktop-only'>{renderNextSection('desktop')}</div>
        </div>
        <h3>Join the course forums</h3>
        {renderForums()}

        <h3>Sources and further reading</h3>
        <p>
          Below are the resources used to compile this course – explore them
          further to dive into more depth!
        </p>
        <div>
          {sources.images.length !== 0 ? (
            <>
              <div className='ui header'>Images</div>
              <div className='ui list'>{renderSources(sources.images)}</div>
            </>
          ) : (
            ''
          )}
          {sources.books.length !== 0 ? (
            <>
              <div className='ui header'>Books</div>
              <div className='ui list'>{renderSources(sources.books)}</div>
            </>
          ) : (
            ''
          )}
          {sources.articles.length !== 0 ? (
            <>
              <div className='ui header'>Articles</div>
              <div className='ui list'>{renderSources(sources.articles)}</div>
            </>
          ) : (
            ''
          )}
          {sources.papers.length !== 0 ? (
            <>
              <div className='ui header'>Papers</div>
              <div className='ui list'>{renderSources(sources.papers)}</div>
            </>
          ) : (
            ''
          )}
          {sources.videos.length !== 0 ? (
            <>
              <div className='ui header'>Videos</div>
              <div className='ui list'>{renderSources(sources.videos)}</div>
            </>
          ) : (
            ''
          )}
          {sources.podcasts.length !== 0 ? (
            <>
              <div className='ui header'>Podcasts</div>
              <div className='ui list'>{renderSources(sources.podcasts)}</div>
            </>
          ) : (
            ''
          )}
          {sources.other.length !== 0 ? (
            <>
              <div className='ui header'>Other resources</div>
              <div className='ui list'>{renderSources(sources.other)}</div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    forums: state.forums.forums,
    courseTitlePage: state.courses.course,
  };
};
export default connect(mapStateToProps, { likeCourse, getForums, fetchCourse })(
  End
);
