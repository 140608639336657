import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { sendVerificationEmail } from '../../../actions';

import Modal from '../../widgets/Modal';

const VerifyEmail = (props) => {
  const email = props.user.email;

  useEffect(() => {
    props.sendVerificationEmail(email);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Modal title='Please verify your email' size='small'>
        <div>
          <p>
            We've sent a verification email to <b>{email}</b>. Please follow the
            instructions to verify your email. It may be in your junk!
          </p>
          <p>
            {' '}
            You can still use Pyxium in the meantime, but you won't be able to
            order anything with your pyxies until your email is verified.
          </p>
          <div className='ui divider'></div>
          <div>
            <h5 className='centered'>Not the right email?</h5>
            <p>
              No worries! You can update your details following the link below.
              Once updated, you'll be able to request a verification link from
              your 'My Account' page, or you'll be sent one when you make your
              first order.
            </p>
          </div>
          <div className='centered' style={{ marginTop: '16px' }}>
            <Link
              to='/account/edit'
              id='basic-secondary-button'
              className='ui basic button'
              style={{ borderRadius: '50px' }}
            >
              Edit your email
            </Link>
            <Link to='/' id='secondary-button' className='ui button'>
              Close
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, { sendVerificationEmail })(VerifyEmail);
