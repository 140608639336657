import React from 'react';
import { Field, reduxForm } from 'redux-form';
import ReactGA from 'react-ga';

import api from '../../../api';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }
  onSubmit = async (formValues) => {
    await api.post('/user/feedback', {
      email: formValues.email,
      feedback: formValues.feedback,
    });
    ReactGA.event({
      category: `Feedback`,
      action: 'General Feedback',
      transport: 'beacon',
    });
    this.setState({ submitted: true });
  };

  renderInput = ({ label, input }) => {
    return (
      <div className='field'>
        <label>{label}</label>
        <input
          {...input}
          placeholder='Enter your email (optional)'
          autoComplete='off'
        ></input>
      </div>
    );
  };

  renderTextArea = ({ label, input }) => {
    return (
      <div className='field'>
        <label>{label}</label>
        <textarea
          {...input}
          placeholder='Tell us what you think :)'
          autoComplete='off'
        ></textarea>
      </div>
    );
  };

  renderSubmitted = () => {
    if (this.state.submitted) {
      return (
        <div className='ui positive message'>
          <div className='header'>Thanks for your feedback!</div>
          <p>
            Getting feedback from you at this stage is crazy important to us -
            thank you for taking the time to submit it! We'll take a look, and
            if you left your email we'll be in touch if we have any more
            questions{' '}
          </p>
        </div>
      );
    }
    return (
      <div className='ui segment'>
        <h1 className='centered'>Give us feedback!</h1>
        <p>
          We'd love to hear from you about Pyxium - the good, the bad and the
          ugly. Please send us any feedback you have to help us improve our app
          below.
        </p>
        <p>
          We've left an optional field for your email. If you're happy for us to
          get in touch with you via email and discuss your feedback further,
          that'd be amazing! If you'd rather stay anonymous, that's completely
          cool too.
        </p>
        <form
          className='ui form'
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name='email'
            component={this.renderInput}
            label='Enter your email'
          />
          <Field
            name='feedback'
            component={this.renderTextArea}
            label='Feedback'
          />
          <button className='ui primary button' onClick={this.onSubmit}>
            Submit feedback
          </button>
        </form>
      </div>
    );
  };

  render() {
    return <div>{this.renderSubmitted()}</div>;
  }
}

export default reduxForm({
  form: 'feedbackForm',
})(Contact);
