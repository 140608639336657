import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';

const DevPurchaseList = (props) => {
  const [firstPosition, setFirstPosition] = useState(1);
  const [columnHeight, setColumnHeight] = useState(364);
  const [scrollable, setScrollable] = useState(false);
  const items = props.items;

  const columnRef = useRef();
  const ref = useRef();

  useEffect(() => {
    if (columnRef.current && columnRef.current.offsetHeight !== columnHeight) {
      setColumnHeight(columnRef.current.offsetHeight);
    }
  });

  const onRightScrollClick = async (ref) => {
    if (!scrollable) {
      setScrollable(true);
      ref.current.scrollBy({
        top: 0,
        left: columnRef.current.offsetWidth,
        behavior: 'smooth',
      });
      setFirstPosition(firstPosition + 1);
      await setTimeout(() => {
        setScrollable(false);
      }, 500);
    }
  };

  const onLeftScrollClick = async (ref) => {
    if (!scrollable) {
      setScrollable(true);
      ref.current.scrollBy({
        top: 0,
        left: -columnRef.current.offsetWidth,
        behavior: 'smooth',
      });
      setFirstPosition(firstPosition - 1);
      await setTimeout(() => {
        setScrollable(false);
      }, 500);
    }
  };

  const renderScrollIcon = (direction) => {
    if (direction === 'left') {
      return (
        <div
          className='desktop-scroll-arrow left'
          style={{ bottom: `${columnHeight / 2 - 28}px` }}
        >
          <i
            className='huge caret left icon'
            onClick={() => onLeftScrollClick(ref)}
          ></i>{' '}
        </div>
      );
    } else {
      return (
        <div
          className='desktop-scroll-arrow right'
          style={{
            bottom: `${columnHeight / 2 - 28}px`,
          }}
        >
          <i
            className='huge caret right icon'
            onClick={() => onRightScrollClick(ref)}
          ></i>{' '}
        </div>
      );
    }
  };

  const renderDesktop = () => {
    return items.map((item, i) => {
      return (
        <div className='four wide column' key={item} ref={columnRef}>
          <div className='ui card course-card'>
            <div className='content'>
              <div className='header'>{item.title}</div>
              <div className='description'>
                <span style={{ color: '#6556ff', fontWeight: '700' }}>
                  Price:
                </span>{' '}
                {item.price} pyxies
              </div>
              <div className='description'>
                <span style={{ color: '#6556ff', fontWeight: '700' }}>
                  Date:
                </span>{' '}
                {item.time}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderMobile = () => {
    return items.map((item, i) => {
      return (
        <div className='ten wide column' key={i}>
          <div className='ui card course-card'>
            <div className='content'>
              <div className='header'>{item.title}</div>
              <div className='description'>
                <span style={{ color: '#6556ff', fontWeight: '700' }}>
                  Price:
                </span>{' '}
                {item.price} pyxies
              </div>
              <div className='description'>
                <span style={{ color: '#6556ff', fontWeight: '700' }}>
                  Date:
                </span>{' '}
                {item.time}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <div>
      <div className='mobile-only'>
        <div
          className='ui stretched grid x-overflow no-scrollbar'
          style={{ marginBottom: '24px' }}
        >
          {renderMobile()}
        </div>
      </div>
      <div className='desktop-only' style={{ position: 'relative' }}>
        <div
          className='ui stretched grid x-overflow-desktop no-scrollbar'
          style={{ marginBottom: '24px' }}
          ref={ref}
        >
          {renderDesktop()}
        </div>
        {firstPosition === 1 ? '' : renderScrollIcon('left')}
        {firstPosition + 4 > items.length ? '' : renderScrollIcon('right')}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(DevPurchaseList);
