import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Modal from '../../widgets/Modal';
import { validatePasswordReset, validateResetEmail } from '../../../actions';
import UserForm from '../../widgets/UserForm';

const ForgotPassword = (props) => {
  const token = props.match.params.id;
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    props.validatePasswordReset(token);
    //eslint-disable-next-line
  }, []);

  const onSubmit = (formValues) => {
    props.validateResetEmail(formValues, token);
    setSubmitted(true);
  };

  const renderValidation = () => {
    if (!submitted) {
      if (props.isValid) {
        return (
          <div style={{ maxWidth: '300px' }}>
            <UserForm onSubmit={onSubmit} button='Set new password' />
          </div>
        );
      } else {
        return (
          <div>
            <p>Unfortunately that link has expired</p>
            <Link to='/forgot'>Request a new link</Link>
          </div>
        );
      }
    }
    return (
      <div>
        <p>
          Your password has been reset! You can now login with your new
          password.
        </p>
        <Link
          to={{
            pathname: '/login',
            state: { prevPath: window.location.pathname },
          }}
          id='primary-button'
          className='ui button'
        >
          Login
        </Link>
      </div>
    );
  };

  return (
    <div>
      <Modal title='Forgot password' size='mini'>
        {renderValidation()}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isValid: state.user.isValid,
    isEmailResetValid: state.user.isEmailResetValid,
  };
};

export default connect(mapStateToProps, {
  validatePasswordReset,
  validateResetEmail,
})(ForgotPassword);
