import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactGA from 'react-ga';

import {
  fetchDevSection,
  setCourseCompletion,
  fetchStructure,
  saveUserProgress,
  unsavedProgress,
  manageSetting,
  resetErrors,
} from '../../actions';

import history from '../../history';
import icon from '../../../img/primary_icon.png';

import Course from '../course/Course';
import BackArrow from '../widgets/BackArrow';

const LiveCourse = (props) => {
  const [active, setActive] = useState(parseInt(props.match.params.section));
  const [showTutorial, setShowTutorial] = useState(false);
  const [newPyxyValue, setNewPyxyValue] = useState(0);
  const [initialLoad, setInitialLoad] = useState(false);

  const user = props.user;
  const fullSection = props.course;
  const saved = props.saved;
  const error = props.errorDetail;

  let course = {};

  if (fullSection.length) {
    course = fullSection.sort(
      (a, b) => parseInt(a.section) - parseInt(b.section)
    )[0];
  }

  useEffect(() => {
    return () => props.resetErrors();
  }, []);

  useEffect(() => {
    if (!initialLoad && user) {
      props.fetchDevSection(props.match.params.id, props.match.params.grouping);
      setInitialLoad(true);
    }
  }, [user]);

  useEffect(() => {
    setNewPyxyValue(newPyxyValue + 1);
    setTimeout(() => {
      setNewPyxyValue(1);
    }, 1000);
  }, [user.pyxys]);

  useEffect(() => {
    props.fetchDevSection(props.match.params.id, props.match.params.grouping);

    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, [props.match.params.grouping]);

  useEffect(() => {
    let save;
    if (user && course) {
      props.unsavedProgress();
      save = setTimeout(() => {
        props.saveUserProgress(
          props.match.params.id,
          props.match.params.grouping,
          props.match.params.section
        );
      }, 1000);
    }

    if (parseInt(props.match.params.section) !== active) {
      setActive(parseInt(props.match.params.section));
    }

    window.scrollTo(0, 0);

    return () => clearTimeout(save);

    // eslint-disable-next-line
  }, [props.match.params.section]);

  if (
    user &&
    props.course.length &&
    props.course[1] &&
    !user.settings.tutorialComplete &&
    !showTutorial &&
    parseInt(active) !== parseInt(props.course[1].section)
  ) {
    setShowTutorial(true);
  } else if (user && user.settings.tutorialComplete && showTutorial) {
    setShowTutorial(false);
  }

  if (user && user.courses.find((e) => e.course === props.match.params.id)) {
    const userCourseProgress = user.courses.find(
      (e) => e.course === props.match.params.id
    ).progress;

    if (
      userCourseProgress === '100' &&
      !user.completedCourses.includes(props.match.params.id)
    ) {
      props.setCourseCompletion(props.match.params.id);
      ReactGA.event({
        category: `Course`,
        action: 'Completion',
        label: props.match.params.id,
        transport: 'beacon',
      });
    }
  }

  const onBackArrowClick = () => {
    history.push(`/course/${course.id}`);
  };

  const onProgressButtonClick = (section) => {
    const userHighestSection = user.courses.find(
      (el) => el.course === course.id
    ).highestSection;

    if (parseInt(section) <= userHighestSection) {
      history.push(`/course/live/${course.id}/${course.grouping}/${section}`);
      setActive(parseInt(section));
    }
  };

  const onCloseTutorial = () => {
    props.manageSetting(user._id, 'tutorialComplete', true);
  };

  const renderProgressSaved = () => {
    if (saved) {
      return (
        <>
          <div style={{ fontSize: '12px', color: '#999999' }}>
            <i className='check icon' style={{ color: '#5DC080' }}></i>
            Progress saved
          </div>
        </>
      );
    }
    return (
      <>
        <div className='ui active inline mini loader'></div>
      </>
    );
  };

  const renderTitle = () => {
    let length;

    if (window.innerWidth < 350) {
      length = 20;
    } else {
      length = 40;
    }

    if (course.unitTitle.length > length) {
      return `${course.unitTitle.slice(0, length - 3)}...`;
    } else {
      return course.unitTitle;
    }
  };

  const renderSubtitle = () => {
    let length;

    if (window.innerWidth < 350) {
      length = 20;
    } else {
      length = 50;
    }
    if (course.subtitle.length > length) {
      return `${course.subtitle.slice(0, length - 3)}...`;
    } else {
      return course.subtitle;
    }
  };

  const renderProgress = () => {
    const displaySection = fullSection
      .slice(1)
      .sort((a, b) => parseInt(a.section) - parseInt(b.section));
    const width = 100 / (displaySection.length + 2);

    let userHighestSection = props.match.params.section;

    if (user.courses.find((el) => el.course === course.id)) {
      userHighestSection = user.courses.find(
        (el) => el.course === course.id
      ).highestSection;
    }

    const renderColour = (section) => {
      if (parseInt(section.section) === active) {
        return 'active';
      } else if (parseInt(section.section) <= userHighestSection) {
        return 'complete';
      } else {
        return;
      }
    };

    return displaySection.map((section) => {
      return (
        <div
          style={{
            width: `${width}%`,
          }}
          className={`course-progress-item ${renderColour(section)} button`}
          key={displaySection.indexOf(section)}
          onClick={() => onProgressButtonClick(section.section)}
        >
          {parseInt(section.section) <= userHighestSection &&
          parseInt(section.section) !== active ? (
            <i
              className='tiny check icon'
              style={{ margin: '0', color: '#338450' }}
            />
          ) : (
            <i className='tiny cross icon' />
          )}
        </div>
      );
    });
  };

  const renderCourseDetails = () => {
    return (
      <>
        <div className='mobile-only'>
          <span style={{ fontWeight: '700', fontSize: '1rem' }}>
            {renderTitle()}
          </span>
          <br />
          <span style={{ fontSize: '.8rem' }}>
            <p>{renderSubtitle()}</p>
          </span>
        </div>
        <div className='desktop-only' style={{ color: '#fff' }}>
          <span
            style={{ fontWeight: '700', fontSize: '1.6rem', lineHeight: '1.2' }}
          >
            {course.unitTitle}
          </span>
          <br />
          <span style={{ fontSize: '1rem' }}>
            <p>{course.subtitle}</p>
          </span>
        </div>
      </>
    );
  };

  const renderMobileHeader = () => {
    return (
      <div className='ui top fixed fluid secondary menu mobile-header'>
        <div className='item'>
          <BackArrow
            onClick={onBackArrowClick}
            notAbsolute={true}
            type='course'
          />
        </div>
        <div
          style={{
            verticalAlign: 'middle',
            margin: 'auto 0',
          }}
        >
          {renderCourseDetails()}
        </div>
        {/* <div className='item' style={{ padding: '0', fontWeight: '700' }}>
            <div className='header'>{course.unitTitle}</div>
          </div> */}
        <div className='right menu'>
          <div className='item'>
            <img className='logo-small' src={icon} alt='pyxium_logo' />
            <span
              className={`pyxy-header ${newPyxyValue > 1 ? 'active' : ''}`}
              style={{ marginLeft: '4px' }}
            >
              {props.user.pyxys.toLocaleString()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDesktopInstructor = () => {
    return (
      <>
        <div style={{ fontWeight: '700', fontSize: '1.6rem' }}>Created by</div>
        <img
          src={props.instructor.image}
          alt='instructor_pic'
          style={{ borderRadius: '16px', margin: '8px 0' }}
          className='ui tiny image'
        />

        <h4 style={{ margin: '0 0 8px 0' }}>
          {props.instructor.instructorName}
        </h4>
        <div style={{ marginBottom: '8px' }}>
          {props.instructor.instructorTagline}
        </div>
        <Link
          to={`/instructor/${props.instructor.instructorUrl}`}
          id='primary-course-button'
          className='ui mini button'
        >
          About instructor
        </Link>
      </>
    );
  };

  const renderTutorial = (type) => {
    let width;

    if (type === 'mobile') {
      width = '200px';
    } else {
      width = 'auto';
    }
    return (
      <div
        className='ui pointing helper-label label fade-in'
        style={{ width: `${width}` }}
      >
        You can click through completed sections using this navigation
        <i className='close icon' onClick={onCloseTutorial} />
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className='mobile-only'>
        <div className='live-course'>
          <div className='mobile-only'>{renderMobileHeader()}</div>
          <div className='course-progress-mobile centered'>
            {renderProgress()}
            {showTutorial ? renderTutorial('mobile') : ''}
          </div>

          <div style={{ marginTop: '84px' }}>
            <div style={{ marginBottom: '8px' }}>{renderProgressSaved()}</div>
            <Course section={props.match.params.section} />
          </div>
        </div>
      </div>
    );
  };

  const renderDesktop = () => {
    return (
      <div className='desktop-only desktop-course'>
        <div
          className='ui grid'
          style={{
            height: `${window.innerHeight - 58}px`,
            maxHeight: `${window.innerHeight - 58}px`,
            marginTop: '0',
          }}
        >
          <div
            className='four wide column'
            style={{ backgroundColor: '#6556ff', letterSpacing: '0.02em' }}
          >
            <div className='course-nav-bar'>
              <div style={{ height: '80px' }}>
                <div
                  onClick={onBackArrowClick}
                  style={{
                    cursor: 'pointer',
                    color: '#fff',
                    height: '22px',
                    width: '100px',
                  }}
                >
                  <BackArrow
                    onClick={onBackArrowClick}
                    top='40px'
                    left='2vw'
                    color='#fff'
                  />
                  <span
                    style={{
                      position: 'absolute',
                      top: '40px',
                      paddingLeft: `40px`,
                      height: '21px',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    Back
                  </span>
                </div>
              </div>
              {renderCourseDetails()}
            </div>
            <div className='course-nav-bar bottom'>
              {renderDesktopInstructor()}
            </div>
          </div>
          <div className='ten wide column' style={{ padding: '0' }}>
            <div className='course-progress-desktop centered'>
              {renderProgress()}

              {showTutorial ? renderTutorial('desktop') : ''}
            </div>
            <div style={{ margin: '64px 0 0 20px', position: 'absolute' }}>
              {renderProgressSaved()}
            </div>
            <div
              style={{
                padding: '84px 60px 0 60px',
                margin: 'auto',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Course section={props.match.params.section} />
            </div>
          </div>
          <div className='two wide column'></div>
        </div>
      </div>
    );
  };

  if (!user && error.code === 401) {
    return (
      <div>
        <div className='centered'>
          <div>
            <h1>Login to access</h1>
            <p>Please login to access this course</p>
          </div>
          <div style={{ marginTop: '16px' }}>
            <Link to='/login' id='secondary-button' className='ui button'>
              Login
            </Link>
          </div>
          <div style={{ marginTop: '8px' }}>
            <Link
              style={{ borderRadius: '50px' }}
              to='/courses'
              id='basic-secondary-button'
              className='ui basic button'
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
    );
  } else if (
    !props.course[0] ||
    props.match.params.id !== props.course[0].id ||
    props.match.params.grouping !== props.course[0].grouping
  ) {
    return (
      <div className='ui segment course'>
        <div className='ui active inverted dimmer'>
          <div className='ui loader'></div>
        </div>
      </div>
    );
  }
  return (
    <>
      {renderMobile()}
      {renderDesktop()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    course: state.course.course,
    instructor: state.course.instructor,
    user: state.user.user,
    courseStructure: state.course.courseStructure,
    saved: state.user.saved,
    error: state.error.error,
    errorDetail: state.error.errorDetail,
  };
};

export default connect(mapStateToProps, {
  fetchDevSection,
  setCourseCompletion,
  fetchStructure,
  saveUserProgress,
  unsavedProgress,
  manageSetting,
  resetErrors,
})(LiveCourse);
