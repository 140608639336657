import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import CardLoader from '../../widgets/CardLoader';

import { getCategories, getCategory, getItems } from '../../../actions';

import icon from '../../../../img/primary_icon.png';

const Marketplace = (props) => {
  const [noInterest, setNoInterest] = useState(false);

  useEffect(() => {
    props.getItems();
    //eslint-disable-next-line
  }, []);

  const items = props.items;
  const categoryRef = useRef([]);

  let allCategories = [];

  items.forEach((item) => {
    allCategories.push(item.category);
  });

  const uniqueCategories = [...new Set(allCategories)];

  const numToWord = [
    { number: 1, word: 'one' },
    { number: 2, word: 'two' },
    { number: 3, word: 'three' },
    { number: 4, word: 'four' },
    { number: 5, word: 'five' },
    { number: 6, word: 'six' },
    { number: 7, word: 'seven' },
    { number: 8, word: 'eight' },
    { number: 9, word: 'nine' },
    { number: 10, word: 'ten' },
  ];

  const categoryWordNumValue = numToWord.find(
    (el) => el.number === uniqueCategories.length
  )?.word;

  categoryRef.current = categoryRef.current.slice(
    0,
    uniqueCategories.length - 1
  );

  const renderItems = (category, type) => {
    if (items.length !== 0) {
      const categoryItems = items
        .filter((item) => item.category === category)
        .sort((a, b) => a.price - b.price);

      if (type === 'mobile') {
        return categoryItems.map((item, i) => {
          return (
            <Link
              to={`/product/${item._id}`}
              className='ui centered link card course-card'
              style={{ width: '95%' }}
              key={i * 10000}
            >
              <div
                id='course-card-image'
                className='image'
                style={{
                  backgroundColor: '#fff',
                }}
              >
                <img
                  src={item.image}
                  alt='product_image'
                  className='center'
                  style={{
                    height: '200px',
                    width: 'auto',
                    padding: '20px',
                  }}
                />
              </div>
              <div className='content' style={{ border: 'none' }}>
                <div className='header'>{item.title}</div>
                <div
                  className='description'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={icon}
                    style={{ width: '24px', marginRight: '8px' }}
                  />
                  <div
                    style={{
                      fontSize: '20px',
                      color: '#6556ff',
                      fontWeight: '700',
                    }}
                  >
                    {item.price}
                  </div>
                </div>
              </div>
            </Link>
          );
        });
      } else {
        return categoryItems.map((item, i) => {
          return (
            <div className='four wide column'>
              <Link
                to={`/product/${item._id}`}
                className='ui centered link card course-card'
                style={{ width: '95%' }}
                key={i * 10000}
              >
                <div
                  id='course-card-image'
                  className='image'
                  style={{
                    backgroundColor: '#fff',
                  }}
                >
                  <img
                    src={item.image}
                    alt='product_image'
                    className='center'
                    style={{
                      maxHeight: '200px',
                      width: 'auto',
                      maxWidth: '100%',
                      padding: '20px',
                    }}
                  />
                </div>
                <div className='content' style={{ border: 'none' }}>
                  <div className='header'>{item.title}</div>
                  <div
                    className='description'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={icon}
                      style={{ width: '24px', marginRight: '8px' }}
                    />
                    <div
                      style={{
                        fontSize: '20px',
                        color: '#6556ff',
                        fontWeight: '700',
                      }}
                    >
                      {item.price}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        });
      }
    }
    return (
      <div>
        <div className='centered'>
          <h3 className='empty-header' style={{ margin: '0 auto 24px' }}>
            {' '}
          </h3>
        </div>
        <div className='mobile-only'>
          <CardLoader
            width='sixteen'
            height='312px'
            style={{ margin: '0 auto' }}
          />
        </div>
        <div className='desktop-only'>
          <CardLoader
            width='four'
            height='312px'
            style={{ margin: '0 auto' }}
          />
        </div>
      </div>
    );
  };

  const renderCategories = () => {
    if (items.length !== 0) {
      return uniqueCategories.map((category, i) => {
        return (
          <div ref={(el) => (categoryRef.current[i] = el)} key={i * 1000000}>
            <div
              className='centered'
              style={{ marginBottom: '48px' }}
              key={i * 100}
            >
              <h3>{category}</h3>
              <div className='mobile-only'>
                {renderItems(category, 'mobile')}
              </div>
              <div className='desktop-only ui stretched grid'>
                {renderItems(category, 'desktop')}
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <>
        <div className='mobile-only'>
          <div>
            <h2
              className='empty-header one'
              style={{
                width: '100%',
                borderRadius: '50px',
                margin: '8px',
              }}
            >
              {' '}
            </h2>
            <h2
              className='empty-header two'
              style={{
                width: '100%',
                borderRadius: '50px',
                margin: '8px',
              }}
            >
              {' '}
            </h2>
            <h2
              className='empty-header three'
              style={{
                width: '100%',
                borderRadius: '50px',
                margin: '8px',
              }}
            >
              {' '}
            </h2>
            <h2
              className='empty-header four'
              style={{
                width: '100%',
                borderRadius: '50px',
                margin: '8px 0 24px',
              }}
            >
              {' '}
            </h2>
          </div>
        </div>
        <div className='desktop-only'>
          <div className={`ui four column grid`}>
            <div className='column'>
              <h2
                className='empty-header one'
                style={{
                  width: '100%',
                  borderRadius: '50px',
                  margin: '8px',
                }}
              >
                {' '}
              </h2>
            </div>
            <div className='column'>
              <h2
                className='empty-header two'
                style={{
                  width: '100%',
                  borderRadius: '50px',
                  margin: '8px',
                }}
              >
                {' '}
              </h2>
            </div>
            <div className='column'>
              <h2
                className='empty-header three'
                style={{
                  width: '100%',
                  borderRadius: '50px',
                  margin: '8px',
                }}
              >
                {' '}
              </h2>
            </div>
            <div className='column'>
              <h2
                className='empty-header four'
                style={{
                  width: '100%',
                  borderRadius: '50px',
                  margin: '8px',
                }}
              >
                {' '}
              </h2>
            </div>
          </div>
        </div>
        {renderItems()}
      </>
    );
  };

  const renderDesktopMenu = () => {
    return uniqueCategories.map((category, i) => {
      return (
        <>
          <div className='column' style={{ padding: '8px' }} key={i + 100000}>
            <div className='centered'>
              <div
                id='secondary-button'
                className='ui button'
                style={{ width: '100%' }}
                onClick={() =>
                  categoryRef.current[i].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }
              >
                {category}
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  const renderMobileMenu = () => {
    return uniqueCategories.map((category, i) => {
      return (
        <>
          <div
            className='sixteen wide column'
            style={{ padding: '8px' }}
            key={i}
          >
            <div className='centered'>
              <div
                id='secondary-button'
                className='ui button'
                style={{ width: '100%' }}
                onClick={() =>
                  categoryRef.current[i].scrollIntoView({
                    behavior: 'smooth',
                  })
                }
              >
                {category}
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  if (props.userNotFound) {
    return '';
  }

  let productSuggestionForumLink = '';

  if (props.user && props.user.isDemo) {
    productSuggestionForumLink = 'wWYkftnMXQ';
  } else {
    productSuggestionForumLink = 'd0hDYT3tIa';
  }

  return (
    <div className='course-text'>
      <div style={{ marginBottom: '24px' }}>
        <h1>Marketplace</h1>
        <div
          className='centered'
          style={{
            lineHeight: '1.4',
            maxWidth: '400px',
            margin: '2rem auto',
          }}
        >
          Spend your pyxies here! From books to gift cards, subscriptions and
          more, cash in your learning for something nice.
        </div>
        <div className='ui grid mobile-only'>{renderMobileMenu()}</div>
        <div className={`ui ${categoryWordNumValue} column grid desktop-only`}>
          {renderDesktopMenu()}
        </div>
        <div style={{ marginTop: '24px' }}>{renderCategories()}</div>
        <div className='ui accordion'>
          <div
            className='title text-link'
            onClick={() => setNoInterest(!noInterest)}
          >
            <span role='img' aria-label='thinking_face_emoji'>
              🤔
            </span>{' '}
            Nothing of interest?
          </div>
          <div
            className={`content ${
              noInterest ? 'active animating-accordion' : ''
            }`}
          >
            Let us know what you'd like to learn in our forum, and we'll look
            into it!
            <div style={{ marginTop: '8px' }}>
              <Link
                to={`/discuss/${productSuggestionForumLink}`}
                id='basic-button'
                className='ui basic  tiny button'
                style={{ borderRadius: '50px' }}
              >
                Take me there
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    marketCategories: state.market.marketCategories,
    category: state.market.category,
    isSignedIn: state.user.isSignedIn,
    pyxys: state.user.user.pyxys,
    user: state.user.user,
    items: state.market.items,
  };
};

export default connect(mapStateToProps, {
  getCategories,
  getCategory,
  getItems,
})(Marketplace);
