import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { validatePhoneNumber } from '../../actions';

const PhoneVerification = (props) => {
  const [verify, setVerify] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);

  const user = props.user;

  useEffect(() => {
    if (user && user.phone.isVerified) {
      setPhoneVerified(true);
    }
  }, [user]);

  const onVerifyChange = (e) => {
    setVerify(e.target.value);
  };

  const onVerifyClick = () => {
    props.validatePhoneNumber(verify);
    setVerifying(true);
  };

  if (user) {
    if (verifying && !phoneVerified) {
      return (
        <>
          <div className='ui active loader' />
          Verifying your number
        </>
      );
    } else if (phoneVerified) {
      return (
        <>
          <h3 style={{ color: '#36454f' }}>
            <i className='check circle icon' style={{ color: '#5DC080' }} />
            Phone verified!
          </h3>
          <Link
            to='/marketplace'
            id='secondary-button'
            className='ui button'
            style={{ marginTop: '16px' }}
          >
            Go to marketplace
          </Link>
        </>
      );
    }

    return (
      <>
        <h3>Phone verification</h3>
        Please enter the confirmation code you were sent below and press verify
        number
        <div style={{ margin: '16px 0', maxWidth: '400px' }}>
          <div className='field'>
            <div className='ui icon input'>
              <input
                className='primary-input'
                type='text'
                placeholder='Enter code'
                onChange={onVerifyChange}
                value={verify}
              />
              <i className='mobile alternate icon'></i>
            </div>
          </div>
        </div>
        <div
          id='secondary-button'
          className='ui button'
          style={{ marginTop: '16px' }}
          onClick={onVerifyClick}
        >
          Verify number
        </div>
      </>
    );
  }

  return (
    <>
      <h3>Phone verification</h3>
      Please login to verify your phone number
      <br />
      <Link
        to='/login'
        id='secondary-button'
        className='ui button'
        style={{ marginTop: '16px' }}
      >
        Login
      </Link>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, { validatePhoneNumber })(
  PhoneVerification
);
