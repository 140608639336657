import React, { useState } from 'react';
import { connect } from 'react-redux';

const Warning = (props) => {
  const [warningAccepted, setWarningAccepted] = useState(false);

  const warnings = props.course.warnings;

  const toggleWarning = () => {
    warningAccepted ? setWarningAccepted(false) : setWarningAccepted(true);
  };

  const renderWarnings = () => {
    return warnings.map((warning) => {
      return (
        <div className='ui list' key={warnings.indexOf(warning)}>
          <div className='centered item'>
            <div className='content'>{warning}</div>
          </div>
        </div>
      );
    });
  };

  const renderCheckbox = () => {
    return (
      <div className='field'>
        <div className='ui checkbox'>
          <input type='checkbox' name={`option 1`} onChange={toggleWarning} />
          <label>
            Please tick this box to accept these warnings and progress to the
            course.
          </label>
        </div>
      </div>
    );
  };

  if (warnings) {
    if (warnings.length !== 0) {
      return (
        <div className='course-text'>
          <div className='ui header centered red'>
            <i className='exclamation triangle icon'></i>Warning
          </div>
          <p>
            In this course we're going to discuss some sensitive topics,
            including:
          </p>
          {renderWarnings()}
          <p>
            If you feel you may find any of the above topic areas traumatising
            or triggering, you may not want to proceed with this course.
          </p>
          {renderCheckbox()}
          <div
            id='secondary-course-button'
            className={`ui button ${warningAccepted ? '' : 'disabled'}`}
            onClick={props.onContinue}
            style={{ marginTop: '16px', width: '100%' }}
          >
            Continue to course
          </div>
        </div>
      );
    }
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Warning);
