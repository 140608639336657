import React from 'react';
import { connect } from 'react-redux';

const CardLoader = (props) => {
  const width = props.width;
  const height = props.height;
  const overflow = props.overflow;

  if (overflow) {
    return (
      <div
        className='ui stretched grid x-overflow-desktop no-scrollbar'
        style={{ marginBottom: '24px' }}
      >
        <div className={`${width} wide column`}>
          <div
            className='ui card course-card empty one'
            style={{ height: `${height}` }}
          ></div>
        </div>
        <div className={`${width} wide column`}>
          <div
            className='ui card course-card empty two'
            style={{ height: `${height}` }}
          ></div>
        </div>
        <div className={`${width} wide column`}>
          <div
            className='ui card course-card empty three'
            style={{ height: `${height}` }}
          ></div>
        </div>
        <div className={`${width} wide column`}>
          <div
            className='ui card course-card empty four'
            style={{ height: `${height}` }}
          ></div>
        </div>
      </div>
    );
  }
  return (
    <div
      className='ui stretched grid no-scrollbar'
      style={{ marginBottom: '24px' }}
    >
      <div className={`${width} wide column`}>
        <div
          className='ui card course-card empty one'
          style={{ height: `${height}`, width: '100%' }}
        ></div>
      </div>
      <div className={`${width} wide column`}>
        <div
          className='ui card course-card empty two'
          style={{ height: `${height}`, width: '100%' }}
        ></div>
      </div>
      <div className={`${width} wide column`}>
        <div
          className='ui card course-card empty three'
          style={{ height: `${height}`, width: '100%' }}
        ></div>
      </div>
      <div className={`${width} wide column`}>
        <div
          className='ui card course-card empty four'
          style={{ height: `${height}`, width: '100%' }}
        ></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps)(CardLoader);
