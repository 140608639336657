import React, { useState } from 'react';
import { connect } from 'react-redux';

import { CSSTransition } from 'react-transition-group';

import { processParticipant } from '../../actions/index';

const Follow = (props) => {
  const [mouseEnter, setMouseEnter] = useState(false);

  const user = props.user;
  const forum = props.forum;
  const place = props.place;
  const size = props.size;

  const onUnfollowClick = (forum) => {
    props.processParticipant(forum._id, user._id, place, 'remove');
  };

  const onFollowClick = (forum) => {
    props.processParticipant(forum._id, user._id, place, 'add');
  };

  if (user) {
    if (forum.participants.includes(user._id)) {
      return (
        <button
          className={`ui ${size} label follow-button`}
          style={{
            color: '#ffffff',
            backgroundColor: '#6556FF',
            borderRadius: '50px',
          }}
          onClick={() => onUnfollowClick(forum)}
          onMouseEnter={() => setMouseEnter(true)}
          onMouseLeave={() => setMouseEnter(false)}
        >
          <CSSTransition
            in={mouseEnter}
            timeout={300}
            classNames='fade-transition'
          >
            <span>{mouseEnter ? 'Unfollow?' : 'Following'}</span>
          </CSSTransition>
        </button>
      );
    }
    return (
      <button
        id='basic-secondary-button'
        style={{ borderRadius: '50px' }}
        className={`ui ${size} basic label follow-button`}
        onClick={() => onFollowClick(forum)}
      >
        Follow
      </button>
    );
  }
  return '';
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  processParticipant,
})(Follow);
