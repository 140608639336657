import React from 'react';

import AllForums from './AllForums';

const ForumTopic = (props) => {
  return (
    <>
      <AllForums topic={props.match.params.id} />
    </>
  );
};

export default ForumTopic;
