import React from 'react';
import { connect } from 'react-redux';

const Description = (props) => {
  const course = props.course;
  return (
    <div>
      <p className='centered'>By {course.ownerName}</p>
      <img
        src={course.image}
        className='course-image'
        alt='course_title_image'
      />
      <p>{course.description}</p>
      <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Description);
