import actions from '../actions/types';

export default (state = { forums: [] }, action) => {
  switch (action.type) {
    case actions.GET_FORUMS:
      return {
        ...state,
        forums: action.payload.forums,
      };
    case actions.GET_FORUM:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.CREATE_FORUM:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.SET_FORUM_SCORE:
      return {
        ...state,
        forums: action.payload.forums,
        forum: action.payload.forum,
      };
    case actions.SET_COMMENT_SCORE:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.ADD_COMMENT:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.ADD_REPLY:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.EDIT_FORUM:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.EDIT_COMMENT:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.REMOVE_COMMENT:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.ADD_PARTICIPANT:
      if (action.payload.forums) {
        return {
          ...state,
          forums: action.payload.forums,
        };
      } else if (action.payload.forum) {
        return {
          ...state,
          forum: action.payload.forum,
        };
      } else {
        break;
      }
    case actions.CLOSE_FORUM:
      return {
        ...state,
        forum: action.payload.forum,
      };
    case actions.DELETE_FORUM:
      return {
        ...state,
      };
    case actions.GET_COURSE_TITLES:
      return {
        ...state,
        courses: action.payload.courses,
      };
    default:
      return {
        ...state,
      };
  }
};
