// import Content from './Content';
import Description from './course-parts/Description';
import InputQuestion from './course-parts/InputQuestion';
import CheckboxQuestion from './course-parts/CheckboxQuestion';
import SectionIntro from './course-parts/SectionIntro';
import Sections from './course-parts/Sections';
import SubSection from './course-parts/SubSection';
import Definitions from './course-parts/Definitions';
import Title from './course-parts/Title';
import Examples from './course-parts/Examples';
import End from './course-parts/End';
import Progress from './course-parts/Progress';
import Warning from './course-parts/Warning';

export default {
  Description,
  SectionIntro,
  Sections,
  SubSection,
  Definitions,
  Title,
  Examples,
  InputQuestion,
  CheckboxQuestion,
  End,
  Progress,
  Warning,
};
