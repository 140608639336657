import React, { useState } from 'react';

const Pagination = (props) => {
  const [active, setActive] = useState(1);

  let pages = [];
  const items = props.items;
  const perPage = props.perPage;
  const totalPages = Math.ceil(items / perPage);
  const type = props.type;

  if (props.overrideActive && props.overrideActive !== active) {
    setActive(props.overrideActive);
  }

  for (let i = 0; i < totalPages; i++) {
    pages.push(i + 1);
  }

  if (type === 'computer') {
    if (pages.length > 5) {
      if (active < 3) {
        pages = [1, 2, 3, 4, 5];
      } else {
        if (active === totalPages) {
          pages = [active - 4, active - 3, active - 2, active - 1, active];
        } else if (active + 1 === totalPages) {
          pages = [active - 3, active - 2, active - 1, active, active + 1];
        } else {
          pages = [active - 2, active - 1, active - 0, active + 1, active + 2];
        }
      }
    }
  } else {
    if (pages.length > 2) {
      if (active < 2) {
        pages = [1, 2];
      } else {
        if (active === totalPages) {
          pages = [active - 1, active];
        } else {
          pages = [active, active + 1];
        }
      }
    }
  }

  const onNumberClick = (page) => {
    setActive(page);
    props.setActive(page);
  };

  const renderLeftButtons = () => {
    if (active === 1) {
      return (
        <>
          {/* eslint-disable-next-line */}
          <a className='disabled item'>
            <i className='angle double left icon' />
          </a>
          {/* eslint-disable-next-line */}
          <a className='disabled item'>
            <i className='angle left icon' />
          </a>
        </>
      );
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        <a className='item' onClick={() => onNumberClick(1)}>
          <i className='angle double left icon' />
        </a>
        {/* eslint-disable-next-line */}
        <a className='item' onClick={() => onNumberClick(active - 1)}>
          <i className='angle left icon' />
        </a>
      </>
    );
  };

  const renderRightButtons = () => {
    if (active === totalPages) {
      return (
        <>
          {/* eslint-disable-next-line */}
          <a className='disabled item'>
            <i className='angle right icon' />
          </a>
          {/* eslint-disable-next-line */}
          <a className='disabled item'>
            <i className='angle double right icon' />
          </a>
        </>
      );
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        <a className='item' onClick={() => onNumberClick(active + 1)}>
          <i className='angle right icon' />
        </a>
        {/* eslint-disable-next-line */}
        <a className='item' onClick={() => onNumberClick(totalPages)}>
          <i className='angle double right icon' />
        </a>
      </>
    );
  };

  const renderMenu = () => {
    return pages.map((page) => {
      return (
        <div key={pages.indexOf(page)}>
          {/* eslint-disable-next-line */}
          <a
            className={page === active ? 'active item' : 'item'}
            onClick={() => onNumberClick(page)}
          >
            {page}
          </a>
        </div>
      );
    });
  };

  return (
    <>
      <div className='ui pagination menu'>
        {renderLeftButtons()}
        {renderMenu()}
        {renderRightButtons()}
      </div>
    </>
  );
};

export default Pagination;
