import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactGA from 'react-ga';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import BackArrow from '../../widgets/BackArrow';

import history from '../../../history';

import {
  getItem,
  spendPyxys,
  sendVerificationText,
  validatePhoneNumber,
} from '../../../actions';

import icon from '../../../../img/primary_icon.png';

const Product = (props) => {
  const [term, setTerm] = useState('');
  const [verify, setVerify] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [errors, setErrors] = useState([]);
  const [item, setItem] = useState({});
  const [phoneExplanation, setPhoneExplanation] = useState(false);
  const [inputPhone, setInputPhone] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');
  const [town, setTown] = useState('');
  const [county, setCounty] = useState('');
  const [postcode, setPostcode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const id = props.match.params.id;

  const user = props.user;
  const items = props.items;

  const ref = useRef();

  useEffect(() => {
    if (user && user.phone.isVerified && verifying) {
      setPhoneVerified(true);
    }
  }, [user]);

  useEffect(() => {
    if (items.length === 0) {
      props.getItem(id, user);
    }

    if (user) {
      setTerm(user.email);
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (items.length !== 0) {
      setItem(items.find((item) => item._id === id));
    } else {
      setItem(props.item);
    }
  }, [props.item]);

  useEffect(() => {
    if (showDetails) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showDetails]);

  const onInputChange = (e) => {
    setTerm(e.target.value);
  };

  const onVerifyChange = (e) => {
    setVerify(e.target.value);
  };

  const onAddressInputChange = (e, field) => {
    const value = e.target.value;
    switch (field) {
      case 'first':
        setFirstName(value);
        setErrors(errors.filter((error) => error.type !== 'first'));
        break;
      case 'last':
        setLastName(value);
        setErrors(errors.filter((error) => error.type !== 'last'));
        break;
      case 'address1':
        setLineOne(value);
        setErrors(errors.filter((error) => error.type !== 'lineone'));
        break;
      case 'address2':
        setLineTwo(value);
        break;
      case 'town':
        setTown(value);
        setErrors(errors.filter((error) => error.type !== 'town'));
        break;
      case 'county':
        setCounty(value);
        setErrors(errors.filter((error) => error.type !== 'county'));
        break;
      case 'postcode':
        setPostcode(value);
        setErrors(errors.filter((error) => error.type !== 'postcode'));
        break;
    }
  };

  const onCloseProduct = () => {
    history.goBack();
  };

  const onOrder = (type) => {
    if (type === 'email') {
      props.spendPyxys(item.price, id, term);
    } else {
      props.spendPyxys(
        item.price,
        id,
        `Address details: ${firstName} ${lastName}, ${lineOne}, ${lineTwo}, ${town}, ${county}, ${postcode}`
      );
    }

    ReactGA.event({
      category: `Marketplace`,
      action: 'Order',
      label: item.title,
      transport: 'beacon',
    });
  };

  const onConfirmClick = (type) => {
    onOrder(type);
    history.push('/confirmation');
  };

  const onContinueClick = (type) => {
    if (type === 'verified') {
      setPhoneVerified(false);
      setVerifying(false);
    } else if (type === 'validation') {
      setVerifying(true);
      props.validatePhoneNumber(verify);
      setShowDetails(false);
      setInputPhone(false);
      setPhoneValidation(false);
    } else if (type === 'phone') {
      props.sendVerificationText(phoneNumber);
      setPhoneValidation(!phoneValidation);
      setInputPhone(false);
    } else if (type === 'email') {
      if (validator.isEmail(term)) {
        setErrors([]);
        setConfirm(true);
      } else {
        setErrors([{ type: 'email', error: 'Please enter a valid email' }]);
      }
    } else {
      let collectingErrors = [];
      if (firstName === '') {
        collectingErrors = [
          ...collectingErrors,
          { type: 'first', error: 'Please enter a first name' },
        ];
      }
      if (lastName === '') {
        collectingErrors = [
          ...collectingErrors,
          { type: 'last', error: 'Please enter a last name' },
        ];
      }
      if (lineOne === '') {
        collectingErrors = [
          ...collectingErrors,
          { type: 'lineone', error: 'Please enter an address' },
        ];
      }
      if (town === '') {
        collectingErrors = [
          ...collectingErrors,
          { type: 'town', error: 'Please enter a town/city' },
        ];
      }
      if (county === '') {
        collectingErrors = [
          ...collectingErrors,
          { type: 'county', error: 'Please enter a county' },
        ];
      }
      if (!validator.isPostalCode(postcode, 'GB')) {
        collectingErrors = [
          ...collectingErrors,
          { type: 'postcode', error: 'Please enter a valid postcode' },
        ];
      }
      if (collectingErrors.length !== 0) {
        setErrors(collectingErrors);
      } else {
        setConfirm(true);
      }
    }
  };
  const renderContinuePurchase = (type) => {
    return (
      <>
        <div
          id='secondary-button'
          className='ui big button'
          style={{ margin: '8px 0 0', borderRadius: '50px' }}
          onClick={() => onContinueClick(type)}
        >
          Continue
        </div>
        <br />
        <div
          id='basic-secondary-button'
          className='ui basic button'
          style={{ margin: '8px 0 0', borderRadius: '50px' }}
          onClick={() => {
            setShowDetails(false);
            setInputPhone(false);
            setPhoneValidation(false);
          }}
        >
          Cancel
        </div>
      </>
    );
  };

  const renderErrorMessage = (type) => {
    if (errors.some((error) => error.type === type)) {
      return (
        <div
          className='ui pointing label'
          style={{
            backgroundColor: '#E56B6F',
            color: '#fff',
            borderRadius: '50px',
          }}
        >
          <div className='header'>
            {errors.find((error) => error.type === type).error}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderDetails = () => {
    if (verifying && !phoneVerified) {
      return (
        <>
          <div className='ui active loader' />
          Verifying your number
        </>
      );
    } else if (phoneVerified) {
      return (
        <>
          <h3 style={{ color: '#36454f' }}>
            <i className='check circle icon' style={{ color: '#5DC080' }} />
            <br />
            Phone verified!
          </h3>

          {renderContinuePurchase('verified')}
        </>
      );
    } else if (inputPhone && !phoneValidation) {
      return (
        <>
          <h3>Please enter your phone number</h3>
          <PhoneInput
            style={{ maxWidth: '300px', margin: '0 auto' }}
            inputClass='primary-input'
            inputStyle={{ marginLeft: '0px' }}
            buttonClass='phone-flag'
            dropdownClass='animating slide down in visible transition primary-input-large'
            countryCodeEditable={false}
            placeholder='Enter phone number'
            country={'gb'}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />

          {renderContinuePurchase('phone')}
        </>
      );
    } else if (phoneValidation) {
      if (phoneNumber.slice(0, 2) === '44') {
        return (
          <>
            <h3>Confirm phone</h3>
            <p>
              We've just sent a confirmation code to <b>+{phoneNumber}</b> -
              please enter it below!
            </p>
            <div style={{ margin: '0 auto 16px', maxWidth: '400px' }}>
              <div className='field'>
                <div className='ui icon input'>
                  <input
                    className='primary-input'
                    type='text'
                    placeholder='Enter code'
                    onChange={onVerifyChange}
                    value={verify}
                  />
                  <i className='mobile alternate icon'></i>
                </div>
              </div>
            </div>

            {renderContinuePurchase('validation')}
          </>
        );
      } else {
        return (
          <>
            <h3>Confirm phone</h3>
            <p style={{ maxWidth: '400px', margin: '0 auto' }}>
              We'll send you a text within the next 24 hours on how to verify
              your phone number. Until then, keep learning and earning!
            </p>
          </>
        );
      }
    } else if (showDetails) {
      if (confirm) {
        return (
          <>
            <h3>Please confirm purchase</h3>
            <div style={{ fontSize: '16px', marginBottom: '24px' }}>
              {item.requiredDetails.category === 'email' ? (
                <>
                  <div style={{ fontWeight: '700', marginBottom: '8px' }}>
                    Email:
                  </div>
                  <div>
                    {term}{' '}
                    <i
                      className='link edit icon'
                      style={{ color: '#6556ff' }}
                      onClick={() => setConfirm(false)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div style={{ fontWeight: '700', marginBottom: '8px' }}>
                    Address:
                  </div>
                  <div>
                    {firstName} {lastName},
                    <br />
                    {lineOne},
                    <br />
                    {lineTwo !== '' ? (
                      <>
                        {lineTwo},
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    {town},
                    <br />
                    {county},
                    <br />
                    {postcode}{' '}
                    <i
                      className='link edit icon'
                      style={{ color: '#6556ff' }}
                      onClick={() => setConfirm(false)}
                    />
                  </div>
                </>
              )}
            </div>
            <div
              id='secondary-button'
              className='ui big button purchase-button'
              style={{ margin: '8px 0 0', borderRadius: '50px' }}
              onClick={() => onConfirmClick(item.requiredDetails.category)}
            >
              Confirm purchase
            </div>
            <br />
            <div
              id='basic-secondary-button'
              className='ui basic button'
              style={{ margin: '8px 0 0', borderRadius: '50px' }}
              onClick={() => setShowDetails(!showDetails)}
            >
              Cancel
            </div>
          </>
        );
      } else if (item.requiredDetails.category === 'email') {
        return (
          <>
            <h3>Please provide an email for us to send further details</h3>
            <div style={{ margin: '0 auto 16px', maxWidth: '400px' }}>
              <div className='field'>
                <div className='ui icon input'>
                  <input
                    className='primary-input'
                    type='text'
                    placeholder='Enter email address'
                    onChange={onInputChange}
                    value={term}
                  />
                  <i className='mail icon'></i>
                </div>
                {renderErrorMessage('email')}
              </div>
            </div>
            {renderContinuePurchase('email')}
          </>
        );
      } else if (item.requiredDetails.category === 'address') {
        return (
          <>
            <h3>Please provide a UK address to send this product to</h3>
            <div style={{ margin: '0 auto 16px', maxWidth: '400px' }}>
              <div className='field'>
                <div className='ui two column grid'>
                  <div className='column' style={{ padding: '1rem 4px 0 0' }}>
                    <div className='ui input' style={{ maxWidth: '100%' }}>
                      <input
                        className='primary-input'
                        type='text'
                        placeholder='First name *'
                        onChange={(e) => onAddressInputChange(e, 'first')}
                        value={firstName}
                      />
                    </div>
                    {renderErrorMessage('first')}
                  </div>
                  <div className='column' style={{ padding: '1rem 0 0 4px' }}>
                    <div className='ui input' style={{ maxWidth: '100%' }}>
                      <input
                        className='primary-input'
                        type='text'
                        placeholder='Last name *'
                        onChange={(e) => onAddressInputChange(e, 'last')}
                        value={lastName}
                      />
                    </div>
                    {renderErrorMessage('last')}
                  </div>
                </div>
                <div className='ui grid'>
                  <div className='column' style={{ padding: '1rem 0 0 0' }}>
                    <div className='ui input' style={{ width: '100%' }}>
                      <input
                        className='primary-input'
                        type='text'
                        placeholder='Address Line 1 *'
                        onChange={(e) => onAddressInputChange(e, 'address1')}
                        value={lineOne}
                      />
                    </div>
                    {renderErrorMessage('lineone')}
                  </div>
                </div>
                <div className='ui grid'>
                  <div className='column' style={{ padding: '1rem 0 0 0' }}>
                    <div className='ui input' style={{ width: '100%' }}>
                      <input
                        className='primary-input'
                        type='text'
                        placeholder='Address Line 2'
                        onChange={(e) => onAddressInputChange(e, 'address2')}
                        value={lineTwo}
                      />
                    </div>
                  </div>
                </div>
                <div className='ui two column grid'>
                  <div className='column' style={{ padding: '1rem 4px 0 0' }}>
                    <div className='ui input' style={{ maxWidth: '100%' }}>
                      <input
                        className='primary-input'
                        type='text'
                        placeholder='Town/city *'
                        onChange={(e) => onAddressInputChange(e, 'town')}
                        value={town}
                      />
                    </div>
                    {renderErrorMessage('town')}
                  </div>
                  <div className='column' style={{ padding: '1rem 0 0 4px' }}>
                    <div className='ui input' style={{ maxWidth: '100%' }}>
                      <input
                        className='primary-input'
                        type='text'
                        placeholder='County *'
                        onChange={(e) => onAddressInputChange(e, 'county')}
                        value={county}
                      />
                    </div>
                    {renderErrorMessage('county')}
                  </div>
                </div>
                <div className='ui two column grid'>
                  <div className='column' style={{ padding: '1rem 4px 0 0' }}>
                    <div className='ui input' style={{ maxWidth: '100%' }}>
                      <input
                        className='primary-input'
                        type='text'
                        placeholder='Postcode *'
                        onChange={(e) => onAddressInputChange(e, 'postcode')}
                        value={postcode}
                      />
                    </div>
                    {renderErrorMessage('postcode')}
                  </div>
                </div>
                {errors.some((error) => error.type === 'email') ? (
                  <div className='ui error message'>
                    <div className='header'>
                      {errors.find((error) => error.type === 'email').error}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {renderContinuePurchase('address')}
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  const renderButton = () => {
    if (!props.isSignedIn) {
      return (
        <Link
          to='/login'
          id='secondary-button'
          className='ui big button'
          style={{ margin: '8px 0 0', borderRadius: '50px' }}
        >
          <i className='lock icon'></i>Login to purchase
        </Link>
      );
    } else if (parseInt(user.pyxys) < parseInt(item.price)) {
      return (
        <Link
          to='/courses'
          id='secondary-button'
          className='ui big disabled button'
          style={{ margin: '8px 0 0', borderRadius: '50px' }}
        >
          <i className='lock icon'></i>Earn more pyxies to purchase
        </Link>
      );
    } else if (
      !user.phone.isVerified &&
      !inputPhone &&
      !phoneValidation &&
      !verifying
    ) {
      return (
        <>
          <div
            id='secondary-button'
            className='ui button'
            style={{ margin: '8px 0 0', borderRadius: '50px' }}
            onClick={() => {
              setInputPhone(true);
              setPhoneExplanation(false);
            }}
          >
            Verify phone number to purchase
          </div>
          <div
            className='text-link'
            style={{ marginTop: '16px' }}
            onClick={() => setPhoneExplanation(!phoneExplanation)}
          >
            Why do you need my number?
            {phoneExplanation ? (
              <i className='angle up icon' />
            ) : (
              <i className='angle down icon' />
            )}
          </div>
        </>
      );
    } else {
      if (
        !showDetails &&
        !inputPhone &&
        !phoneValidation &&
        !verifying &&
        !phoneVerified
      ) {
        return (
          <div
            id='secondary-button'
            className='ui big button purchase-button'
            style={{ margin: '8px 0 0', borderRadius: '50px' }}
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          >
            Purchase
          </div>
        );
      }
      return <></>;
    }
  };

  const renderBasicInfo = () => {
    return (
      <div className='course-text'>
        <div className='image' style={{ marginBottom: '24px' }}>
          <img
            src={item.image}
            alt='product_image'
            style={{ height: '200px' }}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <h2>{item.title}</h2>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <img src={icon} style={{ width: '24px', marginRight: '8px' }} />
          <div
            style={{ fontSize: '20px', color: '#6556ff', fontWeight: '700' }}
          >
            {item.price}
          </div>
        </div>
        <div>
          <p>{item.details}</p>
        </div>
      </div>
    );
  };

  const renderPhoneExplanation = () => {
    if (phoneExplanation) {
      return (
        <div
          className='top pointing ui label helper-label fade-in'
          style={{ maxWidth: '300px', padding: '16px' }}
        >
          We only need your phone number to send you a verification message.
          Once we've uniquely verified you, you won't need to provide it again.
          We won't ever contact you by phone except to verify your identity, or
          share your number with third party organisations.
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div style={{ height: `${window.innerHeight - 98}px` }}>
      <div className='slide-up no-scrollbar'>
        <div style={{ padding: '20px' }} className='centered'>
          <BackArrow onClick={onCloseProduct} left='20px' top='20px' />
          <div className='mobile-only'>{renderBasicInfo()}</div>
          <div style={{ marginTop: '40px' }} className='desktop-only'>
            {renderBasicInfo()}
          </div>
          <div>{renderButton()}</div>
          <div>{renderPhoneExplanation()}</div>
          <div style={{ marginTop: '24px' }}>{renderDetails()}</div>
        </div>
        <div ref={ref}></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    item: state.market.item,
    items: state.market.items,
    user: state.user.user,
    isSignedIn: state.user.isSignedIn,
  };
};

export default connect(mapStateToProps, {
  getItem,
  spendPyxys,
  sendVerificationText,
  validatePhoneNumber,
})(Product);
