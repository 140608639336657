import React from 'react';
import { connect } from 'react-redux';

import { resetErrors } from '../../actions';

import history from '../../history';

const Error = (props) => {
  const onClick = () => {
    props.resetErrors();
    history.push('/courses');
  };

  return (
    <div className='centered'>
      <p>Whoops! Something seems to have gone wrong.</p>
      <button className='ui primary button' onClick={onClick}>
        Go to homepage
      </button>
    </div>
  );
};

export default connect(null, { resetErrors })(Error);
