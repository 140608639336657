import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';
import ReactGA from 'react-ga';

import { fetchInstructor, fetchCourses } from '../../actions'

import CourseList from './courses/CourseList'

const AboutInstructor = (props) => {
  const user = props.user;

  useEffect(() => {
    props.fetchInstructor(props.match.params.id);
    props.fetchCourses(user);

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const instructor = props.instructor;

  if (instructor && instructor.instructorUrl === props.match.params.id) {
    const renderInstructor = (type) => {
      let maxWidth, maxHeight;
      if (type === 'mobile') {
        maxWidth = '125px';
        maxHeight = '125px';
      } else {
        maxWidth = '200px';
        maxHeight = '200px';
      }
      return (
        <div style={{ marginTop: '16px' }}>
          <div className='ui unstackable items'>
            <div className='item'>
              <img
                src={instructor.image}
                alt='instructor_pic'
                style={{
                  borderRadius: '16px',
                  maxWidth,
                  maxHeight,
                }}
                className='ui image'
              />
              <div className='middle aligned content'>
                <div className='header'>
                  <h3>{instructor.instructorName}</h3>
                </div>
                <div className='meta' style={{ lineHeight: '1.2' }}>
                  {instructor.instructorTagline}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const renderSocials = (type) => {
      const icons = [
        'facebook',
        'twitter',
        'instagram',
        'linkedin',
        'youtube',
        'medium',
        'reddit',
      ];

      let width;

      if (type === 'mobile') {
        width = '120';
      } else {
        width = '160';
      }

      const renderStyle = (title) => {
        return icons.includes(title) ? title : '';
      };

      const onInstructorLinkClick = (link) => {
        ReactGA.event({
          category: `Instructor event`,
          action: `Link click for ${instructor.instructorUrl}`,
          label: `${link}`,
          transport: 'beacon',
        });
      };

      return instructor.instructorLinks.map((link) => {
        return (
          <div
            key={link._id}
            className={
              instructor.instructorLinks.indexOf(link) === 0
                ? `first social-link-${type}`
                : `social-link-${type}`
            }
          >
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={link.link}
              className={`ui ${renderStyle(link.title)} small button`}
              style={{
                margin: '0 4px 0 0',
                width: `${width}px`,
                borderRadius: '50px',

                backgroundColor: `${
                  !icons.includes(link.title) ? '#6556ff' : ''
                }`,
                color: `${!icons.includes(link.title) ? '#fff' : ''}`,
              }}
              onClick={(el) => onInstructorLinkClick(link.title)}
            >
              <i
                className={`ui ${
                  icons.includes(link.title)
                    ? `${link.title} brand-icon`
                    : 'linkify'
                } icon`}
              ></i>
              {link.title}
            </a>
          </div>
        );
      });
    };

    const courses = props.courses;

    const renderCourses = () => {
      const sortedCourses = courses.sort((a, b) => a.comingSoon - b.comingSoon);

      return (
        <div>
          <CourseList courses={sortedCourses} />
        </div>
      );
    };

    const renderMobile = () => {
      return (
        <>
          <h3>My links</h3>
          <div
            className='ui stretched grid x-overflow no-scrollbar'
            style={{ margin: '0 -1rem ' }}
          >
            {renderSocials('mobile')}
          </div>
          <h3>Bio</h3>
          <div className='item' style={{ margin: '0' }}>
            <div
              className='description course-text'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(instructor.instructorBio),
              }}
            ></div>
          </div>
        </>
      );
    };

    const renderDesktop = () => {
      return (
        <>
          <div
            className='ui grid'
            style={{ marginTop: 'calc(2rem - .14285714em)' }}
          >
            <div className='nine wide column'>
              <h3>Bio</h3>
              <div className='item' style={{ margin: '0' }}>
                <div
                  className='description course-text'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(instructor.instructorBio),
                  }}
                ></div>
              </div>
            </div>
            <div className='column'></div>
            <div className='six wide column'>
              <h3>My Links</h3>
              {renderSocials('desktop')}
            </div>
          </div>
        </>
      );
    };

    return (
      <div className='ui items'>
        <div className='mobile-only item'>{renderInstructor('mobile')}</div>
        <div className='desktop-only item'>{renderInstructor('desktop')}</div>
        <div className='mobile-only'>{renderMobile()}</div>
        <div className='desktop-only'>{renderDesktop()}</div>

        <h3>My courses</h3>
        {renderCourses()}
      </div>
    );
  }

  return (
    <div className='ui segment course'>
      <div className='ui active inverted dimmer'>
        <div className='ui loader'></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    instructor: state.user.instructor,
    courses: state.user.instructorCourses,
    user: state.user.user,
    isSignedIn: state.user.isSignedIn,
    allCourses: state.courses.courses,
  };
};

export default connect(mapStateToProps, { fetchInstructor, fetchCourses })(
  AboutInstructor
);
