import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import DOMPurify from 'dompurify';

import Explanation from './Explanation';
import SkipMessage from './SkipMessage';

import {
  setAnswer,
  fetchSection,
  addIncorrectAnswer,
  trackAnswer,
  getQuestion,
} from '../../../actions';

const InputQuestion = (props) => {
  const [showSkip, setShowSkip] = useState(false);
  const course = props.course;
  const user = props.user;
  const answer = props.answer;
  let term = props.term;
  const question = props.course.question;
  const validation = props.validation;
  const canAnswer = props.canAnswer.canAnswer;
  const isQuiz = props.isQuiz;
  const section = props.section;

  let validate;

  const ref = useRef();
  const skipRef = useRef();

  useEffect(() => {
    props.getQuestion(user._id, course._id);
  }, [section]);

  useEffect(() => {
    if (ref.current) {
      ref.current.blur();
    }
  }, [answer]);

  useEffect(() => {
    const onBodyClick = (e) => {
      if (skipRef.current && skipRef.current.contains(e.target)) {
        return;
      } else {
        setShowSkip(false);
      }
    };
    document.body.addEventListener('click', onBodyClick);
    return () => document.body.removeEventListener('click', onBodyClick);
  }, [question]);

  if (question.type === 'input') {
    const onInputChange = (e) => {
      props.currentTerm(e.target.value);
    };

    const trackAnswer = (correct, answer) => {
      if (canAnswer) {
        props.trackAnswer(user._id, course._id, correct, answer);
      }
    };

    const onSubmit = (e) => {
      e.preventDefault();
      if (term.length !== 0) {
        props.setAnswer(term[0].toLowerCase().replace(/ /g, ''));
      }

      const refactored = term[0].toLowerCase().replace(/ /g, '');

      if (user) {
        validate = question.correctAnswers.find((e) => e === refactored);
        if (validate) {
          if (!validation) {
            props.onValidation(true);
          }
          trackAnswer(true, refactored);
        } else {
          if (validation) {
            props.onValidation(false);
          }

          trackAnswer(false, refactored);
        }
        if (props.trackAnswered) {
          props.trackAnswered(course._id, validate);
        }
      }
    };

    const onSkip = () => {
      if (!isQuiz) {
        if (user.settings.acceptSkip) {
          trackAnswer(false, 'skipped');
          props.onContinue();
        } else {
          setShowSkip(true);
        }
      } else {
        props.quizSkip();
      }
    };

    const onSkipClose = (val) => {
      if (val === 'skipped') {
        trackAnswer(false, 'skipped');
      }
      setShowSkip(false);
    };

    const renderInputColour = () => {
      if (answer) {
        if (validation) {
          return 'right';
        } else {
          return 'wrong';
        }
      } else {
        return '';
      }
    };

    const renderSkip = () => {
      if (showSkip) {
        return (
          <div ref={skipRef} style={{ marginTop: '4px' }}>
            <SkipMessage
              isQuiz={isQuiz}
              course={course}
              onClose={(val) => onSkipClose(val)}
            />
          </div>
        );
      } else if (props.hideSkip) {
        return <></>;
      } else {
        return (
          <div
            id='basic-course-button'
            className='ui basic button'
            onClick={onSkip}
            style={{ marginTop: '4px' }}
          >
            Skip
          </div>
        );
      }
    };

    const renderInput = () => {
      return (
        <form
          className={`ui input form ${answer ? 'disabled' : ''} `}
          style={{ width: '100%', marginBottom: '16px' }}
          onSubmit={onSubmit}
        >
          <div className='field' style={{ width: '100%' }}>
            <input
              ref={ref}
              type='text'
              placeholder='Type your answer...'
              className={`primary-input ${renderInputColour()}`}
              onChange={onInputChange}
              value={term}
              style={{ pointerEvents: `${answer ? 'none' : ''}` }}
            />
          </div>
        </form>
      );
    };

    return (
      <div>
        <div>
          <div className='centered'>
            {question.image ? (
              <img
                src={question.image}
                className='course-image'
                alt='question_image'
                style={{ marginBottom: '-2em' }}
              ></img>
            ) : (
              ''
            )}
            <div className='question-background ' style={{ margin: '0 -1em' }}>
              <div
                className='course-text-question'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(question.statement),
                }}
              ></div>{' '}
            </div>
            <div
              className='text-divider'
              style={{ margin: '16px 0 16px 0' }}
            ></div>
            {renderInput()}

            {!answer ? (
              <>
                <div className='centered'>
                  {term[0] ? (
                    <div
                      id='secondary-course-button'
                      className='ui button'
                      onClick={onSubmit}
                      style={{ width: '100%' }}
                    >
                      Submit
                    </div>
                  ) : (
                    <div
                      id='secondary-course-button'
                      className='ui button disabled'
                      style={{ width: '100%' }}
                    >
                      Submit
                    </div>
                  )}
                  {renderSkip()}
                </div>
              </>
            ) : (
              <div>
                <Explanation
                  explanation={question.explanation}
                  correct={validation}
                  course={course}
                  onContinue={props.onContinue}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    answer: state.answer.answer,
    user: state.user.user,
    canAnswer: state.question.canAnswer,
  };
};

export default connect(mapStateToProps, {
  setAnswer,
  fetchSection,
  addIncorrectAnswer,
  trackAnswer,
  getQuestion,
})(InputQuestion);
