import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import UserForm from '../widgets/UserForm';
import { editUser, resetErrors } from '../../actions';

const EditAccount = (props) => {
  const [updated, setUpdated] = useState(false);
  const onSubmit = (formValues) => {
    props.editUser(formValues);
    setUpdated(true);
  };

  const error = props.error;
  const errorDetail = props.errorDetail;

  const renderError = () => {
    if (updated && error && errorDetail === 'Wrong password') {
      return (
        <div className='ui error message'>
          <div className='header'>Wrong password</div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  if (props.isSignedIn) {
    return (
      <div style={{ maxWidth: '400px', margin: '0 auto' }}>
        <div className='centered'>
          <h2 className='ui icon header'>
            <i className='settings icon'></i>
            <div className='content'>
              Edit your details
              <div className='sub header'>
                Update your account details here. Don't forget to save!
              </div>
            </div>
          </h2>
        </div>
        {renderError()}
        <UserForm button='Save' onSubmit={onSubmit} />
        <Link
          to='/account/delete'
          id='red-button'
          style={{ margin: '16px 0 0 0' }}
          className='ui button'
        >
          Delete account
        </Link>
      </div>
    );
  }

  return (
    <div className='centered'>
      <h5>Please login</h5>

      <Link to='/login' className='ui primary button'>
        Login
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.error.error,
    errorDetail: state.error.errorDetail,
    isSignedIn: state.user.isSignedIn,
  };
};

export default connect(mapStateToProps, { editUser, resetErrors })(EditAccount);
