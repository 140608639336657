import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import api from '../../../api';

const Suggestions = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [term, setTerm] = useState('');

  const user = props.user;
  const course = props.course;

  const onFeedbackSubmit = async (e) => {
    e.preventDefault();
    await api.post('/user/course/feedback', {
      user,
      course: course.unitTitle,
      feedback: term,
    });

    ReactGA.event({
      category: `Feedback`,
      action: `${course.id} feedback`,
      label: term,
      transport: 'beacon',
    });
    setSubmitted(true);
    setTerm('');
  };

  const renderFeedbackSubmitted = () => {
    if (submitted) {
      return (
        <div>
          <div className='ui positive message'>
            {`Thanks for your feedback! We'll share what you've said with the instructor to help us improve this course, our content and our platform.`}
          </div>
          <br />
        </div>
      );
    }
    return <></>;
  };

  const onInputChange = (e) => {
    setTerm(e.target.value);
  };

  const renderFeedback = () => {
    return (
      <div>
        <form className='ui form' onSubmit={onFeedbackSubmit}>
          <div
            className='field'
            style={{ marginTop: '8px', marginLeft: '0px' }}
          >
            <textarea
              label='feedback'
              placeholder='Let us know what you thought...'
              onChange={onInputChange}
              value={term}
            />
            <button
              onClick={onFeedbackSubmit}
              className='ui primary button'
              style={{ width: '150px', marginLeft: '0px', marginTop: '5px' }}
            >
              {`Leave feedback`}
            </button>
          </div>
        </form>
        <div style={{ marginTop: '4px' }}>{renderFeedbackSubmitted()}</div>
      </div>
    );
  };
  return <div>{renderFeedback()}</div>;
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.user.isSignedIn,
    user: state.user.user,
    course: state.course.course,
  };
};

export default connect(mapStateToProps)(Suggestions);
