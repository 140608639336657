import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import Error from '../../widgets/Error';

import { forgotPassword } from '../../../actions';

const renderInput = ({ input, label }) => {
  return (
    <div className='field' style={{ width: '250px' }}>
      <label>{label}</label>
      <input
        {...input}
        className='primary-input'
        placeholder='Email'
        autoComplete='off'
      ></input>
    </div>
  );
};

let ForgotInstructions = (props) => {
  const [emailSent, setEmailSent] = useState([]);

  const error = props.error;
  const errorType = props.errorDetail;

  const onSubmit = async (formValues) => {
    props.forgotPassword({ ...formValues });
    setEmailSent([true, formValues]);
  };

  const renderError = () => {
    if (error) {
      switch (errorType.code) {
        case 404:
          return (
            <div className='ui error message'>
              <div className='header'>{errorType.message}</div>
            </div>
          );
        default:
          return (
            <div>
              <Error />
            </div>
          );
      }
    }
    return <></>;
  };

  const renderEmailSent = () => {
    if (emailSent.length === 0 || error) {
      return (
        <div>
          <p style={{ maxWidth: '500px' }}>
            Forgotten your password? No worries! Use the form below to reset
            your password. The link is only valid for one hour so make sure to
            reset it straight away!
          </p>
          {renderError()}
          <form className='ui form' onSubmit={props.handleSubmit(onSubmit)}>
            <Field name='email' component={renderInput} label='Your email' />
            <button id='secondary-button' className='ui button'>
              Reset my password
            </button>
            <Link to='/login' id='cancel-button' className='ui button'>
              Cancel
            </Link>
          </form>
        </div>
      );
    }

    return (
      <div>
        <p>
          We've sent an email to <b>{emailSent[1].email}</b>. Please check your
          inbox!
        </p>
      </div>
    );
  };

  return <div>{renderEmailSent()}</div>;
};

const mapStateToProps = (state) => {
  return { error: state.error.error, errorDetail: state.error.errorDetail };
};

ForgotInstructions = connect(mapStateToProps, { forgotPassword })(
  ForgotInstructions
);

export default reduxForm({
  form: 'forgotForm',
})(ForgotInstructions);
