import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';
import DOMPurify from 'dompurify';

import Explanation from './Explanation';
import SkipMessage from './SkipMessage';

import {
  setAnswer,
  fetchSection,
  trackAnswer,
  getQuestion,
} from '../../../actions';

const CheckboxQuestion = (props) => {
  const [showSkip, setShowSkip] = useState(false);

  let checked = props.term;
  const answer = props.answer;
  const user = props.user;
  const course = props.course;
  const question = course.question;
  const validation = props.validation;
  const canAnswer = props.canAnswer.canAnswer;
  const isQuiz = props.isQuiz;
  const section = props.section;

  const ref = useRef([]);
  const skipRef = useRef();

  useEffect(() => {
    props.getQuestion(user._id, course._id);
  }, [section]);

  useEffect(() => {
    ref.current = ref.current.slice(0, question.answers.length);

    const onBodyClick = (e) => {
      if (skipRef.current && skipRef.current.contains(e.target)) {
        return;
      } else {
        setShowSkip(false);
      }
    };
    document.body.addEventListener('click', onBodyClick);
    return () => document.body.removeEventListener('click', onBodyClick);
  }, [question]);

  const onChange = (answerIndex) => {
    if (!answer) {
      if (checked.some((e) => e === answerIndex + 1)) {
        return props.currentTerm(checked.filter((e) => e !== answerIndex + 1));
      }

      props.currentTerm([...checked, answerIndex + 1]);
    }
  };

  const isChecked = (index) => {
    const reference = index + 1;

    if (checked.includes(reference)) {
      return true;
    }
    return false;
  };

  const renderOptionColour = (index, parsedAnswers) => {
    if (answer && isChecked(index) && validation) {
      return 'right';
    } else if (answer && isChecked(index)) {
      if (parsedAnswers.some((e) => e === index + 1)) {
        return 'right';
      } else {
        return 'wrong';
      }
    } else if (answer) {
      if (parsedAnswers.some((e) => e === index + 1)) {
        return 'wrong';
      }
    } else if (isChecked(index)) {
      return 'checked';
    }
  };

  const renderIcon = (index, parsedAnswers) => {
    if (parsedAnswers.some((e) => e === index + 1)) {
      return <i className='check icon' style={{ color: '#338450' }} />;
    }
    // } else {
    //   return <i className='close icon' style={{ color: '#B56576' }} />;
    // }
  };

  const renderOptions = () => {
    return question.answers.map((answer) => {
      const index = question.answers.indexOf(answer);

      const parsedAnswers = question.correctAnswers.map((e) => parseInt(e));

      return (
        <div
          className={`field question-option ${renderOptionColour(
            index,
            parsedAnswers
          )}`}
          key={index}
          ref={(el) => (ref.current[index] = el)}
          onClick={(value) => onChange(index)}
        >
          {!props.answer ? (
            <div className='ui checkbox'>
              <input
                type='checkbox'
                name={`option ${index}`}
                checked={isChecked(index)}
                onChange={() => onChange(index)}
              />
              <label
                className='question-option-text'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(answer),
                }}
              ></label>
            </div>
          ) : (
            <div>
              {renderIcon(index, parsedAnswers)}
              <label
                className='question-option-text'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(answer),
                }}
              ></label>
            </div>
          )}
        </div>
      );
    });
  };

  const trackAnswer = (correct, answer) => {
    if (canAnswer) {
      props.trackAnswer(user._id, course._id, correct, answer);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.currentTerm(props.term);
    props.setAnswer(props.term);

    const parsedAnswers = question.correctAnswers.map((e) => parseInt(e));

    if (user) {
      let validate =
        parsedAnswers.length === checked.length &&
        parsedAnswers.every((e) => checked.includes(e));

      if (validate) {
        if (!validation) {
          props.onValidation(true);
        }
        trackAnswer(true, checked.toString());
      } else {
        if (validation) {
          props.onValidation(false);
        }

        trackAnswer(false, checked.toString());
      }
      if (props.trackAnswered) {
        props.trackAnswered(course._id, validate);
      }
    }
  };

  const onSkip = () => {
    if (!isQuiz) {
      if (user.settings.acceptSkip) {
        trackAnswer(false, 'skipped');
        props.onContinue();
      } else {
        setShowSkip(true);
      }
    } else {
      props.quizSkip();
    }
  };

  const onSkipClose = (val) => {
    if (val === 'skipped') {
      trackAnswer(false, 'skipped');
    }
    setShowSkip(false);
  };

  const renderSkip = () => {
    if (showSkip) {
      return (
        <div ref={skipRef} style={{ marginTop: '4px' }}>
          <SkipMessage
            isQuiz={isQuiz}
            course={course}
            onClose={(val) => onSkipClose(val)}
          />
        </div>
      );
    } else if (props.hideSkip) {
      return <></>;
    } else {
      return (
        <div
          id='basic-course-button'
          className='ui basic button'
          onClick={onSkip}
          style={{ marginTop: '4px' }}
        >
          Skip
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <div className='centered'>
          {question.image ? (
            <img
              src={question.image}
              className='course-image'
              alt='question_image'
              style={{ marginBottom: '-2em' }}
            ></img>
          ) : (
            ''
          )}
          <div className='question-background' style={{ margin: '0 -1em' }}>
            <div
              className='course-text-question'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.statement),
              }}
            ></div>{' '}
          </div>
          <p style={{ marginTop: '8px', fontSize: '12px' }}>
            Select all answers that apply
          </p>
        </div>
        <div className='text-divider' style={{ margin: '16px 0 16px 0' }}></div>
        <div className='ui form'>
          <div className='grouped fields'>{renderOptions()}</div>
        </div>
        <div>
          {!answer ? (
            <>
              <div className='centered'>
                {props.term.length !== 0 ? (
                  <div
                    id='secondary-course-button'
                    className='ui button'
                    onClick={onSubmit}
                    style={{ width: '100%' }}
                  >
                    Submit
                  </div>
                ) : (
                  <div
                    id='secondary-course-button'
                    className='ui button disabled'
                    style={{ width: '100%' }}
                  >
                    Submit
                  </div>
                )}
                {renderSkip()}
              </div>
            </>
          ) : (
            <div className='centered'>
              <Explanation
                explanation={question.explanation}
                correct={validation}
                course={course}
                onContinue={props.onContinue}
              />
            </div>
          )}
        </div>
      </div>{' '}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    answer: state.answer.answer,
    user: state.user.user,
    canAnswer: state.question.canAnswer,
  };
};

export default connect(mapStateToProps, {
  setAnswer,
  fetchSection,
  trackAnswer,
  getQuestion,
})(CheckboxQuestion);
