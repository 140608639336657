import React from 'react';
import { connect } from 'react-redux';

import { setForumScore, setCommentScore } from '../../actions/index';

const Like = (props) => {
  const item = props.item;
  const user = props.user;
  const type = props.type;
  const forum = props.forum;

  const onVoteClick = (item) => {
    let topic;
    type === 'forum' ? (topic = item.topic) : (topic = forum.topic);
    if (user) {
      if (
        user.courses.some((course) => course.course === topic) ||
        topic === 'pyxium'
      ) {
        if (type === 'forum') {
          props.setForumScore(item._id, user._id, user);
        } else if (type === 'comment') {
          props.setCommentScore(forum._id, item._id, user._id);
        }
      } else {
        props.notEnrolled();
      }
    } else {
      props.notLoggedIn();
    }
  };

  const renderScoreIcon = (item) => {
    if (item.voters.includes(user._id)) {
      return (
        <>
          <i
            className='thumbs up icon forum-up'
            style={{ color: '#FFCC00' }}
            onClick={() => onVoteClick(item)}
          ></i>
        </>
      );
    } else {
      return (
        <>
          <i
            className='thumbs up outline icon forum-up'
            onClick={() => onVoteClick(item)}
          ></i>
        </>
      );
    }
  };

  return <>{renderScoreIcon(item)}</>;
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  setForumScore,
  setCommentScore,
})(Like);
