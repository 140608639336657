import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import ReactGA from 'react-ga';

import history from '../src/js/history';
import './css/styles.css';
import App from './js/components/App';
import reducers from './js/reducers';

const composeEnhancers = compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

Sentry.init({
  dsn: 'https://d6924d49a87c48db852347b524065342@o432409.ingest.sentry.io/5385074',
});

const trackPageView = (location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

const initGa = (history) => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  trackPageView(history.location);
  history.listen(trackPageView);
};

initGa(history);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);
